import axios from "axios";
import _ from "lodash";

import { API_BASE_URL } from "./constants";
import { createQueryString } from "./utils";

const API_URL = `${API_BASE_URL}institutions`;

export const INTERNATIONAL_INSTITUTIONS = "International Institutions";

export interface Institution {
  _id: string;
  name: string;
  category: string;
  sub_category?: string[];
  country?: string;
}

export const getInstitutions = async (countries: string[], categories: string[]) => {
  const countryParameters = _.map(countries, (country) => {
    return {
      field: "country",
      value: country,
    };
  });

  const categoryParameters = _.map(categories, (category) => {
    return {
      field: "category",
      value: category,
    };
  });
  const query = createQueryString([...countryParameters, ...categoryParameters]);
  let url = `${API_URL}`;
  if (query) {
    url = url.concat("?", query);
  }

  const { data } = await axios.get<Institution[]>(url);
  return data;
};

export const getInstitutionById = async (id: string) => {
  const url = `${API_URL}/${id}`;

  const { data } = await axios.get<Institution>(url);
  return data;
};

export const getInstitutionsByCustomBrowse = async (countries?: string[]) => {
  let url = `${API_URL}?cb=true`;
  if (countries) {
    const countryParameters = _.map(countries, (country) => {
      return {
        field: "country",
        value: country,
      };
    });
    url = url.concat("&", createQueryString(countryParameters));
  }

  const { data } = await axios.get<Institution[]>(url);
  return data;
};
