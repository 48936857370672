import React, { FC, CSSProperties } from "react";

import { Grid } from "antd";

import { fontSizes } from "../styles/fonts";

const { useBreakpoint } = Grid;

interface PageHeaderProps {
  title: string;
  description?: string;
  imageUrl?: string;
}

const PageHeader: FC<PageHeaderProps> = ({ title, description, imageUrl }) => {
  const { xl } = useBreakpoint();

  const backgroundStyle: CSSProperties = {
    backgroundImage: `radial-gradient(circle, rgba(4, 30, 66, 0.6) 0%, rgba(4, 30, 66, 0.6) 100%), url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const layoutStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `${xl ? "100px" : "50px"} 0`,
  };

  const invisibleStyle: CSSProperties = {
    height: 0,
    width: 0,
    overflow: "hidden",
    marginBottom: xl ? 0 : 16,
  };

  const containerStyle = imageUrl ? { ...backgroundStyle, ...layoutStyle } : invisibleStyle;
  return (
    <div style={containerStyle} className="page-header">
      <h1
        style={{ fontSize: "2.375rem", marginBottom: "4px", color: "white", textAlign: "center" }}
      >
        {title}
      </h1>
      {description && (
        <p
          style={{
            width: `${1 + Math.ceil(description.length / 4)}em`,
            maxWidth: "80vw",
            color: "white",
            textAlign: "center",
            fontSize: fontSizes.font_size_7,
          }}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default PageHeader;
