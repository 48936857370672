import React, { ReactNode, FC } from "react";

interface AssertiveContainerProps {
  children: ReactNode;
}

const AssertiveContainer: FC<AssertiveContainerProps> = ({ children }) => {
  return (
    <div role="region" aria-live="assertive">
      {children}
    </div>
  );
};

export default AssertiveContainer;
