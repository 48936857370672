import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { AppThunk } from "../../app/store";

import { categoriesMenuFactory } from "../categories/categoriesMenuSlice";
import { countriesMenuFactory } from "../countries/countriesMenuSlice";
import { variablesMenuFactory } from "../variables/variablesMenuSlice";

const ROUTE = "compareInstitutions";

const countriesMenu = countriesMenuFactory(ROUTE);
export const { setCountriesIsDisabled, setSelectedCountries } = countriesMenu.actions;

const categoriesMenu = categoriesMenuFactory(ROUTE);
export const { setCategoriesIsDisabled, setSelectedCategories } = categoriesMenu.actions;

interface InstitutionsMenuState {
  selectedInstitutions: Record<string, string>[];
  isDisabled: boolean;
}

const initialInstitutionsMenuState: InstitutionsMenuState = {
  selectedInstitutions: [],
  isDisabled: true,
};

const institutionsMenu = createSlice({
  name: `${ROUTE}/institutionsMenu`,
  initialState: { ...initialInstitutionsMenuState } as InstitutionsMenuState,
  reducers: {
    setInstitutionsIsDisabled(state, { payload }: PayloadAction<boolean>) {
      state.isDisabled = payload;
    },
    setSelectedInstitutions(state, { payload }: PayloadAction<Record<string, string>[]>) {
      state.selectedInstitutions = payload;
    },
  },
});
export const { setInstitutionsIsDisabled, setSelectedInstitutions } = institutionsMenu.actions;

const variablesMenu = variablesMenuFactory(ROUTE);
export const { setVariablesIsDisabled } = variablesMenu.actions;

const compareReducer = combineReducers({
  countriesMenu: countriesMenu.reducer,
  categoriesMenu: categoriesMenu.reducer,
  institutionsMenu: institutionsMenu.reducer,
  variablesMenu: variablesMenu.reducer,
});
export default compareReducer;

export const updateSelectedInstitutionsByCountry =
  (countries: string[]): AppThunk =>
  async (dispatch, state) => {
    const byCategoryByName = state().institutions.byCategoryByName;
    const institutionById = state().institutions.byId;
    const selectedInstitutions = state().compareInstitutions.institutionsMenu.selectedInstitutions;
    const filteredSelectedInstitutions = _.filter(selectedInstitutions, ({ category, name }) => {
      const institutions = _.values(byCategoryByName[category][name]);
      return _.some(institutions, (institutionId) => {
        const institution = institutionById[institutionId];
        return institution.country ? _.includes(countries, institution.country) : true;
      });
    });
    dispatch(setSelectedInstitutions(filteredSelectedInstitutions));
  };
