import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import _ from "lodash";

import { Institution } from "../../api/institution";
import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { fetchBodyOfLaw } from "../../features/bodyOfLaw/bodyOfLawSlice";
import { fetchVariableById } from "../../features/variables/variablesSlice";

import { getBodyOfLawIsLoading, makeGetCompositeVariables } from "./bodyOfLawSelectors";
import { getVariablesIsLoading, makeGetVariableById } from "../variables/variablesSelectors";

import CompositeVariableTable from "../../components/CompositeVariableTable";
import Container from "../../components/Container";
import NotFound from "../../components/NotFound";
import Spinner from "../../components/Spinner";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";

interface RouteParam {
  id: string;
}

const BODY_OF_LAW = "Body of Law";

const BodyOfLawPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    dispatch(fetchVariableById(id));
    dispatch(fetchBodyOfLaw(id));
  }, [id, dispatch]);

  const bodyOfLawIsLoading = useSelector(getBodyOfLawIsLoading);
  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variable = useSelector(makeGetVariableById(id));
  const compositeVariables = useSelector(makeGetCompositeVariables(id));

  useDocumentTitle(variable ? variable.heading : "Loading...");

  let content;

  if (variable && compositeVariables.length && compositeVariables[0].length) {
    const institution = variable.institution as unknown as Institution;
    const columnsType = _.fill(Array(6), "basic");
    columnsType.unshift("filter");
    columnsType.unshift("filter");
    content = (
      <>
        <PageHeader title={`${variable.heading}`} />
        <CompositeVariableTable
          institution={institution}
          columnsType={columnsType}
          breadcrumbs={[institution.country as string, institution.name, variable.heading]}
          variables={compositeVariables}
          furled={false}
        />
      </>
    );
  } else if (bodyOfLawIsLoading || variablesIsLoading) {
    content = <Spinner />;
  } else {
    content = <NotFound message={`${BODY_OF_LAW} doesn't exist.`} />;
  }

  return <Container>{content}</Container>;
};

export default withErrorBoundary(BodyOfLawPage);
