import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { getVariableById, getVariables } from "../../api";
import { Variable } from "../../api/variable";
import { AppThunk } from "../../app/store";

interface VariablesState {
  byId: Record<string, Variable>;
  byInstitution: Record<string, Record<string, string>>;
  isLoading: boolean;
  error: string | null;
}

const initialVariablesState: VariablesState = {
  byId: {},
  byInstitution: {},
  isLoading: false,
  error: null,
};

const variables = createSlice({
  name: "variables",
  initialState: initialVariablesState,
  reducers: {
    getVariablesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getVariablesSuccess(state, { payload }: PayloadAction<Variable[]>) {
      state.isLoading = false;
      state.error = null;
      const variables = payload;
      _.forEach(variables, (variable) => {
        state.byId[variable._id] = variable;
        if (!_.has(state.byInstitution, variable.institution)) {
          state.byInstitution[variable.institution] = {};
        }
        state.byInstitution[variable.institution][variable._id] = variable._id;
      });
    },
    getVariablesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    getVariableByIdSuccess(state, { payload }: PayloadAction<Variable>) {
      state.isLoading = false;
      state.error = null;
      const variable = payload;
      if (!_.has(state.byId, variable._id)) {
        state.byId[variable._id] = variable;
        //dont add it to byInstitution because that is used check whether to fetch all variables for an institution
      }
    },
  },
});

const { getVariablesStart, getVariablesFailure, getVariableByIdSuccess, getVariablesSuccess } =
  variables.actions;

export default variables.reducer;

export const fetchVariables =
  (institutions: string[]): AppThunk =>
  async (dispatch, state) => {
    const byInstitution = state().variables.byInstitution;
    const unfetchedInstitutions = _.filter(
      institutions,
      (institution) => !_.has(byInstitution, institution)
    );
    if (unfetchedInstitutions.length > 0) {
      try {
        dispatch(getVariablesStart());
        const variables = await getVariables(unfetchedInstitutions);
        dispatch(getVariablesSuccess(variables));
      } catch (err) {
        dispatch(getVariablesFailure(err.toString()));
      }
    }
  };

export const fetchVariableById =
  (id: string): AppThunk =>
  async (dispatch, state) => {
    const byId = state().variables.byId;
    if (!_.has(byId, id)) {
      try {
        dispatch(getVariablesStart());
        const variable = await getVariableById(id);
        dispatch(getVariableByIdSuccess(variable));
      } catch (err) {
        dispatch(getVariablesFailure(err.toString()));
      }
    }
  };
