import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CategoriesMenuState {
  selectedCategories: string[];
  isDisabled: boolean;
}

const initialCategoriesMenuState: CategoriesMenuState = {
  selectedCategories: [],
  isDisabled: true,
};

export const categoriesMenuFactory = (route: string) =>
  createSlice({
    name: `${route}/categoriesMenu`,
    initialState: { ...initialCategoriesMenuState } as CategoriesMenuState,
    reducers: {
      setCategoriesIsDisabled(state, { payload }: PayloadAction<boolean>) {
        state.isDisabled = payload;
      },
      setSelectedCategories(state, { payload }: PayloadAction<string[]>) {
        state.selectedCategories = payload;
      },
    },
  });
