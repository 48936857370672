import _ from "lodash";
import { CompositeVariable } from "../../api";

const createSheetsFromCompositeVariable = (variables: CompositeVariable[], title: string) => {
  const headers = _.map(variables[0].sigla_answers, (siglaAnswer) => {
    return siglaAnswer.name;
  });
  const headings = _.uniq(_.map(variables, ({ heading }) => heading));
  const data = _.map(variables, (variable) => {
    const siglaAnswers = _.map(variable.sigla_answers, (siglaAnswer) => {
      return siglaAnswer.answer;
    });
    const row = headers.reduce(function (obj: Record<string, string>, x: string, index: number) {
      obj[x] = siglaAnswers[index];
      return obj;
    }, {});
    if (variable.heading && headings.length > 1) {
      row["Tag / category of right"] = variable.heading;
    }
    return row;
  });
  if (headings.length > 1) {
    headers.unshift("Tag / category of right");
  }

  return {
    title: title,
    headers: headers,
    data: data,
  };
};

export default createSheetsFromCompositeVariable;
