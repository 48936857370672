import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { CompositeVariable } from "../../api";
import { RootState } from "../../app/rootReducer";

export const getRightsIsLoading = (state: RootState) => state.rights.isLoading;
const getRights = (state: RootState) => state.rights.rights;

export const makeGetCompositeVariables = (variableId: string) =>
  createSelector([getRights], (rights) => {
    const rightsAsCV: CompositeVariable[] = _.map(rights, (right) => {
      const categoryOfRight = right.sigla_answers[0].answer;
      return {
        _id: right._id,
        index: right.index,
        heading: categoryOfRight,
        variable: variableId,
        sigla_answers: _.slice(right.sigla_answers, 1),
      };
    });
    const rightsByHeading = _.values(_.groupBy(rightsAsCV, "heading"));
    const sortedRightsByHeading = _.sortBy(
      rightsByHeading,
      (rightsForAHeading) => rightsForAHeading[0].index
    );
    return sortedRightsByHeading;
  });
