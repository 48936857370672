import React, { FC, ReactNode, useState } from "react";

import { MenuOutlined, FilterFilled } from "@ant-design/icons";
import arrayMove from "array-move";
import { Card, Table, Space, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import { Institution } from "../../api/institution";
import { SiglaTriple, Variable, VariableType } from "../../api/variable";

import FilterOption from "../../components/FilterOption";
import InvisibleText from "../../components/InvisibleText";
import SiglaAnswerCell from "../../components/SiglaAnswerCell";
import TableCell from "../../components/TableCell";
import TableHeader from "../../components/TableHeader";
import TableSort from "../../components/TableSort";
import DownloadData from "../downloadData/DownloadData";
import createSheetsFromCustomBrowseTable from "../downloadData/createSheetsFromCustomBrowseTable";
import PinVariable from "../myPinnedData/PinVariable";
import PinCustomBrowseTable from "../myPinnedData/PinCustomBrowseTable";
import UnpinButton from "../myPinnedData/UnpinButton";

import "./CustomBrowseTable.css";
import { DELIMITER } from "../../constants";
import { black, secondary } from "../../styles/colors";
import { fontSizes } from "../../styles/fonts";

interface CustomBrowseTableProps {
  variables: IVariable[];
  pin: boolean;
}

export interface IVariable extends Omit<Variable, "institution"> {
  key: string;
  institution: Institution;
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999", fontSize: 20 }} />
));

const SortableRow = SortableElement((props: any) => <tr {...props} />);
const SortableTableContainer = SortableContainer((props: any) => <tbody {...props} />);

const compareFunction = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};

const headerRender = (header: string, fontWeight?: number) => (
  <TableHeader header={header} fontWeight={fontWeight} />
);

const columnRender = (text: ReactNode) => {
  return (
    <TableCell showEllipsis={false} fontWeight={400}>
      {text}
    </TableCell>
  );
};

const siglaAnswerRender = (text: string, record: IVariable) => {
  const variable = {
    ...record,
    institution: record.institution._id,
  };

  return <SiglaAnswerCell variable={variable} />;
};

const CustomBrowseTable: FC<CustomBrowseTableProps> = ({ variables, pin }) => {
  const pinColumnRender = (value: any, record: IVariable) => {
    const institution = record.institution;
    const variable: Variable = { ...record, institution: institution._id };
    return pin ? (
      <PinVariable institution={institution} variable={variable} type="variable" />
    ) : (
      <UnpinButton
        compare={false}
        data={[{ institution, variables: [variable] }]}
        type="variable"
      />
    );
  };

  const columns: ColumnsType<IVariable> = [
    {
      key: "sort",
      dataIndex: "sort",
      title: <InvisibleText text="Sort" />,
      className: "drag-visible",
      width: "5%",
      render: () => <DragHandle />,
    },
    {
      title: () => <TableSort header="Country" />,
      dataIndex: "country",
      key: "country",
      className: "drag-visible",
      render: (text: string, record: IVariable) => {
        return columnRender(record.institution.country || record.institution.category);
      },
      sorter: (a: IVariable, b: IVariable) =>
        compareFunction(a.institution.country || "", b.institution.country || ""),
    },
    {
      title: () => <TableSort header="Institution" />,
      dataIndex: "institution_name",
      key: "institution_name",
      className: "drag-visible",
      render: (text: string, record: IVariable) => {
        return columnRender(record.institution.name);
      },
      sorter: (a: IVariable, b: IVariable) =>
        compareFunction(a.institution.name, b.institution.name),
    },
    {
      title: () => <TableSort header={`Heading ${DELIMITER} Variable`} />,
      dataIndex: "heading_variable",
      key: "heading_variable",
      className: "drag-visible",
      render: (text: string, record: IVariable) => {
        const value = (
          <span>
            {record.heading !== record.name && (
              <span style={{ color: black.black_3 }}>{record.heading.trim()}</span>
            )}
            {record.heading !== record.name && ` ${DELIMITER} `}
            <span>{record.name.trim()}</span>
          </span>
        );
        return columnRender(value);
      },
      width: "15%",
      sorter: (a: IVariable, b: IVariable) => compareFunction(a.name, b.name),
    },
    {
      title: headerRender(SiglaTriple.answer),
      dataIndex: "sigla_answer",
      key: "sigla_answer",
      render: siglaAnswerRender,
      width: "45%",
    },
    {
      dataIndex: "pin",
      key: "pin",
      title: <InvisibleText text="Pin" />,
      width: "5%",
      render: pinColumnRender,
    },
  ];

  const getColumnFilterProps = (dataIndex: string, values: string[]) => ({
    filters: _.map(values, (value) => {
      return {
        text: <FilterOption text={value} maxWidth={"25vw"} />,
        value: value,
      };
    }),
    filterIcon: (filtered: boolean) => (
      <Button
        aria-label={`Filter ${dataIndex}`}
        icon={
          <FilterFilled
            style={{
              fontSize: fontSizes.font_size_5,
              color: filtered ? secondary : black.black_3,
            }}
          />
        }
      />
    ),
    onFilter: (value: string | number | boolean, record: Record<string, string>) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
  });

  const expandedRowRender = (record: IVariable) => {
    const isRightVariable = record.type === VariableType.aggregate;
    const rightWidths = ["25%", "25%", "50%", "10%"];
    const innerTableColumns: ColumnsType<Record<string, string>> = isRightVariable
      ? _.map((record.sigla_answer as Record<string, string>[][])[0], (siglaAnswer, i) => {
          const column = {
            key: siglaAnswer.name,
            title: headerRender(siglaAnswer.name, 550),
            dataIndex: siglaAnswer.name,
            render: (text: string) => columnRender(text),
            width: rightWidths[i],
          };
          if (i < 2) {
            const filterValues: string[] = [];
            _.forEach(record.sigla_answer as Record<string, string>[][], (siglaAnswers) => {
              const values = _.map(_.split(siglaAnswers[i].answer, ";"), (value) => _.trim(value));
              /*don't include empty strings*/
              const filteredValues = _.filter(values, (value) => value.length > 0);
              filterValues.push(...filteredValues);
            });
            return {
              ...column,
              ...getColumnFilterProps(siglaAnswer.name, _.uniq(_.sortBy(filterValues))),
            };
          }
          return column;
        })
      : [
          {
            dataIndex: "1",
            key: "1",
            render: (text: string) => columnRender(text),
          },
          {
            dataIndex: "2",
            key: "2",
            width: "76%",
            render: (text: string) => columnRender(text),
          },
        ];

    const innerTableData: Record<string, string>[] = isRightVariable
      ? _.map(record.sigla_answer as Record<string, string>[][], (siglaAnswers) => {
          return _.reduce(
            siglaAnswers,
            (obj, siglaAnswer) => {
              obj[siglaAnswer.name] = siglaAnswer.answer;
              return obj;
            },
            { key: siglaAnswers[1].answer } as Record<string, string>
          );
        })
      : [
          {
            key: SiglaTriple.originalText,
            "1": SiglaTriple.originalText,
            "2": record.orig_text as string,
          },
          {
            key: SiglaTriple.source,
            "1": SiglaTriple.source,
            "2": record.source as string,
          },
        ];
    return (
      <Table
        size="small"
        rowClassName={isRightVariable ? "" : "original-text-source-row"}
        tableLayout="fixed"
        columns={innerTableColumns}
        dataSource={innerTableData}
        showHeader={isRightVariable}
        pagination={false}
        style={{ border: "1px solid #d9d9d9", marginLeft: isRightVariable ? "0px" : "35%" }}
        scroll={isRightVariable ? { y: "50vh" } : {}}
        components={{
          header: {
            cell: (props: any) => <th {...props} scope="col" />,
          },
        }}
      />
    );
  };

  const [dataSource, setDataSource] = useState<IVariable[]>(variables);

  const onSortStart = () => {
    document.body.className = "grabbing";
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(([] as IVariable[]).concat(dataSource), oldIndex, newIndex).filter(
        (el) => !!el
      );
      setDataSource(newData);
    }
    document.body.className = "";
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    if (restProps["data-row-key"]) {
      const index = dataSource.findIndex((x) => x._id === restProps["data-row-key"]);
      return <SortableRow className={className} style={style} index={index} {...restProps} />;
    } else {
      return <tr className={className} style={style} {...restProps} />;
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableTableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const pinFormatData = _.values(
    _.mapValues(_.groupBy(variables, "institution._id"), (variablesPerInstitution) => {
      return {
        institution: variablesPerInstitution[0].institution,
        variables: _.map(variablesPerInstitution, (variable) => {
          return {
            ...variable,
            institution: variablesPerInstitution[0].institution._id,
          } as Variable;
        }),
      };
    })
  );

  const downloadCompositeVariableData: Variable[] = _.map(
    _.filter(variables, ({ type }) => type === VariableType.composite),
    (variable) => {
      return {
        ...variable,
        institution: variable.institution._id,
      };
    }
  );

  return (
    <Card
      className="sigla-table"
      bodyStyle={{ padding: 0 }}
      extra={
        <Space>
          <DownloadData
            tooltipTitle={pin ? "Download table" : "Download all pinned individual variables"}
            saveAsFileName={
              pin ? "SIGLA_Custom_Browse_Data" : "SIGLA_Individual_Variables_View_by_Variable_Data"
            }
            sheets={createSheetsFromCustomBrowseTable(variables)}
            compositeVariables={downloadCompositeVariableData}
          />
          {pin ? (
            <PinCustomBrowseTable data={pinFormatData} />
          ) : (
            <UnpinButton compare={false} data={pinFormatData} type="all individual variables" />
          )}
        </Space>
      }
    >
      <Table
        className="custom-browse-table"
        rowClassName="main-row"
        tableLayout="fixed"
        columns={columns}
        dataSource={dataSource}
        rowKey="_id"
        pagination={{
          defaultPageSize: 20,
          position: ["topRight", "bottomRight"],
          showTotal: (total) => `Total ${total} ${total > 1 ? "Variables" : "Variable"}`,
        }}
        components={{
          header: {
            cell: (props: any) => <th {...props} scope="col" />,
          },
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        expandable={{
          expandedRowRender: expandedRowRender,
          indentSize: 0,
        }}
      />
    </Card>
  );
};

export default CustomBrowseTable;
