import { combineReducers } from "@reduxjs/toolkit";

import { categoriesMenuFactory } from "../categories/categoriesMenuSlice";
import { countriesMenuFactory } from "../countries/countriesMenuSlice";
import { institutionsMenuFactory } from "../institutions/institutionsMenuSlice";
import { variablesMenuFactory } from "../variables/variablesMenuSlice";

const ROUTE = "byCategory";

const countriesMenu = countriesMenuFactory(ROUTE);
export const { setCountriesIsDisabled, setSelectedCountries } = countriesMenu.actions;

const categoriesMenu = categoriesMenuFactory(ROUTE);
export const { setCategoriesIsDisabled, setSelectedCategories } = categoriesMenu.actions;

const institutionsMenu = institutionsMenuFactory(ROUTE);
export const {
  setInstitutionsIsDisabled,
  setSelectedInstitutions,
  updateSelectedInstitutionsByCountry,
} = institutionsMenu.actions;

const variablesMenu = variablesMenuFactory(ROUTE);
export const { setVariablesIsDisabled } = variablesMenu.actions;

const byCategoryReducer = combineReducers({
  countriesMenu: countriesMenu.reducer,
  categoriesMenu: categoriesMenu.reducer,
  institutionsMenu: institutionsMenu.reducer,
  variablesMenu: variablesMenu.reducer,
});
export default byCategoryReducer;
