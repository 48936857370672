import { EventCategory, EventAction } from "./constants";

interface WindowWithGTag extends Window {
  gtag: (...args: any) => void;
}

const getWindowWithGTag = () => {
  return window as unknown as WindowWithGTag;
};

export const trackCustomEvent = (category: EventCategory, action: EventAction, label: string) => {
  if (getWindowWithGTag().gtag) {
    getWindowWithGTag().gtag("event", action, {
      event_category: category,
      event_label: label,
    });
  }
};
