import React, { FC, useState } from "react";

import { Button, Menu, Row, Col, Grid } from "antd";
import { DownOutlined } from "@ant-design/icons";
import localforage from "localforage";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import Container from "../../components/Container";

import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";
import { DB_NAME, TABLE, LOCAL_STORAGE_EVENT } from "./constants";
import ComparePins from "./ComparePins";
import InstitutionPins from "./InstitutionPins";
import VariablePins from "./VariablePins";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";

import { SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../../constants";

const { useBreakpoint } = Grid;

const route = SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.myPinnedData];

enum PinType {
  IndividualVariables = "individual-variables",
  ComparedVariables = "compared-variables",
}

enum IndividualVariablesView {
  ByInstitution = "by-institution",
  ByVariable = "by-variable",
}

const MyPinnedDataPage: FC = () => {
  useDocumentTitle(route.title);

  const [selectedPinType, setSelectedPinType] = useState<string>(
    IndividualVariablesView.ByInstitution
  );
  const onSelectionPinTypeChange = ({ selectedKeys }: { selectedKeys: string[] }) =>
    setSelectedPinType(selectedKeys[0]);

  const handClearAllPins = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    await institutions.clear();
    window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.INSTITUTIONS));

    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    await compare.clear();
    window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.COMPARE));
    trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, "All data");
  };

  const { xs } = useBreakpoint();

  return (
    <>
      <PageHeader title={route.title} description={route.description} imageUrl={route.img} />
      <Container>
        <Row justify="space-between" align="middle" gutter={[0, 16]} wrap={true}>
          <Col
            xs={24}
            style={{
              display: "flex",
              flexDirection: xs ? "column" : "row",
              justifyContent: xs ? "flex-start" : "space-between",
              alignItems: xs ? "stretch" : "center",
              rowGap: 8,
              borderBottom: "1px solid #d9d9d9",
            }}
          >
            <Menu
              id="pin-page-menu"
              selectedKeys={[selectedPinType]}
              onSelect={onSelectionPinTypeChange}
              getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
              theme="light"
              mode={xs ? "inline" : "horizontal"}
              triggerSubMenuAction="click"
              style={{
                backgroundColor: xs ? "white" : "transparent",
                margin: xs ? "2px" : 0,
                border: "unset",
              }}
              // This prop name may change
              disabledOverflow={true}
            >
              <Menu.SubMenu
                key="individual-variables"
                title={
                  xs ? (
                    "Individual Variables"
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span>Individual Variables</span>
                      <span style={{ marginLeft: 10 }}>
                        <DownOutlined style={{ fontSize: 12 }} />
                      </span>
                    </div>
                  )
                }
              >
                <Menu.Item key={IndividualVariablesView.ByInstitution}>
                  View by Institution
                </Menu.Item>
                <Menu.Item key={IndividualVariablesView.ByVariable}>View by Variable</Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key={PinType.ComparedVariables}>Compared Variables</Menu.Item>
            </Menu>
            <Button size="large" type="default" onClick={handClearAllPins}>
              Clear all pins
            </Button>
          </Col>
          <Col xs={24} aria-live="polite">
            {selectedPinType === IndividualVariablesView.ByInstitution ? (
              <InstitutionPins />
            ) : selectedPinType === IndividualVariablesView.ByVariable ? (
              <VariablePins />
            ) : (
              <ComparePins />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withErrorBoundary(MyPinnedDataPage);
