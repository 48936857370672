import React, { MouseEventHandler } from "react";
import { HTMLReactParserOptions, Element, domToReact, DOMNode } from "html-react-parser";
import linkify from "linkify-it";

import { EventAction, EventCategory } from "../features/googleAnalytics/constants";
import { trackCustomEvent } from "../features/googleAnalytics/utils";

function domNodeIsElement(domNode: DOMNode): domNode is Element {
  return domNode.type === "tag";
}
const handleClick: (href: string) => MouseEventHandler<HTMLAnchorElement> = (href) => () => {
  trackCustomEvent(EventCategory.externalLink, EventAction.openLink, href);
  return true;
};
export const replaceLinkOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNodeIsElement(domNode) && domNode.attribs) {
      if (domNode.name === "a") {
        return (
          <a
            {...domNode.attribs}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick(domNode.attribs.href)}
          >
            {domToReact(domNode.children, replaceLinkOptions)}
          </a>
        );
      }
    }
  },
};

/**Don't link fuzzy links */
const linkifyIt = linkify({
  fuzzyLink: false,
});

export default function createLinks(text: string) {
  if (!text || text === "") {
    return text;
  }
  const matches = linkifyIt.match(text);
  if (!matches) {
    return text;
  }

  const elements = [];
  let lastIndex = 0;
  matches.forEach((match) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      elements.push(text.substring(lastIndex, match.index));
    }

    const anchor = `<a href=${match.url}>${match.text}</a>`;
    elements.push(anchor);

    lastIndex = match.lastIndex;
  });

  // Push remaining text if there is any
  if (text.length > lastIndex) {
    elements.push(text.substring(lastIndex));
  }

  return elements.join("");
}

export function hasLinks(text: string) {
  if (text.trim() === "") {
    return false;
  }

  const matches = linkifyIt.match(text);
  if (!matches || matches.length === 0) {
    return false;
  }

  return true;
}
