import React, { FC, useState, useEffect } from "react";

import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { TABLE, DB_NAME } from "./constants";
import { InstitutionRecord } from "./recordTypes";
import PinButton from "./PinButton";

interface PinSITProps {
  institution: Institution;
  variables: Variable[];
}

const fetchPinStatus = async (
  institution: Institution,
  variables: Variable[],
  setIsPinned: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const institutions = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.INSTITUTIONS,
  });
  const key = `${institution.country || institution.category}>${institution.name}`;
  let pinStatus = true;
  const record = await institutions.getItem<InstitutionRecord>(key);
  if (record) {
    for (let i = 0; i < variables.length; i++) {
      if (!_.has(record.variables, `${variables[i].variable_index}`)) {
        pinStatus = false;
        break;
      }
    }
  } else {
    pinStatus = false;
  }
  setIsPinned(pinStatus);
};

const PinSIT: FC<PinSITProps> = ({ institution, variables }) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);
  const key = `${institution.country || institution.category}>${institution.name}`;

  useEffect(() => {
    const handleFetchPinStatus = () => {
      fetchPinStatus(institution, variables, setIsPinned);
    };
    handleFetchPinStatus();

    window.addEventListener(`${TABLE.INSTITUTIONS}/${key}`, handleFetchPinStatus);

    return () => window.removeEventListener(`${TABLE.INSTITUTIONS}/${key}`, handleFetchPinStatus);
  }, [key, institution, variables]);

  const onPin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const variablesDict = _.reduce(
      variables,
      (obj, variable) => {
        obj[`${variable.variable_index}`] = variable;
        return obj;
      },
      {} as Record<string, Variable>
    );
    const record: InstitutionRecord = {
      institution,
      variables: variablesDict,
    };

    await institutions.setItem(key, record);
    setIsPinned(true);
    _.forEach(variables, ({ variable_index }) => {
      window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}/${variable_index}`));
    });
  };

  const onUnpin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    await institutions.removeItem(key);
    setIsPinned(false);
    _.forEach(variables, ({ variable_index }) => {
      window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}/${variable_index}`));
    });
  };

  return <PinButton isPinned={isPinned} type="table" onPin={onPin} onUnpin={onUnpin} />;
};

export default PinSIT;
