import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCountries } from "../../api";
import { AppThunk } from "../../app/store";

interface CountriesState {
  countries: string[];
  isLoading: boolean;
  error: string | null;
}

const initialCountriesState: CountriesState = {
  countries: [],
  isLoading: false,
  error: null,
};

const countries = createSlice({
  name: "countries",
  initialState: initialCountriesState,
  reducers: {
    getCountriesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getCountriesSuccess(state, { payload }: PayloadAction<string[]>) {
      state.isLoading = false;
      state.error = null;
      state.countries = payload;
    },
    getCountriesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const { getCountriesStart, getCountriesSuccess, getCountriesFailure } = countries.actions;

export default countries.reducer;

export const fetchCountries = (): AppThunk => async (dispatch, state) => {
  const countries = state().countries.countries;
  if (countries.length === 0) {
    try {
      dispatch(getCountriesStart());
      const countries = await getCountries();
      dispatch(getCountriesSuccess(countries));
    } catch (err) {
      dispatch(getCountriesFailure(err.toString()));
    }
  }
};
