import React, { CSSProperties, useEffect } from "react";

import { DownOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, notification } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";

import { APP_UI_STATE, useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { SiglaTriple } from "../../api/variable";

import { fetchCountries } from "../countries/countriesSlice";
import { fetchCategories } from "../categories/categoriesSlice";
import {
  setSelectedCategories,
  setSelectedCountries,
  setSelectedInstitutions,
  setVariablesIsDisabled,
  updateSelectedInstitutionsByCountry,
  setCountriesIsDisabled,
  setInstitutionsIsDisabled,
} from "./compareInstitutionsSlice";

import { fetchInstitutionsByCategory } from "../institutions/institutionsSlice";
import { fetchVariables } from "../variables/variablesSlice";

import { getCategoriesIsLoading } from "../categories/categoriesSelectors";
import { getCountriesIsLoading } from "../countries/countriesSelectors";
import {
  getCategoryNodes,
  getSelectedCategories,
  getHasSelectedInternationalInstitutions,
  getSelectedCountries,
  getCountriesIsDisabled,
  getCountriesPlaceholder,
  makeGetCountryNodes,
  getInstitutionByName,
  getSelectedInstitutions,
  getSelectedInstitutionStrings,
  getSelectedInstitutionIds,
  getInstitutionsIsDisabled,
  getInstitutionsPlaceholder,
  getVariablesIsDisabled,
  getSelectedInstitutionData,
  getSeeDataButtonIsDisabled,
} from "./compareInstitutionsSelectors";
import { getInstitutionsIsLoading } from "../institutions/institutionsSelectors";
import { getVariablesIsLoading, getVariablesError } from "../variables/variablesSelectors";

import AssertiveContainer from "../../components/AssertiveContainer";
import Container from "../../components/Container";
import NotificationHint from "../../components/NotificationHint";
import SeeDataButton from "../../components/SeeDataButton";
import SelectDropdown from "../../components/SelectDropdown";
import SelectRow from "../../components/SelectRow";
import PageHeader from "../../components/PageHeader";
import VisuallyHidden from "../../components/VisuallyHidden";
import withErrorBoundary from "../../components/withErrorBoundary";
import CompareInstitutionsSelect from "./CompareInstitutionsSelect";
import CompareTable from "./CompareTable";
import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";

import { selectColumn, seeDataColumn } from "../../styles/breakpoints";
import { SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../../constants";

const route = SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.compare];

const CompareInstitutionPage = () => {
  useDocumentTitle(route.title);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchCountries());
  }, [dispatch]);

  const categoriesIsLoading = useSelector(getCategoriesIsLoading);
  const categoryNodes = useSelector(getCategoryNodes);
  const selectedCategories = useSelector(getSelectedCategories);
  const hasSelectedInternationalInstitutions = useSelector(getHasSelectedInternationalInstitutions);

  const countriesIsLoading = useSelector(getCountriesIsLoading);
  const countriesIsDisabled = useSelector(getCountriesIsDisabled);
  const countriesPlaceholder = useSelector(getCountriesPlaceholder);
  const countryNodes = useSelector(makeGetCountryNodes(selectedCategories[0]));
  const selectedCountries = useSelector(getSelectedCountries);

  const institutionsIsLoading = useSelector(getInstitutionsIsLoading);
  const institutionsIsDisabled = useSelector(getInstitutionsIsDisabled);
  const institutionsPlaceHolder = useSelector(getInstitutionsPlaceholder);
  const institutionByName = useSelector(getInstitutionByName);
  const selectedInstitutions = useSelector(getSelectedInstitutions);
  const selectedInstitutionStrings = useSelector(getSelectedInstitutionStrings);
  const selectedInstitutionIds = useSelector(getSelectedInstitutionIds);

  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variablesIsDisabled = useSelector(getVariablesIsDisabled);
  const variablesError = useSelector(getVariablesError);
  const selectedInstitutionData = useSelector(getSelectedInstitutionData);

  const seeDataButtonIsDisabled = useSelector(getSeeDataButtonIsDisabled);

  const onSelectCategory = (categories: string[]) => {
    dispatch(setSelectedCategories(categories));
    dispatch(fetchInstitutionsByCategory(categories[0]));
    if (categories[0] === INTERNATIONAL_INSTITUTIONS) {
      dispatch(setCountriesIsDisabled(true));
      dispatch(setInstitutionsIsDisabled(false));
    }
    dispatch(setSelectedCountries([]));
    dispatch(setSelectedInstitutions([]));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectCountry = (countries: string[]) => {
    dispatch(setSelectedCountries(countries));
    dispatch(updateSelectedInstitutionsByCountry(countries));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectInstitution = (institutions: string[]) => {
    const selectedInstitutions = _.map(institutions, (institution) => {
      const [category, name] = _.split(institution, ">");
      return {
        category,
        name,
      };
    });
    dispatch(setSelectedInstitutions(selectedInstitutions));
    dispatch(setVariablesIsDisabled(true));
  };

  const onClickSeeData = () => {
    dispatch(fetchVariables(selectedInstitutionIds));
    dispatch(setVariablesIsDisabled(selectedInstitutions.length === 0));
    trackCustomEvent(EventCategory.dataRequest, EventAction.requestData, SIGLA_ROUTES.compare);
  };
  let content;
  if (!variablesIsDisabled && !variablesIsLoading && variablesError === null) {
    content = (
      <CompareTable
        institutions={selectedInstitutionData}
        pin={true}
        title={
          selectedInstitutions.length > 1
            ? selectedInstitutionData[0].institution.category
            : selectedInstitutions[0].name
        }
      />
    );
  }

  useEffect(() => {
    if (variablesError !== null) {
      notification.error({
        message: "Unable to fetch comparison table. Please try again.",
        description: `${variablesError}`,
        duration: 5,
      });
    }
  }, [variablesError]);

  const comparisonDataIconStyle: CSSProperties = {
    border: `1px solid #d9d9d9`,
    borderRadius: "2px",
  };
  const comparisonDataDescription = (
    <ul>
      <li>
        <span>{"Drag the "}</span>
        <MenuOutlined rotate={90} />
        <span>{" icon to reorder table columns"}</span>
      </li>
      <li>
        <span>{"Click the "}</span>
        <VisuallyHidden
          icon={<PlusOutlined style={comparisonDataIconStyle} />}
          text="expand heading row"
        />
        <span>{" button to view variables for each Heading"}</span>
      </li>
      <li>
        <span>{"Click the "}</span>
        <VisuallyHidden
          icon={<DownOutlined style={comparisonDataIconStyle} />}
          text="expand variable row"
        />
        <span>{` button to view ${SiglaTriple.originalText} and ${SiglaTriple.source} for ${SiglaTriple.answer}`}</span>
      </li>
    </ul>
  );

  return (
    <>
      <PageHeader title={route.title} description={route.description} imageUrl={route.img} />
      <Container>
        <NotificationHint
          showHint={!variablesIsDisabled && !variablesIsLoading && variablesError === null}
          appUiStateKey={APP_UI_STATE.DISABLE_COMPARE_ROUTE_HINT}
          message={"Navigating Comparison Tables"}
          description={comparisonDataDescription}
          duration={0}
        />
        <SelectRow>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={false}
              loading={categoriesIsLoading}
              multiple={false}
              options={categoryNodes}
              placeholder="1. Choose an institutional category"
              selectedOptions={selectedCategories}
              onChangeCb={onSelectCategory}
            />
          </Col>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={countriesIsDisabled}
              loading={countriesIsLoading || institutionsIsLoading}
              multiple={true}
              options={countryNodes}
              placeholder={countriesPlaceholder}
              selectedOptions={selectedCountries}
              onChangeCb={onSelectCountry}
            />
          </Col>
          <Col {...selectColumn}>
            <CompareInstitutionsSelect
              disabled={institutionsIsDisabled}
              institutionByName={institutionByName}
              loading={institutionsIsLoading}
              multiple={hasSelectedInternationalInstitutions}
              numberOfSelectedCountries={selectedCountries.length}
              placeholder={institutionsPlaceHolder}
              selectedOptions={selectedInstitutionStrings}
              onChangeCb={onSelectInstitution}
            />
          </Col>
          <Col
            {...seeDataColumn}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SeeDataButton
              disabled={seeDataButtonIsDisabled}
              loading={variablesIsLoading}
              onClickCb={onClickSeeData}
            />
          </Col>
        </SelectRow>
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <AssertiveContainer>{content}</AssertiveContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withErrorBoundary(CompareInstitutionPage);
