import React, { FC } from "react";

interface InvisibleTextProps {
  text: string;
}

const InvisibleText: FC<InvisibleTextProps> = ({ text }) => {
  return <span style={{ opacity: 0 }}>{text}</span>;
};

export default InvisibleText;
