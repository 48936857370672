import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getRights } from "../../api";
import { Right } from "../../api/right";
import { AppThunk } from "../../app/store";

interface RightsState {
  rights: Right[];
  isLoading: boolean;
  error: string | null;
}

const initialRightsState: RightsState = {
  rights: [],
  isLoading: false,
  error: null,
};

const rights = createSlice({
  name: "rights",
  initialState: initialRightsState,
  reducers: {
    getRightsSuccess(state, { payload }: PayloadAction<Right[]>) {
      state.isLoading = false;
      state.error = null;
      state.rights = payload;
    },
    getRightsStart(state) {
      state.isLoading = true;
    },
    getRightsFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { getRightsSuccess, getRightsStart, getRightsFailure } = rights.actions;

export default rights.reducer;

export const fetchRights =
  (variable: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRightsStart());
      const rights = await getRights(variable);
      dispatch(getRightsSuccess(rights));
    } catch (err) {
      dispatch(getRightsFailure(err.toString()));
    }
  };
