import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getBodyOfLaw } from "../../api";
import { Law } from "../../api/bodyOfLaw";
import { AppThunk } from "../../app/store";

interface BodyOfLawState {
  laws: Law[];
  isLoading: boolean;
  error: string | null;
}

const initialBodyOfLawState: BodyOfLawState = {
  laws: [],
  isLoading: false,
  error: null,
};

const bodyOfLaw = createSlice({
  name: "bodyOfLaw",
  initialState: initialBodyOfLawState,
  reducers: {
    getBodyOfLawSuccess(state, { payload }: PayloadAction<Law[]>) {
      state.isLoading = false;
      state.error = null;
      state.laws = payload;
    },
    getBodyOfLawStart(state) {
      state.isLoading = true;
    },
    getBodyOfLawFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { getBodyOfLawSuccess, getBodyOfLawStart, getBodyOfLawFailure } = bodyOfLaw.actions;

export default bodyOfLaw.reducer;

export const fetchBodyOfLaw =
  (variable: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getBodyOfLawStart());
      const bodyOfLaw = await getBodyOfLaw(variable);
      dispatch(getBodyOfLawSuccess(bodyOfLaw));
    } catch (err) {
      dispatch(getBodyOfLawFailure(err.toString()));
    }
  };
