import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { Col } from "antd";
import _ from "lodash";

import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { INTERNATIONAL_INSTITUTIONS } from "../../api/institution";

import { fetchCountries } from "../countries/countriesSlice";
import { fetchCategories } from "../categories/categoriesSlice";
import {
  setSelectedCategories,
  setSelectedCountries,
  setSelectedInstitutions,
  setVariablesIsDisabled,
  updateSelectedInstitutionsByCountry,
  setCountriesIsDisabled,
  setInstitutionsIsDisabled,
} from "./byCategorySlice";

import { fetchInstitutionsByCategory } from "../institutions/institutionsSlice";
import { fetchVariables } from "../variables/variablesSlice";

import { getCategoriesIsLoading } from "../categories/categoriesSelectors";
import { getCountriesIsLoading } from "../countries/countriesSelectors";
import {
  getCategoryNodes,
  getSelectedCategories,
  getHasSelectedInternationalInstitutions,
  getSelectedCountries,
  getCountriesPlaceholder,
  makeGetCountryNodes,
  getInstitutionNodes,
  getSelectedInstitutions,
  getInstitutionsPlaceholder,
  getSelectedInstitutionStrings,
  getVariablesIsDisabled,
  getSelectedInstitutionData,
} from "./byCategorySelectors";
import { getInstitutionsIsLoading } from "../institutions/institutionsSelectors";
import { getVariablesIsLoading } from "../variables/variablesSelectors";

import AssertiveContainer from "../../components/AssertiveContainer";
import Container from "../../components/Container";
import SeeDataButton from "../../components/SeeDataButton";
import SelectDropdown from "../../components/SelectDropdown";
import SelectRow from "../../components/SelectRow";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";
import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";
import SITGroup from "../institutions/SITGroup";

import { selectColumn, seeDataColumn } from "../../styles/breakpoints";
import { SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../../constants";

const route = SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.byInstitution];

const InstitutionByCategoryPage: FC = () => {
  useDocumentTitle(route.title);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchCountries());
  }, [dispatch]);

  const categoriesIsLoading = useSelector(getCategoriesIsLoading);
  const categoryNodes = useSelector(getCategoryNodes);
  const selectedCategories = useSelector(getSelectedCategories);
  const hasSelectedInternationalInstitutions = useSelector(getHasSelectedInternationalInstitutions);

  const countriesIsLoading = useSelector(getCountriesIsLoading);
  const countriesPlaceholder = useSelector(getCountriesPlaceholder);
  const countryNodes = useSelector(makeGetCountryNodes(selectedCategories[0]));
  const selectedCountries = useSelector(getSelectedCountries);

  const institutionsIsLoading = useSelector(getInstitutionsIsLoading);
  const institutionsPlaceholder = useSelector(getInstitutionsPlaceholder);
  const institutionNodes = useSelector(getInstitutionNodes);
  const selectedInstitutions = useSelector(getSelectedInstitutions);
  const selectedInstitutionStrings = useSelector(getSelectedInstitutionStrings);

  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variablesIsDisabled = useSelector(getVariablesIsDisabled);
  const selectedInstitutionData = useSelector(getSelectedInstitutionData);

  const onSelectCategory = (categories: string[]) => {
    dispatch(setSelectedCategories(categories));
    dispatch(fetchInstitutionsByCategory(categories[0]));
    if (categories[0] === INTERNATIONAL_INSTITUTIONS) {
      dispatch(setCountriesIsDisabled(true));
      dispatch(setInstitutionsIsDisabled(false));
    }
    dispatch(setSelectedCountries([]));
    dispatch(setSelectedInstitutions([]));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectCountry = (countries: string[]) => {
    dispatch(setSelectedCountries(countries));
    dispatch(updateSelectedInstitutionsByCountry(countries));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectInstitution = (institutions: string[]) => {
    const selectedInstitutions = _.map(institutions, (institution) => {
      const [category, country, name, _id] = _.split(institution, ">");
      return {
        category,
        country,
        name,
        _id,
      };
    });
    dispatch(setSelectedInstitutions(selectedInstitutions));
    dispatch(setVariablesIsDisabled(true));
  };

  const onClickSeeData = () => {
    dispatch(fetchVariables(_.map(selectedInstitutions, ({ _id }) => _id)));
    dispatch(setVariablesIsDisabled(selectedInstitutions.length === 0));
    trackCustomEvent(
      EventCategory.dataRequest,
      EventAction.requestData,
      SIGLA_ROUTES.byInstitution
    );
  };

  let specificInstitutionsTable;
  if (!variablesIsDisabled && !variablesIsLoading) {
    specificInstitutionsTable = (
      <SITGroup
        furled={true}
        breadcrumbs={["category", "country", "name"]}
        sits={selectedInstitutionData}
        pin={true}
      />
    );
  }

  return (
    <>
      <PageHeader title={route.title} description={route.description} imageUrl={route.img} />
      <Container>
        <SelectRow>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={false}
              loading={categoriesIsLoading}
              multiple={false}
              onChangeCb={onSelectCategory}
              options={categoryNodes}
              selectedOptions={selectedCategories}
              placeholder="1. Choose an institutional category"
            />
          </Col>
          <Col {...selectColumn}>
            <SelectDropdown
              loading={countriesIsLoading || institutionsIsLoading}
              disabled={hasSelectedInternationalInstitutions || selectedCategories.length === 0}
              multiple={true}
              onChangeCb={onSelectCountry}
              options={countryNodes}
              selectedOptions={selectedCountries}
              placeholder={countriesPlaceholder}
            />
          </Col>
          <Col {...selectColumn}>
            <SelectDropdown
              loading={institutionsIsLoading}
              disabled={selectedCountries.length === 0 && !hasSelectedInternationalInstitutions}
              multiple={true}
              onChangeCb={onSelectInstitution}
              options={institutionNodes}
              selectedOptions={selectedInstitutionStrings}
              placeholder={institutionsPlaceholder}
            />
          </Col>
          <Col
            {...seeDataColumn}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SeeDataButton
              loading={variablesIsLoading}
              disabled={selectedInstitutions.length === 0}
              onClickCb={onClickSeeData}
            />
          </Col>
        </SelectRow>
        <AssertiveContainer>{specificInstitutionsTable}</AssertiveContainer>
      </Container>
    </>
  );
};

export default withErrorBoundary(InstitutionByCategoryPage);
