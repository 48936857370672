import React, { FC, useState } from "react";

import { Col, Pagination, Row } from "antd";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable, VariableType } from "../../api/variable";

import SpecificInstitutionTable from "./SpecificInstitutionTable";
import RightsTable from "../rights/RightsTable";

interface SITGroupProps {
  /**are rows inistially expanded */
  furled: boolean;
  /**paths taken to retrieve SITs, browse by country or browse by instituion */
  breadcrumbs: string[];
  /**institutions and their variables */
  sits: {
    institution: Institution;
    variables: Variable[][];
  }[];
  /**is institution pinnable */
  pin: boolean;
}

const NUMBER_OF_SITS_PER_PAGE = 10;

const SITGroup: FC<SITGroupProps> = ({ furled, breadcrumbs, sits, pin }) => {
  const [current, setCurrent] = useState<number>(1);
  const onChange = (page: number) => {
    setCurrent(page);
  };

  const [pageSize, setPageSize] = useState<number>(NUMBER_OF_SITS_PER_PAGE);
  const onPageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  const activeSITs = _.slice(sits, pageSize * (current - 1), pageSize * (current - 1) + pageSize);

  return (
    <Row gutter={[0, 32]} justify="end" style={{ marginTop: 16 }}>
      {activeSITs.map(({ institution, variables }) => (
        <Col key={institution._id} span={24}>
          {variables[0][0].type === VariableType.aggregate ? (
            <RightsTable
              breadcrumbs={breadcrumbs}
              furled={furled}
              institution={institution}
              variables={variables}
              pin={pin}
              columnsType={["filter", "search", "search", "basic"]}
            />
          ) : (
            <SpecificInstitutionTable
              breadcrumbs={breadcrumbs}
              furled={furled}
              institution={institution}
              variables={variables}
              pin={pin}
            />
          )}
        </Col>
      ))}
      <Col>
        <Pagination
          current={current}
          showSizeChanger
          pageSize={pageSize}
          pageSizeOptions={["5", "10", "20", "50"]}
          total={sits.length}
          onChange={onChange}
          onShowSizeChange={onPageSizeChange}
        />
      </Col>
    </Row>
  );
};

export default SITGroup;
