import { createSelector } from "@reduxjs/toolkit";
import { DataNode } from "rc-tree-select/lib/interface";
import _ from "lodash";

import { RootState } from "../../app/rootReducer";
import { getCategories } from "../categories/categoriesSelectors";
import { getCountries } from "../countries/countriesSelectors";

import {
  makeGetCategoriesPerCountry,
  getById as getInstitutionById,
  getByCountryByCatgory,
} from "../institutions/institutionsSelectors";
import { getByInstitution, getById as getVariableById } from "../variables/variablesSelectors";

import {
  executiveSortByConfig,
  executiveSortOrderConfig,
  byCountrySortByConfig,
  byCountrySortOrderConfig,
} from "../../utils/createSortConfig";

export const getCountriesIsDisabled = (state: RootState) =>
  state.byCountry.countriesMenu.isDisabled;
export const getSelectedCountries = (state: RootState) =>
  state.byCountry.countriesMenu.selectedCountries;
export const getCountryNodes = createSelector([getCountries], (countries) => {
  return _.map(countries, (country) => {
    return {
      key: country,
      title: country,
      value: country,
    };
  });
});

export const getCategoriesIsDisabled = (state: RootState) =>
  state.byCountry.categoriesMenu.isDisabled;
export const getSelectedCategories = (state: RootState) =>
  state.byCountry.categoriesMenu.selectedCategories;
export const makeGetCategoryNodes = (country: string) =>
  createSelector(
    [getCategories, getSelectedCountries, makeGetCategoriesPerCountry(country)],
    (categories, selectedCountries, categoriesPerCountry) => {
      const categoriesToDisplay = selectedCountries.length > 0 ? categoriesPerCountry : categories;
      return _.map(categoriesToDisplay, (category) => {
        return {
          key: category,
          title: category,
          value: category,
        };
      });
    }
  );

export const getInstitutionsIsDisabled = (state: RootState) =>
  state.byCountry.institutionsMenu.isDisabled;
export const getSelectedInstitutions = (state: RootState) =>
  state.byCountry.institutionsMenu.selectedInstitutions;
export const getSelectedInstitutionStrings = createSelector(
  [getSelectedInstitutions],
  (selectedInstitutions) =>
    _.map(
      selectedInstitutions,
      ({ category, country, name, _id }) => `${category}>${country}>${name}>${_id}`
    )
);
export const getInstitutionNodes = createSelector(
  [getSelectedCountries, getSelectedCategories, getInstitutionById, getByCountryByCatgory],
  (selectedCountries, selectedCategories, byId, byCountryByCatgory) => {
    const country = selectedCountries[0];
    const byCategory = byCountryByCatgory[country] || {};
    const sortedSelectedCategories = _.sortBy(selectedCategories);
    const nodes = _.reduce(
      sortedSelectedCategories,
      (list, category) => {
        const byInstitutionId = byCategory[category] || {};
        const institutions = _.values(_.mapValues(byInstitutionId, (id) => byId[id]));
        let sortedInstitutions = _.sortBy(institutions, "name");
        /*hacky solution to sort excecutive*/
        if (category === "Executive") {
          sortedInstitutions = _.orderBy(
            institutions,
            executiveSortByConfig,
            executiveSortOrderConfig
          );
        }

        if (sortedInstitutions.length > 0) {
          list.push({
            key: category,
            title: category,
            value: category,
            children: _.map(sortedInstitutions, ({ _id, category, name, country }) => {
              return {
                key: `${category}>${country || category}>${name}>${_id}`,
                title: name,
                value: `${category}>${country || category}>${name}>${_id}`,
              };
            }),
          });
        }
        return list;
      },
      [] as DataNode[]
    );
    return nodes;
  }
);

export const getVariablesIsDisabled = (state: RootState) =>
  state.byCountry.variablesMenu.isDisabled;
export const getSelectedInstitutionData = createSelector(
  [getSelectedInstitutions, getInstitutionById, getByInstitution, getVariableById],
  (selectedInstitutions, institutionById, variableByInstitution, variableById) => {
    const sortedSelectedInstitutions = _.orderBy(
      selectedInstitutions,
      byCountrySortByConfig,
      byCountrySortOrderConfig
    ) as Record<string, any>[];
    const data = _.map(sortedSelectedInstitutions, (institution) => {
      const fullInstitution = institutionById[institution._id];
      const variables = _.sortBy(
        _.values(
          _.mapValues(
            variableByInstitution[institution._id],
            (variableId) => variableById[variableId]
          )
        ),
        "variable_index"
      );
      const variablesByHeading = _.values(_.groupBy(variables, "heading"));
      const sortedVariablesByHeading = _.sortBy(
        variablesByHeading,
        (variablesForAHeading) => variablesForAHeading[0].variable_index
      );
      return {
        institution: fullInstitution,
        variables: sortedVariablesByHeading,
      };
    });
    return data;
  }
);
