import React, { FC, ReactNode } from "react";

import { Row } from "antd";

interface SelectRowProps {
  children: ReactNode;
}

const SelectRow: FC<SelectRowProps> = ({ children }) => {
  return (
    <Row
      gutter={[16, 16]}
      style={{ backgroundColor: "white", padding: "16px", marginLeft: 0, marginRight: 0 }}
    >
      {children}
    </Row>
  );
};

export default SelectRow;
