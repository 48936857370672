import React, { FC, ReactText, ReactNode, useState, useEffect, useRef } from "react";

import { CheckOutlined, CloseOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Space, Input, Button, Tree, Tooltip, Divider, Spin } from "antd";
import { DataNode } from "antd/lib/tree";
import _ from "lodash";
import Highlighter from "react-highlight-words";

import { CustomBrowseTreeNode } from "./customBrowseSelectors";

import { black } from "../../styles/colors";

const { Search } = Input;

interface SearchOptionsProps {
  onSearch(value: string): void;
}

const SearchOptions: FC<SearchOptionsProps> = ({ onSearch }) => {
  return (
    <Search
      aria-label="Search options"
      allowClear={true}
      enterButton={<Button type="default">Search</Button>}
      onSearch={onSearch}
      style={{ width: "70%" }}
    />
  );
};

interface CustomBrowseTreeProps {
  isLoading: boolean;
  searchable: boolean;
  checkedKeys: string[];
  treeData: CustomBrowseTreeNode[];
  flattenedTreeData: CustomBrowseTreeNode[];
  /**Initial expanded tree cutoff level */
  initialExpandedTreeCutoffLevel: number;
  virtual: boolean;
  onCheck(seleckedKeys: any): void;
  onSelectAll(): void;
  onUnselectAll(): void;
}

const CustomBrowseTree: FC<CustomBrowseTreeProps> = ({
  isLoading,
  searchable,
  checkedKeys,
  treeData,
  flattenedTreeData,
  initialExpandedTreeCutoffLevel,
  virtual,
  onCheck,
  onSelectAll,
  onUnselectAll,
}) => {
  const expandedKeysRef = useRef<Record<ReactText, boolean>>({});
  const [expandedKeys, setExpandedKeys] = useState<ReactText[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  /**Update expanded keys*/
  useEffect(() => {
    const newExpandedKeysRef: Record<ReactText, boolean> = {};
    _.forEach(flattenedTreeData, ({ key }) => {
      if (_.has(expandedKeysRef.current, key)) {
        newExpandedKeysRef[key] = expandedKeysRef.current[key];
      } else {
        newExpandedKeysRef[key] =
          _.split(key as string, ">").length < initialExpandedTreeCutoffLevel;
      }
    });
    expandedKeysRef.current = newExpandedKeysRef;

    const newExpandedKeys = _.filter(
      _.keys(expandedKeysRef.current),
      (key) => expandedKeysRef.current[key]
    );
    setExpandedKeys(newExpandedKeys);
  }, [flattenedTreeData, initialExpandedTreeCutoffLevel]);

  const onExpand = (expandedKeys: ReactText[]) => {
    expandedKeysRef.current = _.mapValues(expandedKeysRef.current, (_) => false);
    _.forEach(expandedKeys, (key) => (expandedKeysRef.current[key] = true));
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onSearch = (value: string) => {
    const searchValue = value.toLowerCase().trim();
    const newExpandedKeys =
      searchValue.length > 0
        ? _.reduce(
            flattenedTreeData,
            (list, node) => {
              const title = node.title as string;
              if (_.includes(title.toLowerCase(), searchValue)) {
                const parentKey = getParentKey(node.key, treeData);
                if (node.children) {
                  list.push(node.key);
                }
                if (parentKey) {
                  list.push(parentKey);
                }
              }
              return list;
            },
            [] as ReactText[]
          )
        : [];
    expandedKeysRef.current = _.mapValues(expandedKeysRef.current, (_) => false);
    _.forEach(newExpandedKeys, (key) => (expandedKeysRef.current[key] = true));
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(true);
    setSearchText(value);
  };

  const getParentKey = (key: ReactText, tree: CustomBrowseTreeNode[]): ReactText | undefined => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (_.some(node.children, (child) => child.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const loop = (data: CustomBrowseTreeNode[]) => {
    const highlightedData: DataNode[] = _.map(data, (node) => {
      const title = node.title as string;
      const highlightedTitle: ReactNode = (
        <span style={{ fontSize: node.fontSize, color: node.color }}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText.trim()]}
            autoEscape
            textToHighlight={title ? title.toString() : ""}
          />
        </span>
      );

      if (node.children) {
        return { ...node, title: highlightedTitle, children: loop(node.children) };
      }

      return {
        ...node,
        title: highlightedTitle,
      } as DataNode;
    });
    return highlightedData;
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        style={{
          visibility: isLoading || treeData.length === 0 ? "collapse" : "visible",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Space direction="horizontal">
          <Tooltip
            title="Select all"
            placement="topLeft"
            arrowPointAtCenter
            getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          >
            <Button type="default" size="small" icon={<CheckOutlined />} onClick={onSelectAll} />
          </Tooltip>
          <Tooltip
            title="Unselect all"
            placement="topLeft"
            arrowPointAtCenter
            getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          >
            <Button type="default" size="small" icon={<CloseOutlined />} onClick={onUnselectAll} />
          </Tooltip>
        </Space>
        {searchable && <SearchOptions onSearch={onSearch} />}
      </div>
      {!isLoading && treeData.length > 0 && <Divider style={{ margin: 0, color: black.black_2 }} />}
      {isLoading ? (
        <Spin size="large" tip="Loading ..." />
      ) : (
        <div>
          <Tree
            checkable
            switcherIcon={<CaretDownOutlined style={{ fontSize: 15 }} />}
            autoExpandParent={autoExpandParent}
            checkedKeys={checkedKeys}
            expandedKeys={expandedKeys}
            treeData={loop(treeData)}
            height={window.outerHeight * 0.5}
            virtual={virtual}
            onCheck={onCheck}
            onExpand={onExpand}
          />
        </div>
      )}
    </Space>
  );
};

export default CustomBrowseTree;
