import React, { FC } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import FileSaver from "file-saver";
import _ from "lodash";
import XLSX, { WritingOptions, JSON2SheetOpts } from "xlsx";

import { CompositeVariable, CompositeVariableFunctionDict, getInstitutionById } from "../../api";
import { Variable } from "../../api/variable";
import createSheetsFromCompositeVariable from "./createSheetsFromCompositeVariable";

import createExcelSheetName from "../../utils/createExcelSheetName";

import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";

export interface Sheet {
  title: string;
  headers: string[];
  data: Record<string, string>[];
}

interface DownloadDataProps {
  tooltipTitle: string;
  saveAsFileName: string;
  sheets: Sheet[];
  compositeVariables?: Variable[];
}

export const FILE_EXTENSION = ".xlsx";

const DownloadData: FC<DownloadDataProps> = ({
  tooltipTitle,
  sheets,
  saveAsFileName,
  compositeVariables,
}) => {
  const downloadData = async () => {
    const sheetsCopy = [...sheets];
    if (compositeVariables && compositeVariables.length > 0) {
      const institutionPromises = _.map(compositeVariables, ({ institution }) =>
        getInstitutionById(institution)
      );
      const institutions = await Promise.all(institutionPromises);
      const compositeVariablesWithCountry = _.map(compositeVariables, (cv, i) => {
        return {
          ...cv,
          country: institutions[i].country,
        };
      });
      const unqiqCompositeVariables = _.uniqBy(compositeVariablesWithCountry, (cv) => {
        return `${cv.country} ${cv.name}`;
      });
      const cvPromises = _.map(unqiqCompositeVariables, (variable) => {
        return _.get(CompositeVariableFunctionDict, variable.hyperlink as string)(variable._id);
      });
      const compositeVariableData: CompositeVariable[][] = await Promise.all(cvPromises);
      _.forEach(compositeVariableData, (cv, i) => {
        const tabTitle = `${unqiqCompositeVariables[i].country} ${unqiqCompositeVariables[i].name}`;
        const sheet = createSheetsFromCompositeVariable(cv, tabTitle);
        sheetsCopy.push(sheet);
      });
    }
    const newWorkbook = XLSX.utils.book_new();
    _.forEach(sheetsCopy, (sheet) => {
      const opts: JSON2SheetOpts = { header: sheet.headers, skipHeader: false };
      const worksheet = XLSX.utils.json_to_sheet(sheet.data, opts);
      XLSX.utils.book_append_sheet(newWorkbook, worksheet, createExcelSheetName(sheet.title));
    });

    const wopts: WritingOptions = { bookType: "xlsx", bookSST: false, type: "array" };
    try {
      const wbout = XLSX.write(newWorkbook, wopts);
      FileSaver.saveAs(
        new Blob([wbout], { type: "application/octet-stream" }),
        `${saveAsFileName}${FILE_EXTENSION}`
      );
      //Assume download is the first word in tooltipTitle
      trackCustomEvent(
        EventCategory.dataStorage,
        EventAction.download,
        tooltipTitle.split(" ").slice(1).join(" ")
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Tooltip
      title={tooltipTitle}
      placement="topRight"
      arrowPointAtCenter
      getPopupContainer={(trigger) => trigger}
    >
      <Button
        type="default"
        size="middle"
        icon={<DownloadOutlined style={{ fontSize: "24px" }} />}
        onClick={downloadData}
      />
    </Tooltip>
  );
};

export default DownloadData;
