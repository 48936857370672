import React, { FC, useState, useEffect } from "react";

import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { TABLE, DB_NAME } from "./constants";
import PinButton from "./PinButton";
import { CompareRecord } from "./recordTypes";

interface PinCompareRowProps {
  data: {
    institution: Institution;
    variables: Variable[];
  }[];
  /**The name of the comparison table this comparison row belongs to */
  name: string;
}

const fetchPinStatus = async (
  key: string,
  variableIndex: number,
  setIsPinned: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const compare = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.COMPARE,
  });
  const record = await compare.getItem<CompareRecord>(key);
  const pinStatus = record ? _.has(record.variables, `${variableIndex}`) : false;
  setIsPinned(pinStatus);
};

const PinCompareRow: FC<PinCompareRowProps> = ({ data, name }) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);

  const sortedInstitutions = _.sortBy(data, ["institution.country", "institution.name"]);

  const key = _.join(
    _.map(sortedInstitutions, ({ institution }) => {
      return `${institution.country || institution.category}>${institution.name}`;
    }),
    ">"
  );

  const variableIndex = _.sortBy(data, ({ variables }) => variables[0])[0].variables[0]
    .variable_index;

  useEffect(() => {
    const handleFetchPinStatus = () => {
      fetchPinStatus(key, variableIndex, setIsPinned);
    };
    handleFetchPinStatus();

    window.addEventListener(`${TABLE.COMPARE}/${key}/${variableIndex}`, handleFetchPinStatus);

    return () =>
      window.removeEventListener(`${TABLE.COMPARE}/${key}/${variableIndex}`, handleFetchPinStatus);
  }, [key, variableIndex]);

  const onPin = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    const record = await compare.getItem<CompareRecord>(key);
    const variableList = _.map(sortedInstitutions, ({ variables }) => variables[0] || null);
    const newRecord: CompareRecord = record
      ? { ...record }
      : {
          name: name,
          institutions: _.map(sortedInstitutions, ({ institution }) => institution),
          variables: {},
        };
    newRecord.variables[`${variableIndex}`] = variableList;

    await compare.setItem(key, newRecord);
    setIsPinned(true);
    window.dispatchEvent(new CustomEvent(`${TABLE.COMPARE}/${key}`));
  };

  const onUnpin = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    const record = await compare.getItem<CompareRecord>(key);
    if (record) {
      const newRecord: CompareRecord = { ...record };
      delete newRecord.variables[`${variableIndex}`];

      if (_.keys(newRecord.variables).length > 0) {
        await compare.setItem(key, newRecord);
      } else {
        await compare.removeItem(key);
      }
    }
    setIsPinned(false);
    window.dispatchEvent(new CustomEvent(`${TABLE.COMPARE}/${key}`));
  };

  return <PinButton isPinned={isPinned} type="comparison" onPin={onPin} onUnpin={onUnpin} />;
};

export default PinCompareRow;
