import React, { FC } from "react";

import { fontSizes } from "../styles/fonts";

const VariableIcon: FC = () => {
  return (
    <span aria-label="variable" style={{ fontSize: fontSizes.font_size_7 }}>
      &#91;&#120;&#93;
    </span>
  );
};

export default VariableIcon;
