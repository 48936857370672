import React, { CSSProperties, FC, Fragment } from "react";

import { Divider } from "antd";
import parse from "html-react-parser";

import createLinks, { replaceLinkOptions } from "../utils/createLink";

import { fontSizes } from "../styles/fonts";
import { black } from "../styles/colors";

interface AggregateTableCellProps {
  siglaAnswers: Record<string, string>[][];
}

const cellStyle: CSSProperties = {
  maxHeight: "300px",
  overflow: "auto",
};

const rightsStyle: CSSProperties = {
  fontSize: fontSizes.font_size_5,
  color: black.black_2,
  paddingRight: "8px",
};

const beneficiariesStyle: CSSProperties = {
  fontSize: fontSizes.font_size_4,
  color: black.black_3,
  paddingRight: "8px",
};

const dividerStyle: CSSProperties = {
  margin: "8px 0",
};

const AggregateTableCell: FC<AggregateTableCellProps> = ({ siglaAnswers }) => {
  return (
    <div className="aggregate-table-cell" style={cellStyle}>
      {siglaAnswers.map((item, i) => {
        return (
          <Fragment key={i}>
            <div style={rightsStyle}>{parse(createLinks(item[1].answer), replaceLinkOptions)}</div>
            <div style={beneficiariesStyle}>{`(Beneficiaries: ${item[0].answer})`}</div>
            {i < siglaAnswers.length - 1 && <Divider style={dividerStyle} />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default AggregateTableCell;
