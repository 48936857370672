import axios from "axios";

import { SiglaAnswer } from "./";
import { API_BASE_URL } from "./constants";

const API_URL = `${API_BASE_URL}amendments`;

export interface Amendment {
  _id: string;
  variable: string;
  index: number;
  sigla_answers: SiglaAnswer[];
}

export const getAmendments = async (variable: string) => {
  const url = `${API_URL}?id=${variable}`;
  const { data } = await axios.get<Amendment[]>(url);
  return data;
};
