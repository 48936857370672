import React, { FC } from "react";

import { Row, Col, Input, Button, Grid } from "antd";

const { useBreakpoint } = Grid;

interface SearchTableColumnProps {
  dataIndex: string;
  placeholderText: string;
  selectedKeys: React.Key[];
  setSelectedKeys(selectedKeys: React.Key[]): void;
  confirm(): void;
  clearFilters?(): void;
  handleSearch(selectedKeys: string[], confirm: () => void, dataIndex: string): void;
  handleReset(dataIndex: string, clearFilters?: () => void): void;
}

const SearchTableColumn: FC<SearchTableColumnProps> = ({
  dataIndex,
  placeholderText,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset,
}) => {
  const { xs } = useBreakpoint();
  return (
    <div style={{ padding: 8, width: xs ? "50vw" : "25vw" }}>
      <Row gutter={[8, 8]} justify="space-between" style={{ marginBottom: 0 }}>
        <Col span={24}>
          <Input
            aria-label={`Search ${dataIndex}`}
            placeholder={placeholderText}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          />
        </Col>
        <Col>
          <Button type="default" size="small" onClick={() => handleReset(dataIndex, clearFilters)}>
            Reset
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            size="small"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          >
            Search
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SearchTableColumn;
