import React, { FC, ReactNode, CSSProperties } from "react";

import { Grid } from "antd";

const { useBreakpoint } = Grid;

interface ContainerProps {
  children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => {
  const { lg } = useBreakpoint();
  const style: CSSProperties = {
    padding: lg ? "32px" : "0",
  };
  return <div style={style}>{children}</div>;
};

export default Container;
