import React, { FC, CSSProperties, ReactNode, isValidElement } from "react";

import { Typography, Button } from "antd";
import parse from "html-react-parser";

import { black } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

import createLinks, { hasLinks, replaceLinkOptions } from "../utils/createLink";

const { Paragraph } = Typography;

interface TableCellProps {
  showEllipsis: boolean;
  numberOfRows?: number;
  fontWeight: number;
  children: ReactNode;
}

const cellStyle: CSSProperties = {
  padding: "2px",
  maxHeight: "250px",
  overflow: "auto",
};

const TableCell: FC<TableCellProps> = ({ showEllipsis, children, fontWeight, numberOfRows }) => {
  const paragraphStyle: CSSProperties = {
    whiteSpace: "pre-wrap",
    fontWeight: fontWeight,
    color: black.black_2,
    marginBottom: 0,
  };

  const childrenIsText = !isValidElement(children);

  let ellipsisable = childrenIsText;
  if (childrenIsText) {
    const text = children as string;
    ellipsisable = !hasLinks(text);
  }

  const paragraph =
    // children is text and doesn't have links
    showEllipsis && ellipsisable ? (
      <Paragraph
        ellipsis={{
          expandable: true,
          rows: numberOfRows || 2,
          //This relies on event bubbling, so that clicking this button bubbles click event to expand table cell
          symbol: (
            <Button
              className="see-more-btn"
              style={{
                padding: 0,
                margin: 0,
                border: 0,
                height: "100%",
                fontSize: fontSizes.font_size_5,
                overflow: "visible",
              }}
              type="link"
            >
              See more
            </Button>
          ),
        }}
        style={paragraphStyle}
      >
        {parse(children as string)}
      </Paragraph>
    ) : (
      // children isn't text or has links
      <Paragraph style={paragraphStyle}>
        {childrenIsText ? parse(createLinks(children as string), replaceLinkOptions) : children}
      </Paragraph>
    );

  return (
    <div className="table-cell" style={cellStyle}>
      {paragraph}
    </div>
  );
};

export default TableCell;
