import React, { CSSProperties, FC, ReactNode } from "react";

import { black } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

interface TitleProps {
  fontWeight?: number;
  children: ReactNode;
}

const DEFAULT_FONTWEIGHT = 700;

const Title: FC<TitleProps> = ({ fontWeight, children }) => {
  const style: CSSProperties = {
    color: black.black_2,
    fontWeight: fontWeight || DEFAULT_FONTWEIGHT,
    fontSize: fontSizes.font_size_8,
    whiteSpace: "pre-wrap",
  };
  return <div style={style}>{children}</div>;
};

export default Title;
