import React, { Component, CSSProperties } from "react";

const layoutStyle: CSSProperties = {
  padding: "3em",
};

const detailsStyle: CSSProperties = {
  marginTop: "0.7em",
  whiteSpace: "pre-wrap",
};

const sectionStyle: CSSProperties = {
  marginTop: "0.7em",
};

class GeneralErrorBoundary extends Component<
  {},
  {
    error?: Error | null;
    info: {
      componentStack?: string;
    };
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      info: {
        componentStack: "",
      },
    };
  }

  componentDidCatch(error: Error | null, info: object) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      info,
    });
  }

  // Note: error.stack is non-standard and is not on a standards track. It shouldn't be used in production,
  // but this is just displaying the stack trace.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Stack
  render() {
    const { error, info } = this.state;
    const componentStack = info && info.componentStack ? info.componentStack : null;
    const errorMessage = (error || "").toString();
    if (error) {
      // Render error path
      return (
        <div style={layoutStyle}>
          <h2>Something went wrong.</h2>
          <div>
            Sorry about that. To alert us of the issue, please fill out an{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/sigla-gu/sigla-web/issues"
            >
              issue ticket
            </a>{" "}
            and we will try to fix it as soon as we can.
          </div>
          <br />
          <div>{errorMessage}</div>
          <details style={detailsStyle}>
            {error && (
              <section style={sectionStyle}>
                <strong>Error Stack Trace</strong>
                <br />
                <span>{error.stack}</span>
              </section>
            )}
            <section style={sectionStyle}>
              <strong>Component Stack Trace</strong>
              <span>{componentStack}</span>
            </section>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default GeneralErrorBoundary;
