import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { CompositeVariable } from "../../api";
import { RootState } from "../../app/rootReducer";

import { makeGetVariableById } from "../variables/variablesSelectors";

export const getAmendmentsIsLoading = (state: RootState) => state.amendments.isLoading;
const getAmendments = (state: RootState) => state.amendments.amendments;

export const makeGetCompositeVariables = (variableId: string) =>
  createSelector([getAmendments, makeGetVariableById(variableId)], (amendments, variable) => {
    return [
      _.map(amendments, (amendment) => {
        return {
          _id: amendment._id,
          index: amendment.index,
          heading: `${variable ? variable.name : ""}`,
          variable: variableId,
          sigla_answers: amendment.sigla_answers,
        } as CompositeVariable;
      }),
    ];
  });
