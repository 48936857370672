import React from "react";

import NavBar from "./NavBar";
import SkipLink from "./SkipLink";

const AppHeader = () => {
  return (
    <header id="sigla-app-header">
      <SkipLink />
      <NavBar />
    </header>
  );
};

export default AppHeader;
