import React, { FC, useEffect, useState } from "react";

import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Pagination, Space, Tooltip, Button } from "antd";
import localforage from "localforage";
import _ from "lodash";

import { CompositeVariable, CompositeVariableFunctionDict } from "../../api";
import { Institution } from "../../api/institution";
import { Variable, VariableType } from "../../api/variable";

import createSheetsFromCompareTable from "../downloadData/createSheetsFromCompareTable";
import { Sheet } from "../downloadData/DownloadData";
import createSheetsFromCompositeVariable from "../downloadData/createSheetsFromCompositeVariable";
import createZipFile from "../downloadData/createZipFile";
import CompareTable from "../compareInstitution/CompareTable";
import { trackCustomEvent } from "../googleAnalytics/utils";
import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { DB_NAME, TABLE, LOCAL_STORAGE_EVENT } from "./constants";
import { CompareRecord } from "./recordTypes";

interface InstitutionData {
  institution: Institution;
  variables: Variable[][];
}

interface ComparisonPin {
  data: InstitutionData[];
  name: string;
}

const NUMBER_OF_PINS_PER_PAGE = 10;

const fetchPins = async (setPins: React.Dispatch<React.SetStateAction<ComparisonPin[]>>) => {
  const compare = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.COMPARE,
  });
  const keys = await compare.keys();
  const comparePromises = _.map(keys, (key) => compare.getItem<CompareRecord>(key));
  const comparisons = await Promise.all(comparePromises);
  const comparisonTables = _.map(
    _.map(comparisons, (record) => record as CompareRecord),
    (record) => {
      const institutions = record.institutions;
      const sortedVariableIndices = _.sortBy(_.keys(record.variables));

      const table: InstitutionData[] = _.map(institutions, (institution, i) => {
        const variables = _.reduce(
          sortedVariableIndices,
          (obj, variableIndex) => {
            const variable = record.variables[variableIndex][i];
            if (variable) {
              if (!_.has(obj, variable.heading)) {
                obj[variable.heading] = [];
              }
              obj[variable.heading].push(variable);
            }
            return obj;
          },
          {} as Record<string, Variable[]>
        );
        const sortedVariables = _.sortBy(
          _.values(variables),
          (variablesForAHeading) => variablesForAHeading[0].variable_index
        );
        return {
          institution,
          variables: sortedVariables,
        };
      });
      return {
        data: table,
        name: record.name,
      };
    }
  );
  setPins(comparisonTables);
};

const ComparePins: FC = () => {
  const [pins, setPins] = useState<ComparisonPin[]>([]);

  const [current, setCurrent] = useState<number>(1);
  const onChange = (page: number) => {
    setCurrent(page);
  };

  const [pageSize, setPageSize] = useState<number>(NUMBER_OF_PINS_PER_PAGE);
  const onPageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  const activePins = _.slice(pins, pageSize * (current - 1), pageSize * (current - 1) + pageSize);

  useEffect(() => {
    const handleFetchPins = () => {
      fetchPins(setPins);
    };
    window.addEventListener(LOCAL_STORAGE_EVENT.COMPARE, handleFetchPins as EventListener);

    return () =>
      window.removeEventListener(LOCAL_STORAGE_EVENT.COMPARE, handleFetchPins as EventListener);
  }, []);

  useEffect(() => {
    fetchPins(setPins);
  }, []);

  const handleClearAll = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    await compare.clear();
    window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.COMPARE));
    trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, "All compared variables");
  };

  const handleDownloadAll = async () => {
    const sheetsByIndex = _.reduce(
      pins,
      (obj, pin, i) => {
        const { data, name } = pin;
        obj[`${i}`] = createSheetsFromCompareTable(name, data);
        return obj;
      },
      {} as Record<string, Sheet[]>
    );
    const compositeVariables = _.reduce(
      pins,
      (list, pin, i) => {
        const { data } = pin;
        _.forEach(data, (datum) => {
          const variables = _.flattenDeep(datum.variables);
          _.forEach(variables, (variable) => {
            if (variable.type === VariableType.composite) {
              list.push({ ...variable, indexKey: `${i}`, country: datum.institution.country });
            }
          });
        });
        return list;
      },
      [] as any[]
    );
    const cvPromises = _.map(compositeVariables, (variable) => {
      return _.get(CompositeVariableFunctionDict, variable.hyperlink as string)(variable._id);
    });
    const compositeVariablesData: CompositeVariable[][] = await Promise.all(cvPromises);
    const cvSheets = _.map(compositeVariablesData, (cvData, i) =>
      createSheetsFromCompositeVariable(
        cvData,
        `${compositeVariables[i].country} ${compositeVariables[i].name}`
      )
    );
    _.forEach(cvSheets, (cvSheet, i) => {
      sheetsByIndex[compositeVariables[i].indexKey].push(cvSheet);
    });
    const fileNames = _.map(pins, ({ name }, i) => `Compare Table ${i + 1} (${name})`);
    const sheetData = _.map(pins, (_, i) => sheetsByIndex[`${i}`]);
    const zipFilename = "SIGLA_Compared_Variables_Data";
    await createZipFile(`${zipFilename}.zip`, sheetData, fileNames);
    trackCustomEvent(
      EventCategory.dataStorage,
      EventAction.download,
      "All pinned compared variables"
    );
  };

  let content = <></>;
  if (pins.length > 0) {
    content = (
      <>
        <Row justify="end">
          <Col>
            <Space direction="horizontal">
              <Tooltip
                title={`Download all compared variables`}
                placement="topRight"
                arrowPointAtCenter
                getPopupContainer={(trigger) => trigger}
              >
                <Button
                  type="default"
                  size="middle"
                  icon={<DownloadOutlined style={{ fontSize: 24 }} />}
                  onClick={handleDownloadAll}
                />
              </Tooltip>
              <Tooltip
                title={`Unpin all compared variables`}
                placement="topRight"
                arrowPointAtCenter
                getPopupContainer={(trigger) => trigger}
              >
                <Button
                  type="default"
                  size="middle"
                  icon={<CloseOutlined style={{ fontSize: 24 }} />}
                  onClick={handleClearAll}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <Row gutter={[24, 32]} justify="end" style={{ marginTop: 24 }}>
          {activePins.map((pin, i) => {
            return (
              <Col key={i} span={24}>
                <CompareTable institutions={pin.data} pin={false} title={pin.name} />
              </Col>
            );
          })}
          <Col>
            <Pagination
              current={current}
              showSizeChanger
              pageSize={pageSize}
              pageSizeOptions={["5", "10", "20", "50"]}
              total={pins.length}
              onChange={onChange}
              onShowSizeChange={onPageSizeChange}
            />
          </Col>
        </Row>
      </>
    );
  }
  return content;
};

export default ComparePins;
