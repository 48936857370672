import browseByCountryRoute from "../assets/browse-by-country-route.json";
import browseByInstitutionRoute from "../assets/browse-by-institution-route.json";
import compareRoute from "../assets/compare-route.json";
import customBrowseRoute from "../assets/custom-browse-route.json";
import myPinnedDataPage from "../assets/my-pinned-data-page.json";
import defaultImg from "../assets/default-img.json";
import siglaWebsite from "../assets/sigla-website.json";

export const SIGLA_WEBSITE = siglaWebsite.url;

export const DELIMITER = `/`;

export const SIGLA_ROUTES = {
  byCountry: browseByCountryRoute.path,
  byInstitution: browseByInstitutionRoute.path,
  compare: compareRoute.path,
  customBrowse: customBrowseRoute.path,
  myPinnedData: myPinnedDataPage.path,
};

export const SIGLA_ROUTES_DISPLAY: Record<string, Record<string, string>> = {
  [SIGLA_ROUTES.byCountry]: {
    title: browseByCountryRoute.title,
    img: browseByCountryRoute.img,
    description: browseByCountryRoute.instruction,
  },
  [SIGLA_ROUTES.byInstitution]: {
    title: browseByInstitutionRoute.title,
    img: browseByInstitutionRoute.img,
    description: browseByInstitutionRoute.instruction,
  },
  [SIGLA_ROUTES.compare]: {
    title: compareRoute.title,
    img: compareRoute.img,
    description: compareRoute.instruction,
  },
  [SIGLA_ROUTES.customBrowse]: {
    title: customBrowseRoute.title,
    img: customBrowseRoute.img,
    description: customBrowseRoute.instruction,
  },
  [SIGLA_ROUTES.myPinnedData]: {
    title: myPinnedDataPage.title,
    img: myPinnedDataPage.img,
  },
  default: {
    title: "default",
    img: defaultImg.url,
  },
};
