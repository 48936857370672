/**black with different levels of opacity */
export const black = {
  black_1: "rgba(0, 0, 0, 1)",
  black_2: "rgba(0, 0, 0, 0.85)",
  black_3: "rgba(0, 0, 0, 0.65)",
  black_4: "rgba(0, 0, 0, 0.45)",
};

export const primary = "#041E42";

export const secondary = "#1890ff";
