import React, { FC, useState, useEffect } from "react";

import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { TABLE, DB_NAME } from "./constants";
import PinButton from "./PinButton";
import { CompareRecord } from "./recordTypes";

interface PinCompareTableProps {
  data: {
    institution: Institution;
    variables: Variable[];
  }[];
  /**The name of this comparison table, usually the the institution's name or a common sub_category */
  name: string;
}

const fetchPinStatus = async (
  key: string,
  variables: Variable[],
  setIsPinned: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const compare = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.COMPARE,
  });
  let pinStatus = true;
  const record = await compare.getItem<CompareRecord>(key);
  if (record) {
    for (let i = 0; i < variables.length; i++) {
      if (!_.has(record.variables, `${variables[i].variable_index}`)) {
        pinStatus = false;
        break;
      }
    }
  } else {
    pinStatus = false;
  }
  setIsPinned(pinStatus);
};

const PinCompareTable: FC<PinCompareTableProps> = ({ data, name }) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);

  const sortedInstitutions = _.sortBy(data, ["institution.country", "institution.name"]);

  const institutionWithLargestListOfVariables = _.orderBy(
    data,
    [({ variables }) => variables.length],
    ["desc"]
  )[0];

  const key = _.join(
    _.map(sortedInstitutions, ({ institution }) => {
      return `${institution.country || institution.category}>${institution.name}`;
    }),
    ">"
  );

  useEffect(() => {
    const handleFetchPinStatus = () => {
      fetchPinStatus(key, institutionWithLargestListOfVariables.variables, setIsPinned);
    };
    handleFetchPinStatus();

    window.addEventListener(`${TABLE.COMPARE}/${key}`, handleFetchPinStatus);

    return () => window.removeEventListener(`${TABLE.COMPARE}/${key}`, handleFetchPinStatus);
  }, [key, institutionWithLargestListOfVariables.variables]);

  const onPin = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    const record = await compare.getItem<CompareRecord>(key);
    const newRecord: CompareRecord = record
      ? { ...record }
      : {
          name: name,
          institutions: _.map(sortedInstitutions, ({ institution }) => institution),
          variables: {},
        };

    _.forEach(institutionWithLargestListOfVariables.variables, (variable, i) => {
      const variableList = _.map(sortedInstitutions, ({ variables }) => {
        const variablePerInstitution = variables[i];
        return variablePerInstitution ? variablePerInstitution : null;
      });
      newRecord.variables[`${variable.variable_index}`] = variableList;
    });

    await compare.setItem(key, newRecord);
    setIsPinned(true);
    _.forEach(institutionWithLargestListOfVariables.variables, ({ variable_index }) => {
      window.dispatchEvent(new CustomEvent(`${TABLE.COMPARE}/${key}/${variable_index}`));
    });
  };

  const onUnpin = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    await compare.removeItem(key);
    setIsPinned(false);
    _.forEach(institutionWithLargestListOfVariables.variables, ({ variable_index }) => {
      window.dispatchEvent(new CustomEvent(`${TABLE.COMPARE}/${key}/${variable_index}`));
    });
  };

  return <PinButton isPinned={isPinned} type="table" onPin={onPin} onUnpin={onUnpin} />;
};

export default PinCompareTable;
