import _ from "lodash";

import { RootState } from "../../app/rootReducer";

export const makeGetVariableById = (id: string) => (state: RootState) => state.variables.byId[id];
export const makeGetVariablesByInstitutionId = (id: string) => (state: RootState) => {
  const variables = _.map(
    state.variables.byInstitution[id],
    (variable) => state.variables.byId[variable]
  );
  const sortedVariables = _.sortBy(variables, "variable_index");
  const sortedVariablesByHeading = _.values(_.groupBy(sortedVariables, "heading"));
  const finalVariables = _.sortBy(
    sortedVariablesByHeading,
    (variablesForAHeading) => variablesForAHeading[0].variable_index
  );
  return finalVariables;
};
export const getVariablesIsLoading = (state: RootState) => state.variables.isLoading;
export const getByInstitution = (state: RootState) => state.variables.byInstitution;
export const getById = (state: RootState) => state.variables.byId;
export const getVariablesError = (state: RootState) => state.variables.error;
