import React, { FC } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Tooltip, Button } from "antd";
import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";
import { TABLE, DB_NAME, LOCAL_STORAGE_EVENT } from "./constants";
import { InstitutionRecord, CompareRecord } from "./recordTypes";

interface UnpinButtonProps {
  type: string;
  compare: boolean;
  data: {
    institution: Institution;
    variables: Variable[];
  }[];
}

const UnpinButton: FC<UnpinButtonProps> = ({ type, compare, data }) => {
  const unpinInstitution = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const keys = await institutions.keys();
    const unpins = _.filter(data, ({ institution }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      return _.includes(keys, key);
    });
    const unpinPromises = _.map(unpins, ({ institution }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      return institutions.getItem<InstitutionRecord>(key);
    });
    const unpinRecords = await Promise.all(unpinPromises);
    const updatePinPromises: Promise<InstitutionRecord | void>[] = _.map(
      unpinRecords,
      (record, i) => {
        const newRecord = { ...record } as InstitutionRecord;
        const unpinInstitution = newRecord.institution;
        const key = `${unpinInstitution.country || unpinInstitution.category}>${
          unpinInstitution.name
        }`;
        const unpinVariables = unpins[i].variables;
        _.forEach(unpinVariables, (variable) => {
          delete newRecord.variables[`${variable.variable_index}`];
        });
        if (_.keys(newRecord.variables).length > 0) {
          return institutions.setItem(key, newRecord);
        } else {
          return institutions.removeItem(key);
        }
      }
    );
    await Promise.all(updatePinPromises);
    window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.INSTITUTIONS));
    trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, type);
  };

  const unpinCompare = async () => {
    const compare = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.COMPARE,
    });
    const sortedData = _.sortBy(data, ["institution.country", "institution.name"]);
    const compareKey = _.join(
      _.map(
        sortedData,
        ({ institution }) => `${institution.country || institution.category}>${institution.name}`
      ),
      ">"
    );
    const compareRecord = await compare.getItem<CompareRecord>(compareKey);
    if (compareRecord !== null) {
      const institutionWithLargestListOfVariables = _.orderBy(
        data,
        [({ variables }) => variables.length],
        ["desc"]
      )[0];
      const newCompareRecord: CompareRecord = {
        ...compareRecord,
      };
      _.forEach(institutionWithLargestListOfVariables.variables, (variable) => {
        delete newCompareRecord.variables[`${variable.variable_index}`];
      });
      if (_.keys(newCompareRecord.variables).length > 0) {
        await compare.setItem(compareKey, newCompareRecord);
      } else {
        await compare.removeItem(compareKey);
      }
      window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.COMPARE));
      trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, type);
    }
  };

  const icon = <CloseOutlined style={{ fontSize: 24 }} />;

  const handleClick = compare ? unpinCompare : unpinInstitution;

  return (
    <Tooltip
      title={`Unpin ${type}`}
      placement="topRight"
      arrowPointAtCenter
      getPopupContainer={(trigger) => trigger}
    >
      <Button type="default" size="middle" icon={icon} onClick={handleClick} />
    </Tooltip>
  );
};

export default UnpinButton;
