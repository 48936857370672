import _ from "lodash";

import { SiglaTriple, VariableType } from "../../api/variable";

import { IVariable } from "../customBrowse/CustomBrowseTable";

const createSheetsFromCustomBrowseTable = (variables: IVariable[]) => {
  const [aggregateVariables, regularVariables] = _.partition(
    variables,
    (variable) => variable.type === VariableType.aggregate
  );

  const aggregateVariableHeaders = ["Country", "Institution", "Tags / Category of right"];
  if (aggregateVariables.length > 0) {
    _.forEach(
      (aggregateVariables[0].sigla_answer as Record<string, string>[][])[0],
      (siglaAnswer) => aggregateVariableHeaders.push(siglaAnswer.name)
    );
  }

  const aggregateVariableData: Record<string, string>[] = [];
  _.forEach(aggregateVariables, (variable) => {
    const siglaAnswers = variable.sigla_answer as Record<string, string>[][];
    _.forEach(siglaAnswers, (siglaAsnwer) => {
      aggregateVariableData.push({
        [aggregateVariableHeaders[0]]:
          variable.institution.country || variable.institution.category,
        [aggregateVariableHeaders[1]]: variable.institution.name,
        [aggregateVariableHeaders[2]]: variable.heading,
        [aggregateVariableHeaders[3]]: siglaAsnwer[0].answer,
        [aggregateVariableHeaders[4]]: siglaAsnwer[1].answer,
        [aggregateVariableHeaders[5]]: siglaAsnwer[2].answer,
        [aggregateVariableHeaders[6]]: siglaAsnwer[3].answer,
      });
    });
  });

  const regularVariableHeaders = [
    "Country",
    "Institution",
    "Category",
    "Variable",
    SiglaTriple.answer,
    SiglaTriple.originalText,
    SiglaTriple.source,
  ];
  const regularVariableData = _.map(regularVariables, (variable) => {
    return {
      [regularVariableHeaders[0]]: variable.institution.country || variable.institution.category,
      [regularVariableHeaders[1]]: variable.institution.name,
      [regularVariableHeaders[2]]: variable.heading,
      [regularVariableHeaders[3]]: variable.name,
      [regularVariableHeaders[4]]: variable.sigla_answer as string,
      [regularVariableHeaders[5]]: variable.orig_text as string,
      [regularVariableHeaders[6]]: variable.source as string,
    };
  });

  const sheets = [];

  if (regularVariableData.length > 0) {
    sheets.push({
      title: "Variables",
      headers: regularVariableHeaders,
      data: regularVariableData,
    });
  }

  if (aggregateVariableData.length > 0) {
    sheets.push({
      title: "Rights",
      headers: aggregateVariableHeaders,
      data: aggregateVariableData,
    });
  }

  return sheets;
};

export default createSheetsFromCustomBrowseTable;
