import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { getInstitutionsSuccess as addInstitutions } from "../institutions/institutionsSlice";
import { getCountries, getInstitutions, getVariables } from "../../api";
import { AppThunk } from "../../app/store";
import { Institution, INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { Variable } from "../../api/variable";

const ROUTE = "customBrowse";

interface CountriesMenuState {
  countries: string[];
  selectedCountries: string[];
  isLoading: boolean;
  error: string | null;
}
const initialCountriesMenuState: CountriesMenuState = {
  countries: [],
  selectedCountries: [],
  isLoading: false,
  error: null,
};
const countriesMenu = createSlice({
  name: `${ROUTE}/countriesMenu`,
  initialState: initialCountriesMenuState,
  reducers: {
    getCountriesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getCountriesSuccess(state, { payload }: PayloadAction<string[]>) {
      state.isLoading = false;
      state.error = null;
      state.countries = payload;
    },
    getCountriesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    setSelectedCountries(state, { payload }: PayloadAction<string[]>) {
      state.selectedCountries = payload;
    },
  },
});
export const { getCountriesStart, getCountriesSuccess, getCountriesFailure, setSelectedCountries } =
  countriesMenu.actions;

interface InstitutionsMenuState {
  selectedInstitutions: Record<string, string>[];
  byCategoryByName: Record<string, Record<string, Record<string, Institution>>>;
  isLoading: boolean;
  error: string | null;
}
const initialInstitutionsMenuState: InstitutionsMenuState = {
  selectedInstitutions: [],
  byCategoryByName: {},
  isLoading: false,
  error: null,
};
const institutionsMenu = createSlice({
  name: `${ROUTE}/institutionsMenu`,
  initialState: initialInstitutionsMenuState,
  reducers: {
    getInstitutionsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getInstitutionsSuccess(state, { payload }: PayloadAction<Institution[]>) {
      state.isLoading = false;
      state.error = null;
      const institutions = payload;
      _.forEach(institutions, (institution) => {
        const { _id, category, name, sub_category } = institution;
        if (!_.has(state.byCategoryByName, category)) {
          state.byCategoryByName[category] = {};
        }
        const names = sub_category && sub_category.length > 0 ? sub_category : [name];
        _.forEach(names, (name) => {
          if (!_.has(state.byCategoryByName[category], name)) {
            state.byCategoryByName[category][name] = {};
          }
          state.byCategoryByName[category][name][_id] = institution;
        });
      });
    },
    getInstitutionsFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    setSelectedInstitutions(state, { payload }: PayloadAction<Record<string, string>[]>) {
      state.selectedInstitutions = payload;
    },
    updateSelectedInstitutions(state, { payload }: PayloadAction<string[]>) {
      const countries = payload;
      state.selectedInstitutions = _.filter(state.selectedInstitutions, ({ category, name }) => {
        const institutions = _.values(state.byCategoryByName[category][name]);
        return _.some(institutions, (institution) => {
          return institution.country ? _.includes(countries, institution.country) : true;
        });
      });
    },
  },
});
export const {
  getInstitutionsStart,
  getInstitutionsSuccess,
  getInstitutionsFailure,
  setSelectedInstitutions,
  updateSelectedInstitutions,
} = institutionsMenu.actions;

interface VariablesMenuState {
  selectedVariables: Record<string, string>[];
  byInstitutionByHeadingByName: Record<
    string,
    Record<string, Record<string, Record<string, VariableData>>>
  >;
  fetchedInstitutions: Record<string, string>;
  isLoading: boolean;
  error: string | null;
}
export interface VariableData extends Omit<Variable, "institution"> {
  key: string;
  institution: Institution;
}
const initialVariablesMenuState: VariablesMenuState = {
  selectedVariables: [],
  byInstitutionByHeadingByName: {},
  fetchedInstitutions: {},
  isLoading: false,
  error: null,
};
const variablesMenu = createSlice({
  name: `${ROUTE}/variablesMenu`,
  initialState: initialVariablesMenuState,
  reducers: {
    getVariablesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getVariablesSuccess(state, { payload }: PayloadAction<VariableData[]>) {
      state.isLoading = false;
      state.error = null;
      const variables = payload;
      const byInstitutionByHeadingByName = state.byInstitutionByHeadingByName;
      _.forEach(variables, (variable) => {
        const institution = variable.institution;
        state.fetchedInstitutions[institution._id] = institution._id;
        const names =
          institution.sub_category && institution.sub_category.length > 0
            ? institution.sub_category
            : [institution.country ? institution.name : INTERNATIONAL_INSTITUTIONS];
        _.forEach(names, (name) => {
          if (!_.has(byInstitutionByHeadingByName, name)) {
            byInstitutionByHeadingByName[name] = {};
          }
          if (!_.has(byInstitutionByHeadingByName[name], variable.heading)) {
            byInstitutionByHeadingByName[name][variable.heading] = {};
          }
          if (!_.has(byInstitutionByHeadingByName[name][variable.heading], variable.name)) {
            byInstitutionByHeadingByName[name][variable.heading][variable.name] = {};
          }
          byInstitutionByHeadingByName[name][variable.heading][variable.name][variable._id] =
            variable;
        });
      });
    },
    getVariablesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    setSelectedVariables(state, { payload }: PayloadAction<Record<string, string>[]>) {
      state.selectedVariables = payload;
    },
    updateSelectedVariablesByCountry(state, { payload }: PayloadAction<string[]>) {
      const countries = payload;
      state.selectedVariables = _.filter(state.selectedVariables, (variable) => {
        const { category, institution_name, heading, name } = variable;
        if (category === INTERNATIONAL_INSTITUTIONS) {
          return true;
        }
        const variables = _.values(
          state.byInstitutionByHeadingByName[institution_name][heading][name]
        );
        return _.some(variables, ({ institution }) => {
          return institution.country ? _.includes(countries, institution.country) : true;
        });
      });
    },
    updateSelectedVariablesByInstitution(
      state,
      { payload }: PayloadAction<Record<string, string>[]>
    ) {
      const institutions = payload;
      state.selectedVariables = _.filter(state.selectedVariables, (variable) => {
        const { category, institution_name } = variable;
        const index = _.findIndex(institutions, (institution) => {
          if (category === institution_name) {
            return institution.category === category;
          }
          return institution.category === category && institution.name === institution_name;
        });
        return index > -1;
      });
    },
  },
});
export const {
  getVariablesStart,
  getVariablesSuccess,
  getVariablesFailure,
  setSelectedVariables,
  updateSelectedVariablesByCountry,
  updateSelectedVariablesByInstitution,
} = variablesMenu.actions;

interface UiState {
  activePanel: string | undefined;
  showSeeDataCard: boolean;
  menuIsCollapsed: boolean;
}
const initialUiState: UiState = {
  activePanel: undefined,
  showSeeDataCard: true,
  menuIsCollapsed: true,
};
const ui = createSlice({
  name: `${ROUTE}/ui`,
  initialState: initialUiState,
  reducers: {
    setActivePanel(state, { payload }: PayloadAction<string | undefined>) {
      state.activePanel = payload;
    },
    setShowSeeDataCard(state, { payload }: PayloadAction<boolean>) {
      state.showSeeDataCard = payload;
    },
    toggleMenuIsCollapsed(state) {
      state.menuIsCollapsed = !state.menuIsCollapsed;
    },
  },
});
export const { setActivePanel, setShowSeeDataCard, toggleMenuIsCollapsed } = ui.actions;

const customBrowseReducer = combineReducers({
  countriesMenu: countriesMenu.reducer,
  institutionsMenu: institutionsMenu.reducer,
  variablesMenu: variablesMenu.reducer,
  ui: ui.reducer,
});
export default customBrowseReducer;

export const fetchCountries = (): AppThunk => async (dispatch, state) => {
  const countries = state().customBrowse.countriesMenu.countries;
  if (countries.length === 0) {
    try {
      dispatch(getCountriesStart());
      const allCountries = await getCountries();
      dispatch(getCountriesSuccess(allCountries));
    } catch (err) {
      dispatch(getCountriesFailure(err.toString()));
    }
  }
};

export const fetchInstitutions = (): AppThunk => async (dispatch, state) => {
  const byCategoryByName = state().customBrowse.institutionsMenu.byCategoryByName;
  if (_.keys(byCategoryByName).length === 0) {
    try {
      dispatch(getInstitutionsStart());
      const institutions = await getInstitutions([], []);
      dispatch(addInstitutions(institutions));
      dispatch(getInstitutionsSuccess(institutions));
    } catch (err) {
      dispatch(getInstitutionsFailure(err.toString()));
    }
  }
};

export const fetchVariables =
  (institutions: Record<string, string>[]): AppThunk =>
  async (dispatch, state) => {
    const byCategoryByName = state().customBrowse.institutionsMenu.byCategoryByName;
    const fetchedInstitutions = state().customBrowse.variablesMenu.fetchedInstitutions;
    const byId = state().institutions.byId;
    const unfetchedInstitutions = _.reduce(
      institutions,
      (list, institution) => {
        const ids = _.keys(byCategoryByName[institution.category][institution.name]);
        const unfetchedIds = _.filter(ids, (id) => !_.has(fetchedInstitutions, id));
        list.push(...unfetchedIds);
        return list;
      },
      [] as string[]
    );
    if (unfetchedInstitutions.length > 0) {
      try {
        dispatch(getVariablesStart());
        const variables = await getVariables(unfetchedInstitutions);
        const variableData = _.map(variables, (variable) => {
          return {
            ...variable,
            key: variable._id,
            institution: byId[variable.institution],
          };
        });
        dispatch(getVariablesSuccess(variableData));
      } catch (err) {
        dispatch(getVariablesFailure(err.toString()));
      }
    }
  };
