import _ from "lodash";

import { RootState } from "../../app/rootReducer";

export const makeGetCategoriesPerCountry = (country: string) => (state: RootState) => {
  const categoriesDict = state.institutions.byCountryByCategory[country] || {};
  return _.sortBy(_.keys(categoriesDict));
};
export const makeGetCountriesPerCategory = (category: string) => (state: RootState) => {
  const countriesDict = state.institutions.byCategoryByCountry[category] || {};
  return _.sortBy(_.keys(countriesDict));
};
export const makeGetInstitutionById = (id: string) => (state: RootState) =>
  state.institutions.byId[id];
export const getById = (state: RootState) => state.institutions.byId;
export const getByCountryByCatgory = (state: RootState) => state.institutions.byCountryByCategory;
export const getByCategoryByCountry = (state: RootState) => state.institutions.byCategoryByCountry;
export const getByCategoryByName = (state: RootState) => state.institutions.byCategoryByName;
export const getInstitutionsIsLoading = (state: RootState) => state.institutions.isLoading;
