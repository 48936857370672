import { combineReducers } from "@reduxjs/toolkit";

import amendmentsReducer from "../features/amendments/amendmentsSlice";
import bodyOfLawReducer from "../features/bodyOfLaw/bodyOfLawSlice";
import byCategoryReducer from "../features/institutionByCategory/byCategorySlice";
import byCountryReducer from "../features/institutionByCountry/byCountrySlice";
import categoriesReducer from "../features/categories/categoriesSlice";
import compareInstitutionsReducer from "../features/compareInstitution/compareInstitutionsSlice";
import countriesReducer from "../features/countries/countriesSlice";
import customBrowseReducer from "../features/customBrowse/customBrowseSlice";
import institutionsReducer from "../features/institutions/institutionsSlice";
import rightsReducer from "../features/rights/rightsSlice";
import variablesReducer from "../features/variables/variablesSlice";

const rootReducer = combineReducers({
  amendments: amendmentsReducer,
  bodyOfLaw: bodyOfLawReducer,
  byCategory: byCategoryReducer,
  byCountry: byCountryReducer,
  categories: categoriesReducer,
  compareInstitutions: compareInstitutionsReducer,
  countries: countriesReducer,
  customBrowse: customBrowseReducer,
  institutions: institutionsReducer,
  rights: rightsReducer,
  variables: variablesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
