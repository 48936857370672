import { getAmendments } from "./amendment";
import { getBodyOfLaw } from "./bodyOfLaw";
import { getCategories } from "./category";
import { getCountries } from "./country";
import { getInstitutionById, getInstitutions, getInstitutionsByCustomBrowse } from "./institution";
import { getRights } from "./right";
import { getVariableById, getVariables } from "./variable";

export interface SiglaAnswer {
  name: string;
  answer: string;
}

export interface CompositeVariable {
  _id: string;
  variable: string;
  heading: string;
  index: number;
  sigla_answers: SiglaAnswer[];
}

export const CompositeVariableFunctionDict = {
  amendments: getAmendments,
  rights: getRights,
  body_of_law: getBodyOfLaw,
};

export {
  getAmendments,
  getCategories,
  getCountries,
  getInstitutionById,
  getInstitutions,
  getInstitutionsByCustomBrowse,
  getBodyOfLaw,
  getRights,
  getVariableById,
  getVariables,
};
