import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { Institution, INTERNATIONAL_INSTITUTIONS } from "../../api/institution";

import { RootState } from "../../app/rootReducer";
import { getCategories } from "../categories/categoriesSelectors";
import { getCountries } from "../countries/countriesSelectors";

import {
  makeGetCountriesPerCategory,
  getById as getInstitutionById,
  getByCategoryByName,
} from "../institutions/institutionsSelectors";
import { getByInstitution, getById as getVariableById } from "../variables/variablesSelectors";

export const getSelectedCategories = (state: RootState) =>
  state.compareInstitutions.categoriesMenu.selectedCategories;
export const getHasSelectedInternationalInstitutions = createSelector(
  [getSelectedCategories],
  (selectedCategories) =>
    selectedCategories.length > 0 && selectedCategories[0] === INTERNATIONAL_INSTITUTIONS
);
export const getCategoryNodes = createSelector([getCategories], (categories) => {
  return _.map(categories, (category) => {
    return {
      key: category,
      title: category,
      value: category,
    };
  });
});

export const getSelectedCountries = (state: RootState) =>
  state.compareInstitutions.countriesMenu.selectedCountries;
export const makeGetCountryNodes = (category: string) =>
  createSelector(
    [getCountries, getSelectedCategories, makeGetCountriesPerCategory(category)],
    (countries, selectedCategories, countriesPerCategory) => {
      const countriesToDisplay = selectedCategories.length > 0 ? countriesPerCategory : countries;
      return _.map(countriesToDisplay, (country) => {
        return {
          key: country,
          title: country,
          value: country,
        };
      });
    }
  );
export const getCountriesIsDisabled = createSelector(
  [getSelectedCategories],
  (selectedCategories) =>
    selectedCategories.length === 0 || selectedCategories[0] === INTERNATIONAL_INSTITUTIONS
);
export const getCountriesPlaceholder = createSelector(
  [getSelectedCategories],
  (selectedCategories) => {
    const placeholder = "Choose 2+ countries to compare";
    return selectedCategories.length && selectedCategories[0] === INTERNATIONAL_INSTITUTIONS
      ? `${placeholder} (SKIP)`
      : `2. ${placeholder}`;
  }
);

export const getSelectedInstitutions = (state: RootState) =>
  state.compareInstitutions.institutionsMenu.selectedInstitutions;
export const getSelectedInstitutionStrings = createSelector(
  [getSelectedInstitutions],
  (selectedInstitutions) =>
    _.map(selectedInstitutions, ({ category, name }) => `${category}>${name}`)
);
export const getSelectedInstitutionIds = createSelector(
  [getSelectedInstitutions, getByCategoryByName],
  (selectedInstitutions, byCategoryByName) => {
    return _.reduce(
      selectedInstitutions,
      (list, selectedInstitution) => {
        const { category, name } = selectedInstitution;
        list.push(..._.keys(byCategoryByName[category][name]));
        return list;
      },
      [] as string[]
    );
  }
);
export const getInstitutionsIsDisabled = createSelector(
  [getSelectedCategories, getSelectedCountries],
  (selectedCategories, selectedCountries) =>
    selectedCountries.length < 2 && selectedCategories[0] !== INTERNATIONAL_INSTITUTIONS
);
export const getInstitutionsPlaceholder = createSelector(
  [getHasSelectedInternationalInstitutions],
  (hasSelectedInternationalInstitutions) => {
    return hasSelectedInternationalInstitutions
      ? "2. Choose 2+ institutions to compare"
      : "3. Choose institution";
  }
);
export const getInstitutionByName = createSelector(
  [getSelectedCountries, getSelectedCategories, getInstitutionById, getByCategoryByName],
  (selectedCountries, selectedCategories, byId, byCategoryByName) => {
    const category = selectedCategories[0];
    const byName = byCategoryByName[category] || {};
    const institutionByName = _.reduce(
      _.keys(byName),
      (obj, name) => {
        const institutionById = _.reduce(
          _.keys(byName[name]),
          (obj, institutionId) => {
            const institution = byId[institutionId];
            if (
              selectedCountries.length === 0 ||
              _.includes(selectedCountries, institution.country)
            ) {
              obj[institutionId] = institution;
            }
            return obj;
          },
          {} as Record<string, Institution>
        );
        if (_.keys(institutionById).length > 0) {
          obj[name] = institutionById;
        }
        return obj;
      },
      {} as Record<string, Record<string, Institution>>
    );
    return institutionByName;
  }
);

export const getVariablesIsDisabled = (state: RootState) =>
  state.compareInstitutions.variablesMenu.isDisabled;
export const getSelectedInstitutionData = createSelector(
  [getSelectedInstitutions, getInstitutionByName, getByInstitution, getVariableById],
  (selectedInstitutions, institutionByName, variableByInstitution, variableById) => {
    const institutions = _.reduce(
      selectedInstitutions,
      (list, selectedInstitution) => {
        list.push(..._.values(institutionByName[selectedInstitution.name]));
        return list;
      },
      [] as Institution[]
    );
    const data = _.map(institutions, (institution) => {
      const variables = _.sortBy(
        _.values(
          _.mapValues(
            variableByInstitution[institution._id],
            (variableId) => variableById[variableId]
          )
        ),
        "variable_index"
      );
      const variablesByHeading = _.values(_.groupBy(variables, "heading"));
      const sortedVariablesByHeading = _.sortBy(
        variablesByHeading,
        (variablesForAHeading) => variablesForAHeading[0].variable_index //sort by variable index
      );
      return {
        institution,
        variables: sortedVariablesByHeading,
      };
    });
    return _.sortBy(data, ["institution.country", "institution.name"]);
  }
);

export const getSeeDataButtonIsDisabled = createSelector(
  [getSelectedCategories, getSelectedCountries, getSelectedInstitutions],
  (selectedCategories, selectedCountries, selectedInstitutions) => {
    const category = selectedCategories[0];
    return category === INTERNATIONAL_INSTITUTIONS
      ? selectedInstitutions.length < 2
      : selectedInstitutions.length === 0 || selectedCountries.length < 2;
  }
);
