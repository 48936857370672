import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { DataNode } from "rc-tree-select/lib/interface";

import { RootState } from "../../app/rootReducer";
import { INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { getCategories } from "../categories/categoriesSelectors";
import { getCountries } from "../countries/countriesSelectors";

import {
  makeGetCountriesPerCategory,
  getById as getInstitutionById,
  getByCategoryByCountry,
} from "../institutions/institutionsSelectors";
import { getByInstitution, getById as getVariableById } from "../variables/variablesSelectors";

import {
  byCategorySortByConfig,
  byCategorySortOrderConfig,
  executiveSortByConfig,
  executiveSortOrderConfig,
} from "../../utils/createSortConfig";

export const getCategoriesIsDisabled = (state: RootState) =>
  state.byCategory.categoriesMenu.isDisabled;
export const getSelectedCategories = (state: RootState) =>
  state.byCategory.categoriesMenu.selectedCategories;
export const getCategoryNodes = createSelector([getCategories], (categories) => {
  return _.map(categories, (category) => {
    return {
      key: category,
      title: category,
      value: category,
    };
  });
});
export const getHasSelectedInternationalInstitutions = createSelector(
  [getSelectedCategories],
  (selectedCategories) =>
    selectedCategories.length > 0 && selectedCategories[0] === INTERNATIONAL_INSTITUTIONS
);

export const getCountriesIsDisabled = (state: RootState) =>
  state.byCategory.countriesMenu.isDisabled;
export const getSelectedCountries = (state: RootState) =>
  state.byCategory.countriesMenu.selectedCountries;
export const getCountriesPlaceholder = createSelector(
  [getHasSelectedInternationalInstitutions],
  (hasSelectedInternationalInstitutions) => {
    const placeholder = "Choose countries";
    return hasSelectedInternationalInstitutions ? `${placeholder} (SKIP)` : `2. ${placeholder}`;
  }
);
export const makeGetCountryNodes = (category: string) =>
  createSelector(
    [getCountries, getSelectedCategories, makeGetCountriesPerCategory(category)],
    (countries, selectedCategories, countriesPerCategory) => {
      const countriesToDisplay = selectedCategories.length > 0 ? countriesPerCategory : countries;
      return _.map(countriesToDisplay, (country) => {
        return {
          key: country,
          title: country,
          value: country,
        };
      });
    }
  );

export const getInstitutionsIsDisabled = (state: RootState) =>
  state.byCategory.institutionsMenu.isDisabled;
export const getSelectedInstitutions = (state: RootState) =>
  state.byCategory.institutionsMenu.selectedInstitutions;
export const getSelectedInstitutionStrings = createSelector(
  [getSelectedInstitutions],
  (selectedInstitutions) =>
    _.map(
      selectedInstitutions,
      ({ category, country, name, _id }) => `${category}>${country}>${name}>${_id}`
    )
);
export const getInstitutionsPlaceholder = createSelector(
  [getHasSelectedInternationalInstitutions],
  (hasSelectedInternationalInstitutions) => {
    return `${hasSelectedInternationalInstitutions ? 2 : 3}. Choose institutions`;
  }
);
export const getInstitutionNodes = createSelector(
  [getSelectedCountries, getSelectedCategories, getInstitutionById, getByCategoryByCountry],
  (selectedCountries, selectedCategories, byId, byCategoryByCountry) => {
    const category = selectedCategories[0];
    const byCountry = byCategoryByCountry[category] || {};
    const countriesToConsider =
      category === INTERNATIONAL_INSTITUTIONS ? [category] : selectedCountries;
    const sortedSelectedCountries = _.sortBy(countriesToConsider);
    const nodes = _.reduce(
      sortedSelectedCountries,
      (list, country) => {
        const byInstitutionId = byCountry[country] || {};
        const institutions = _.values(_.mapValues(byInstitutionId, (id) => byId[id]));
        let sortedInstitutions = _.sortBy(institutions, "name");
        /*hacky solution to sort executive*/
        if (category === "Executive") {
          sortedInstitutions = _.orderBy(
            institutions,
            executiveSortByConfig,
            executiveSortOrderConfig
          );
        }

        if (sortedInstitutions.length > 0) {
          list.push({
            key: country,
            title: country,
            value: country,
            children: _.map(sortedInstitutions, ({ _id, category, name, country }) => {
              return {
                key: `${category}>${country}>${name}>${_id}`,
                title: name,
                value: `${category}>${country}>${name}>${_id}`,
              };
            }),
          });
        }
        return list;
      },
      [] as DataNode[]
    );
    return nodes;
  }
);

export const getVariablesIsDisabled = (state: RootState) =>
  state.byCategory.variablesMenu.isDisabled;
export const getSelectedInstitutionData = createSelector(
  [getSelectedInstitutions, getInstitutionById, getByInstitution, getVariableById],
  (selectedInstitutions, institutionById, variableByInstitution, variableById) => {
    const sortedSelectedInstitutions = _.orderBy(
      selectedInstitutions,
      byCategorySortByConfig,
      byCategorySortOrderConfig
    ) as Record<string, any>[];
    const data = _.map(sortedSelectedInstitutions, (institution) => {
      const fullInstitution = institutionById[institution._id];
      const variables = _.sortBy(
        _.values(
          _.mapValues(
            variableByInstitution[institution._id],
            (variableId) => variableById[variableId]
          )
        ),
        "variable_index"
      );
      const variablesByHeading = _.values(_.groupBy(variables, "heading"));
      const sortedVariablesByHeading = _.sortBy(
        variablesByHeading,
        (variablesForAHeading) => variablesForAHeading[0].variable_index
      );
      return {
        institution: fullInstitution,
        variables: sortedVariablesByHeading,
      };
    });
    return data;
  }
);
