import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CountriesMenuState {
  selectedCountries: string[];
  isDisabled: boolean;
}

const initialCountriesMenuState: CountriesMenuState = {
  selectedCountries: [],
  isDisabled: true,
};

export const countriesMenuFactory = (route: string) =>
  createSlice({
    name: `${route}countriesMenu`,
    initialState: { ...initialCountriesMenuState } as CountriesMenuState,
    reducers: {
      setCountriesIsDisabled(state, { payload }: PayloadAction<boolean>) {
        state.isDisabled = payload;
      },
      setSelectedCountries(state, { payload }: PayloadAction<string[]>) {
        state.selectedCountries = payload;
      },
    },
  });
