import _ from "lodash";

import { Institution } from "../../api/institution";
import { SiglaTriple, Variable, VariableType } from "../../api/variable";

const SIT_COLUMNS = [
  "Category",
  "Variable",
  SiglaTriple.answer,
  SiglaTriple.originalText,
  SiglaTriple.source,
];

const createSheetsFromSIT = (institution: Institution, variables: Variable[]) => {
  const institutionHeaders = ["Country", "Institutional Category", "Institution Name"];
  const institutionData = [
    {
      [`${institutionHeaders[0]}`]: institution.country || "",
      [`${institutionHeaders[1]}`]: institution.category,
      [`${institutionHeaders[2]}`]: institution.name,
    },
  ];

  const variableHeaders =
    variables[0].type === VariableType.aggregate
      ? createRightHeadersHelper(variables[0])
      : SIT_COLUMNS;

  const variableData =
    variables[0].type === VariableType.aggregate
      ? createRightData(variableHeaders, variables)
      : createVariableData(variables);

  return [
    {
      title: "Institution",
      headers: institutionHeaders,
      data: institutionData,
    },
    {
      title: variables[0].type === VariableType.aggregate ? "Rights" : "Variables",
      headers: variableHeaders,
      data: variableData,
    },
  ];
};

const createRightHeadersHelper = (variable: Variable) => {
  const siglaAnswers = variable.sigla_answer as Record<string, string>[][];
  const aggregateVariableHeaders = ["Tags / Category of right"];
  _.forEach(siglaAnswers[0], (siglaAnswer) => aggregateVariableHeaders.push(siglaAnswer.name));

  return aggregateVariableHeaders;
};

const createVariableData = (variables: Variable[]) => {
  return _.map(variables, (variable) => {
    return {
      [SIT_COLUMNS[0]]: variable.heading,
      [SIT_COLUMNS[1]]: variable.name,
      [SIT_COLUMNS[2]]: variable.sigla_answer as string,
      [SIT_COLUMNS[3]]: variable.orig_text as string,
      [SIT_COLUMNS[4]]: variable.source as string,
    };
  });
};

const createRightData = (headers: string[], variables: Variable[]) => {
  const rightData: Record<string, string>[] = [];
  _.forEach(variables, (variable) => {
    const siglaAnswers = variable.sigla_answer as Record<string, string>[][];
    _.forEach(siglaAnswers, (siglaAsnwer) => {
      rightData.push({
        [headers[0]]: variable.heading,
        [headers[1]]: siglaAsnwer[0].answer,
        [headers[2]]: siglaAsnwer[1].answer,
        [headers[3]]: siglaAsnwer[2].answer,
        [headers[4]]: siglaAsnwer[3].answer,
      });
    });
  });
  return rightData;
};

export default createSheetsFromSIT;
