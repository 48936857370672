import React, { FC } from "react";

interface FilterOptionProps {
  text: string;
  maxWidth: string;
}

const FilterOption: FC<FilterOptionProps> = ({ text, maxWidth }) => {
  return (
    <span
      style={{
        display: "inline-block",
        whiteSpace: "pre-wrap",
        maxWidth: maxWidth,
        paddingRight: "16px",
      }}
    >
      {text}
    </span>
  );
};

export default FilterOption;
