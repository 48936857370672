import React from "react";

import { Route, Switch } from "react-router-dom";

import AmendmentsPage from "../features/amendments/AmendmentsPage";
import BodyOfLawPage from "../features/bodyOfLaw/BodyOfLawPage";
import CompareInstitutionPage from "../features/compareInstitution/CompareInstitutionPage";
import CustomBrowsePage from "../features/customBrowse/CustomBrowsePage";
import InstitutionByCategory from "../features/institutionByCategory/InstitutionByCategoryPage";
import InstitutionByCountry from "../features/institutionByCountry/InstitutionByCountryPage";
import NoMatchPage from "../components/NoMatchPage";
import SpecificInstitutionPage from "../features/institutions/SpecificInstitutionPage";
import MyPinnedDataPage from "../features/myPinnedData/MyPinnedDataPage";
import RightsPage from "../features/rights/RightsPage";

import { SIGLA_ROUTES } from "../constants";

const AppContent = () => {
  return (
    <>
      <Switch>
        <Route exact path="/amendments/:id" component={AmendmentsPage} />
        <Route exact path="/body_of_law/:id" component={BodyOfLawPage} />
        <Route exact path={`/${SIGLA_ROUTES.byCountry}`} component={InstitutionByCountry} />
        <Route exact path={`/${SIGLA_ROUTES.byInstitution}`} component={InstitutionByCategory} />
        <Route excat path={`/${SIGLA_ROUTES.compare}`} component={CompareInstitutionPage} />
        <Route exact path={`/${SIGLA_ROUTES.customBrowse}`} component={CustomBrowsePage} />
        <Route exact path="/institutions/:id" component={SpecificInstitutionPage} />
        <Route exact path={`/${SIGLA_ROUTES.myPinnedData}`} component={MyPinnedDataPage} />
        <Route exact path="/rights/:id" component={RightsPage} />
        <Route path="*" component={NoMatchPage} />
      </Switch>
    </>
  );
};

export default AppContent;
