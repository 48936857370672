import React, { FC, useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Institution } from "../../api/institution";
import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { fetchRights } from "../../features/rights/rightsSlice";
import { fetchVariableById } from "../../features/variables/variablesSlice";

import { getRightsIsLoading, makeGetCompositeVariables } from "./rightsSelectors";
import { getVariablesIsLoading, makeGetVariableById } from "../variables/variablesSelectors";

import CompositeVariableTable from "../../components/CompositeVariableTable";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";
import NotFound from "../../components/NotFound";

interface RouteParam {
  id: string;
}

const RightsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    dispatch(fetchVariableById(id));
    dispatch(fetchRights(id));
  }, [id, dispatch]);

  const rightsIsLoading = useSelector(getRightsIsLoading);
  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variable = useSelector(makeGetVariableById(id));
  const compositeVariables = useSelector(makeGetCompositeVariables(id));

  useDocumentTitle(variable ? variable.heading : "Loading...");

  let content;

  if (variable && compositeVariables.length && compositeVariables[0].length) {
    const institution = variable.institution as unknown as Institution;
    content = (
      <>
        <PageHeader title={variable.heading} />
        <CompositeVariableTable
          institution={institution}
          columnsType={["filter", "search", "search", "basic"]}
          breadcrumbs={[institution.country as string, institution.name, variable.heading]}
          variables={compositeVariables}
          furled={false}
        />
      </>
    );
  } else if (rightsIsLoading || variablesIsLoading) {
    content = <Spinner />;
  } else {
    content = <NotFound message="Rights don't exist." />;
  }

  return <Container>{content}</Container>;
};

export default withErrorBoundary(RightsPage);
