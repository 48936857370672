import axios from "axios";

import { API_BASE_URL } from "./constants";

const API_URL = `${API_BASE_URL}countries`;

export const getCountries = async (category?: string) => {
  let url = `${API_URL}`;
  if (category) {
    url = url.concat("?", `category=${category}`);
  }
  const { data } = await axios.get<string[]>(url);
  return data;
};
