export enum TABLE {
  COMPARE = "compare",
  INSTITUTIONS = "institutions",
}

export const DB_NAME = "my-pinned-data";

export enum LOCAL_STORAGE_EVENT {
  COMPARE = "compare-change",
  INSTITUTIONS = "institutions-change",
}
