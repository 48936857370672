import React from "react";

import { DownloadOutlined, PushpinOutlined } from "@ant-design/icons";
import { BsFlagFill, BsBoxArrowUpRight } from "react-icons/bs";

import VisuallyHidden from "./VisuallyHidden";

import { SIGLA_WEBSITE } from "../constants";

import { primary } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

const SiglaDataDescription = () => {
  return (
    <>
      <ul>
        <li>
          <span>{"Use the "}</span>
          <VisuallyHidden icon={<BsBoxArrowUpRight />} text="open in a new tab" />
          <span>{" button to open data in a new tab."}</span>
        </li>
        <li>
          <span>{"Use the "}</span>
          <VisuallyHidden icon={<DownloadOutlined />} text="download" />
          <span>{" button to download data."}</span>
        </li>
        <li>
          <span>{"Use the "}</span>
          <VisuallyHidden icon={<PushpinOutlined />} text="pin" />
          <span>
            {" button to pin/save data. Visit My Pinned Data page to view your pinned/saved data."}
          </span>
        </li>
        <li>
          <span>{"Use the "}</span>
          <VisuallyHidden
            icon={<BsFlagFill style={{ color: primary, transform: "rotate(-15deg)" }} />}
            text="report an issue"
          />
          <span>{" link to report an issue with our data."}</span>
        </li>
      </ul>
      <div style={{ marginBottom: "1em" }}>
        See{" "}
        {
          <a target="_blank" rel="noopener noreferrer" href={`${SIGLA_WEBSITE}user-guide/`}>
            User Guide
          </a>
        }{" "}
        page to learn more.
      </div>
      <div style={{ fontSize: fontSizes.font_size_3 }}>
        <em>Disclaimer: Your pinned data are only saved on your current browser and device.</em>
      </div>
    </>
  );
};

export default SiglaDataDescription;
