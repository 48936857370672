import React, { FC, useEffect, useState } from "react";

import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, Tooltip } from "antd";
import localforage from "localforage";
import _ from "lodash";

import { CompositeVariable, CompositeVariableFunctionDict } from "../../api";
import { Institution, INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { Variable, VariableType } from "../../api/variable";

import createSheetsFromSIT from "../downloadData/createSheetsFromSIT";
import createSheetsFromCompositeVariable from "../downloadData/createSheetsFromCompositeVariable";
import createZipFile from "../downloadData/createZipFile";
import { Sheet } from "../downloadData/DownloadData";
import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";
import SITGroup from "../institutions/SITGroup";
import { DB_NAME, TABLE, LOCAL_STORAGE_EVENT } from "./constants";
import { InstitutionRecord } from "./recordTypes";

interface SITData {
  institution: Institution;
  variables: Variable[][];
}

const fetchPins = async (setPins: React.Dispatch<React.SetStateAction<SITData[]>>) => {
  const institutions = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.INSTITUTIONS,
  });
  const keys = await institutions.keys();
  const institutionPromises = _.map(keys, (key) => institutions.getItem<InstitutionRecord>(key));
  const pinnedInstitutions = await Promise.all(institutionPromises);
  const SITs: SITData[] = _.map(
    _.map(pinnedInstitutions, (record) => record as InstitutionRecord),
    (record) => {
      const institution = record.institution;
      const variables = _.sortBy(_.values(record.variables), "variable_index");
      const sortedVariables = _.sortBy(
        _.values(_.groupBy(variables, "heading")),
        (variablesForAHeading) => variablesForAHeading[0].variable_index
      );
      return {
        institution,
        variables: sortedVariables,
      };
    }
  );
  const sortedSITs = _.sortBy(SITs, [
    "institution.country",
    "institution.category",
    "institution.name",
  ]);
  setPins(sortedSITs);
};

const InstitutionPins: FC = () => {
  const [pins, setPins] = useState<SITData[]>([]);

  useEffect(() => {
    const handleFetchPins = () => {
      fetchPins(setPins);
    };
    window.addEventListener(LOCAL_STORAGE_EVENT.INSTITUTIONS, handleFetchPins as EventListener);

    return () =>
      window.removeEventListener(
        LOCAL_STORAGE_EVENT.INSTITUTIONS,
        handleFetchPins as EventListener
      );
  }, []);

  useEffect(() => {
    fetchPins(setPins);
  }, []);

  const handleClearAll = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    await institutions.clear();
    window.dispatchEvent(new CustomEvent(LOCAL_STORAGE_EVENT.INSTITUTIONS));
    trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, "All individual variables");
  };

  const handleDownloadAll = async () => {
    const sheetsByInstitution = _.reduce(
      pins,
      (obj, sit) => {
        const variables = _.flattenDeep(sit.variables);
        obj[sit.institution._id] = createSheetsFromSIT(sit.institution, variables);
        return obj;
      },
      {} as Record<string, Sheet[]>
    );
    const compositeVariables = _.reduce(
      pins,
      (list, sit) => {
        const variables = _.flattenDeep(sit.variables);
        _.forEach(variables, (variable) => {
          if (variable.type === VariableType.composite) {
            list.push(variable);
          }
        });
        return list;
      },
      [] as Variable[]
    );
    const cvPromises = _.map(compositeVariables, (variable) => {
      return _.get(CompositeVariableFunctionDict, variable.hyperlink as string)(variable._id);
    });
    const compositeVariablesData: CompositeVariable[][] = await Promise.all(cvPromises);
    const cvSheets = _.map(compositeVariablesData, (cvData, i) =>
      createSheetsFromCompositeVariable(cvData, compositeVariables[i].name)
    );
    _.forEach(cvSheets, (cvSheet, i) => {
      sheetsByInstitution[compositeVariables[i].institution].push(cvSheet);
    });
    const fileNames = _.map(pins, ({ institution }) =>
      institution.category === INTERNATIONAL_INSTITUTIONS
        ? institution.name
        : `${institution.name} (${institution.country})`
    );
    const sheetData = _.map(pins, ({ institution }) => sheetsByInstitution[institution._id]);
    const zipFileName = "SIGLA_Individual_Variables_View_by_Institution_Data";
    await createZipFile(`${zipFileName}.zip`, sheetData, fileNames);
    trackCustomEvent(
      EventCategory.dataStorage,
      EventAction.download,
      "All pinned individual variables"
    );
  };

  let content = <></>;
  if (pins.length > 0) {
    content = (
      <>
        <Row justify="end">
          <Col>
            <Space direction="horizontal">
              <Tooltip
                title={`Download all individual variables`}
                placement="topRight"
                arrowPointAtCenter
                getPopupContainer={(trigger) => trigger}
              >
                <Button
                  type="default"
                  size="middle"
                  icon={<DownloadOutlined style={{ fontSize: 24 }} />}
                  onClick={handleDownloadAll}
                />
              </Tooltip>
              <Tooltip
                title={`Unpin all individual variables`}
                placement="topRight"
                arrowPointAtCenter
                getPopupContainer={(trigger) => trigger}
              >
                <Button
                  type="default"
                  size="middle"
                  icon={<CloseOutlined style={{ fontSize: 24 }} />}
                  onClick={handleClearAll}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <SITGroup
          furled={true}
          breadcrumbs={["country", "category", "name"]}
          sits={pins}
          pin={false}
        />
      </>
    );
  }
  return content;
};

export default InstitutionPins;
