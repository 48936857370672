import axios from "axios";
import _ from "lodash";

import { API_BASE_URL } from "./constants";
import { createQueryString } from "./utils";

const API_URL = `${API_BASE_URL}variables`;

export enum SiglaTriple {
  answer = "SIGLA's Summary",
  originalText = "Original Text",
  source = "Source",
}

export enum VariableType {
  standard = "standard",
  aggregate = "aggregate",
  composite = "composite",
}

export const NAP = "Not applicable.";

export interface Variable {
  _id: string;
  institution: string;
  heading: string;
  name: string;
  variable_index: number;
  sigla_answer: string | Record<string, string>[][];
  orig_text?: string;
  source?: string;
  type: string;
  hyperlink?: string;
}

export const getVariableById = async (id: string) => {
  const url = `${API_URL}/${id}`;
  const { data } = await axios.get<Variable>(url);
  return data;
};

export const getVariables = async (institutions: string[]) => {
  const institutionParameters = _.map(institutions, (institution) => {
    return {
      field: "ids",
      value: institution,
    };
  });
  const query = createQueryString(institutionParameters);

  let url = `${API_URL}`;
  if (query) {
    url = url.concat("?", query);
  }
  const { data } = await axios.get<Variable[]>(url);
  return data;
};
