import React, { CSSProperties, FC } from "react";

import { Button } from "antd";

interface GoButtonProps {
  disabled: boolean;
  loading: boolean;
  onClickCb(): void;
  style?: CSSProperties;
}
const GoButton: FC<GoButtonProps> = ({ disabled, loading, onClickCb, style }) => {
  const handleClick = () => {
    onClickCb();
  };
  return (
    <Button
      disabled={disabled}
      loading={loading}
      type="primary"
      onClick={handleClick}
      style={style}
    >
      {loading ? "Loading" : "See Data"}
    </Button>
  );
};

export default GoButton;
