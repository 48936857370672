import React, { CSSProperties } from "react";

import { Spin } from "antd";

const spinnerStyle: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  minHeight: "85vh",
  alignItems: "center",
  justifyContent: "center",
};

const Spinner = () => {
  return (
    <div style={spinnerStyle}>
      <Spin size="large" tip="Loading..." />
    </div>
  );
};

export default Spinner;
