import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { VariableType } from "../../api/variable";
import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { fetchInstitutionById } from "../../features/institutions/institutionsSlice";
import { fetchVariables } from "../../features/variables/variablesSlice";

import { getInstitutionsIsLoading, makeGetInstitutionById } from "./institutionsSelectors";
import {
  getVariablesIsLoading,
  makeGetVariablesByInstitutionId,
} from "../variables/variablesSelectors";

import Container from "../../components/Container";
import NotFound from "../../components/NotFound";
import Spinner from "../../components/Spinner";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";
import SpecificInstitutionTable from "./SpecificInstitutionTable";
import RightsTable from "../rights/RightsTable";

interface RouteParam {
  id: string;
}

const SpecificInstitutionPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    dispatch(fetchInstitutionById(id));
    dispatch(fetchVariables([id]));
  }, [id, dispatch]);

  const institutionsIsLoading = useSelector(getInstitutionsIsLoading);
  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const institution = useSelector(makeGetInstitutionById(id));
  const variables = useSelector(makeGetVariablesByInstitutionId(id));

  useDocumentTitle(institution ? institution.name : "Institution");

  let content;
  if (institution && variables && variables.length) {
    let breadCrumbs = ["country", "category", "name"];
    content = (
      <>
        <PageHeader title={institution.name} />
        {variables[0][0].type !== VariableType.aggregate ? (
          <SpecificInstitutionTable
            furled={false}
            breadcrumbs={breadCrumbs}
            institution={institution}
            variables={variables}
            pin={true}
          />
        ) : (
          <RightsTable
            furled={false}
            breadcrumbs={breadCrumbs}
            institution={institution}
            variables={variables}
            pin={true}
            columnsType={["filter", "search", "search", "basic"]}
          />
        )}
      </>
    );
  } else if (institutionsIsLoading || variablesIsLoading) {
    content = <Spinner />;
  } else {
    content = <NotFound message="Institution doesn't exist." />;
  }

  return <Container>{content}</Container>;
};

export default withErrorBoundary(SpecificInstitutionPage);
