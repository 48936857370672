import React, { CSSProperties, FC } from "react";

import { SIGLA_WEBSITE } from "../constants";
import { fontSizes } from "../styles/fonts";

interface NotFoundProps {
  message: string;
}

const NotFound: FC<NotFoundProps> = ({ message }) => {
  const layoutStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    padding: 16,
  };

  const messageStyle: CSSProperties = {
    textAlign: "center",
    fontSize: fontSizes.font_size_6,
  };
  return (
    <div style={layoutStyle}>
      <h1>404 Not Found</h1>
      <p style={messageStyle}>{`Sorry, the page you visited doesn't exist. ${message}`}</p>
      <p style={messageStyle}>
        Please{" "}
        <a href={`${SIGLA_WEBSITE}report-an-issue/`} target="_blank" rel="noopener noreferrer">
          report an issue
        </a>
        !
      </p>
    </div>
  );
};

export default NotFound;
