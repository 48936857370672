import React, { FC, useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Institution } from "../../api/institution";
import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { fetchAmendments } from "../../features/amendments/amendmentsSlice";
import { fetchVariableById } from "../../features/variables/variablesSlice";

import { getAmendmentsIsLoading, makeGetCompositeVariables } from "./amendmentsSelectors";
import { getVariablesIsLoading, makeGetVariableById } from "../variables/variablesSelectors";

import CompositeVariableTable from "../../components/CompositeVariableTable";
import Container from "../../components/Container";
import NotFound from "../../components/NotFound";
import Spinner from "../../components/Spinner";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";

interface RouteParam {
  id: string;
}

const AmendmentsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    dispatch(fetchVariableById(id));
    dispatch(fetchAmendments(id));
  }, [id, dispatch]);

  const amendmentsIsLoading = useSelector(getAmendmentsIsLoading);
  const variableIsloading = useSelector(getVariablesIsLoading);
  const variable = useSelector(makeGetVariableById(id));
  const compositeVariables = useSelector(makeGetCompositeVariables(id));

  useDocumentTitle(variable ? variable.heading : "Loading...");

  let content;

  if (variable && compositeVariables.length && compositeVariables[0].length) {
    const institution = variable.institution as unknown as Institution;
    content = (
      <>
        <PageHeader title={`${variable.heading}`} />
        <CompositeVariableTable
          institution={institution}
          columnsType={["search", "search", "search", "search", "basic", "basic"]}
          breadcrumbs={[institution.country as string, institution.name, variable.heading]}
          variables={compositeVariables}
          furled={false}
        />
      </>
    );
  } else if (amendmentsIsLoading || variableIsloading) {
    content = <Spinner />;
  } else {
    content = <NotFound message="Amendments don't exist." />;
  }

  return <Container>{content}</Container>;
};

export default withErrorBoundary(AmendmentsPage);
