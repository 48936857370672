import _ from "lodash";

import { Institution } from "../../api/institution";
import { SiglaTriple, Variable, VariableType, NAP } from "../../api/variable";
import createSheetsFromCompositeVariable from "./createSheetsFromCompositeVariable";
import { CompositeVariable, SiglaAnswer } from "../../api";
import { Sheet } from "./DownloadData";

import { DELIMITER } from "../../constants";

const createSheetsFromCompareTable = (
  title: string,
  institutions: { institution: Institution; variables: Variable[][] }[]
) => {
  return institutions[0].variables[0][0].type === VariableType.aggregate
    ? createRightSheets(institutions)
    : createStandardCompareSheet(title, institutions);
};

const createStandardCompareSheet = (
  title: string,
  institutions: { institution: Institution; variables: Variable[][] }[]
) => {
  const institutionPrefixes = _.map(institutions, ({ institution }) => {
    return institution.country
      ? institution.sub_category && institution.sub_category.length > 0
        ? `${institution.country} > ${institution.name}`
        : institution.country
      : institution.name;
  });

  const headers = ["Category", "Variable"];
  _.forEach(institutions, (_, i) => {
    headers.push(`${institutionPrefixes[i]} ${DELIMITER} ${SiglaTriple.answer}`);
    headers.push(`${institutionPrefixes[i]} ${DELIMITER} ${SiglaTriple.originalText}`);
    headers.push(`${institutionPrefixes[i]} ${DELIMITER} ${SiglaTriple.source}`);
  });

  /**selected institution with the largest number of headings */
  const largestInstitution = _.maxBy(institutions, (institution) => institution.variables.length);
  const data = _.map(largestInstitution?.variables, (variablesForAHeading, headingIndex) => {
    const allVariablesForHeading = _.map(
      institutions,
      (institution) => institution.variables[headingIndex] || []
    );
    const largestHeading = _.maxBy(allVariablesForHeading, (variables) => {
      return variables.length;
    });

    const compareVariableRows = _.map(largestHeading, (variable, variableIndex) => {
      const compareRow: Record<string, string> = {
        Category: variable.heading,
        Variable: variable.name,
      };
      _.forEach(institutions, (_, institutionIndex) => {
        const variableForInstitution = (institutions[institutionIndex].variables[headingIndex] ||
          [])[variableIndex];
        compareRow[`${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.answer}`] =
          NAP;
        compareRow[
          `${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.originalText}`
        ] = NAP;
        compareRow[`${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.source}`] =
          NAP;
        if (variableForInstitution) {
          compareRow[
            `${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.answer}`
          ] = variableForInstitution.sigla_answer as string;
          compareRow[
            `${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.originalText}`
          ] = variableForInstitution.orig_text as string;
          compareRow[
            `${institutionPrefixes[institutionIndex]} ${DELIMITER} ${SiglaTriple.source}`
          ] = variableForInstitution.source as string;
        }
      });
      return compareRow;
    });
    return compareVariableRows;
  });

  return [
    {
      title: title,
      headers: headers,
      data: _.flattenDeep(data),
    },
  ];
};

const createRightSheets = (
  institutions: { institution: Institution; variables: Variable[][] }[]
) => {
  const sheets: Sheet[] = [];
  _.forEach(institutions, (institutionData) => {
    const compositeVaribles: CompositeVariable[] = [];
    _.forEach(_.flattenDeep(institutionData.variables), (variable) => {
      _.forEach(variable.sigla_answer as Record<string, string>[][], (right) => {
        compositeVaribles.push({
          _id: variable._id,
          variable: variable._id,
          heading: variable.heading,
          index: variable.variable_index,
          sigla_answers: right as unknown as SiglaAnswer[],
        });
      });
    });
    const sheet = createSheetsFromCompositeVariable(
      compositeVaribles,
      institutionData.institution.country as string
    );
    sheets.push(sheet);
  });
  return sheets;
};

export default createSheetsFromCompareTable;
