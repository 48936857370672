import React, { FC, useState, useEffect } from "react";

import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { TABLE, DB_NAME } from "./constants";
import { InstitutionRecord } from "./recordTypes";
import PinButton from "./PinButton";

interface PinCustomBrowseTableProps {
  data: { institution: Institution; variables: Variable[] }[];
}

const fetchPinStatus = async (
  data: { institution: Institution; variables: Variable[] }[],
  setIsPinned: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const institutions = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.INSTITUTIONS,
  });
  const recordPromises = _.map(data, ({ institution }) => {
    const key = `${institution.country || institution.category}>${institution.name}`;
    return institutions.getItem<InstitutionRecord>(key);
  });
  const records = await Promise.all(recordPromises);
  let pinStatus = true;
  for (let i = 0; i < records.length; i++) {
    const record = records[i];
    if (record === null) {
      pinStatus = false;
      break;
    }
    let missingVariable = false;
    for (let j = 0; j < data[i].variables.length; j++) {
      if (!_.has(record.variables, `${data[i].variables[j].variable_index}`)) {
        missingVariable = true;
        break;
      }
    }
    if (missingVariable) {
      pinStatus = false;
      break;
    }
  }
  setIsPinned(pinStatus);
};

const PinCustomBrowseTable: FC<PinCustomBrowseTableProps> = ({ data }) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);

  useEffect(() => {
    const handleFetchPinStatus = () => {
      fetchPinStatus(data, setIsPinned);
    };
    handleFetchPinStatus();

    window.addEventListener(`${TABLE.INSTITUTIONS}/customBrowse`, handleFetchPinStatus);

    return () =>
      window.removeEventListener(`${TABLE.INSTITUTIONS}/customBrowse`, handleFetchPinStatus);
  }, [data]);

  const onPin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const recordPromises = _.map(data, ({ institution }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      return institutions.getItem<InstitutionRecord>(key);
    });
    const records = await Promise.all(recordPromises);

    const updatePromises = _.map(records, (record, i) => {
      const { institution, variables } = data[i];
      const key = `${institution.country || institution.category}>${institution.name}`;
      const newRecord: InstitutionRecord = record ? { ...record } : { institution, variables: {} };
      newRecord.variables = _.reduce(
        variables,
        (obj, variable) => {
          obj[`${variable.variable_index}`] = variable;
          return obj;
        },
        newRecord.variables
      );
      return institutions.setItem<InstitutionRecord>(key, newRecord);
    });

    await Promise.all(updatePromises);
    setIsPinned(true);
    _.forEach(data, ({ institution, variables }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      _.forEach(variables, ({ variable_index }) => {
        window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}/${variable_index}`));
      });
    });
  };

  const onUnpin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const recordPromises = _.map(data, ({ institution }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      return institutions.getItem<InstitutionRecord>(key);
    });
    const records = await Promise.all(recordPromises);
    const updatePromises: Promise<InstitutionRecord | void>[] = _.map(records, (record, i) => {
      const { institution, variables } = data[i];
      const key = `${institution.country || institution.category}>${institution.name}`;
      const newRecord: InstitutionRecord = record ? { ...record } : { institution, variables: {} };
      _.forEach(variables, (variable) => {
        delete newRecord.variables[`${variable.variable_index}`];
      });
      if (_.keys(newRecord.variables).length > 0) {
        return institutions.setItem<InstitutionRecord>(key, newRecord);
      } else {
        return institutions.removeItem(key);
      }
    });

    await Promise.all(updatePromises);
    setIsPinned(false);
    _.forEach(data, ({ institution, variables }) => {
      const key = `${institution.country || institution.category}>${institution.name}`;
      _.forEach(variables, ({ variable_index }) => {
        window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}/${variable_index}`));
      });
    });
  };

  return <PinButton isPinned={isPinned} type="table" onPin={onPin} onUnpin={onUnpin} />;
};

export default PinCustomBrowseTable;
