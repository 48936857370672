import React, { FC, ReactNode, useEffect } from "react";

import { Button, notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

interface NotificationHintProps {
  showHint: boolean;
  appUiStateKey: string;
  message: ReactNode;
  description: ReactNode;
  duration: number;
  placement?: NotificationPlacement;
}

const DEFAULT_PLACEMENT = "topRight";

const NotificationHint: FC<NotificationHintProps> = ({
  showHint,
  appUiStateKey,
  message,
  description,
  duration,
  placement,
}) => {
  useEffect(() => {
    const disableHint = localStorage.getItem(appUiStateKey) === "true";
    if (showHint && !disableHint) {
      const key = appUiStateKey;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            localStorage.setItem(appUiStateKey, "true");
            notification.close(key);
          }}
        >
          OK
        </Button>
      );
      notification.open({
        placement: placement || DEFAULT_PLACEMENT,
        message,
        description,
        duration,
        btn,
        key,
        onClose: () => localStorage.setItem(appUiStateKey, "true"),
      });
    }
  }, [appUiStateKey, message, description, duration, showHint, placement]);

  return <></>;
};

export default NotificationHint;
