import React, { CSSProperties, FC, useState } from "react";
import { Button } from "antd";

const SKIP_LINK = "Skip to content";

const SkipLink: FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const onFocus = () => setVisible(true);
  const onBlur = () => setVisible(false);

  const skipLinkStyle: CSSProperties = {
    position: "absolute",
    top: visible ? "5px" : "-3em",
    left: "5px",
  };
  return (
    <div style={skipLinkStyle}>
      <Button href="#main" onFocus={onFocus} onBlur={onBlur} size="large">
        {SKIP_LINK}
      </Button>
    </div>
  );
};

export default SkipLink;
