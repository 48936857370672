import React, { FC, CSSProperties, ReactNode, ReactElement, cloneElement } from "react";

interface VisuallyHiddenProps {
  icon: ReactNode;
  text: string;
}

const visuallyHiddenStyle: CSSProperties = {
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
};

const VisuallyHidden: FC<VisuallyHiddenProps> = ({ text, icon }) => {
  const hiddenIcon = cloneElement(icon as ReactElement<any>, {
    "aria-hidden": true,
    focusable: false,
  });
  return (
    <>
      {hiddenIcon}
      <span style={visuallyHiddenStyle}>{text}</span>
    </>
  );
};

export default VisuallyHidden;
