import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getCategories } from "../../api";
import { AppThunk } from "../../app/store";

interface CategoriesState {
  categories: string[];
  isLoading: boolean;
  error: string | null;
}

const initialCategoriesState: CategoriesState = {
  categories: [],
  isLoading: false,
  error: null,
};

const categories = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    getCategoriesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getCategoriesSuccess(state, { payload }: PayloadAction<string[]>) {
      state.isLoading = false;
      state.error = null;
      state.categories = payload;
    },
    getCategoriesFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const { getCategoriesStart, getCategoriesSuccess, getCategoriesFailure } = categories.actions;

export default categories.reducer;

export const fetchCategories = (): AppThunk => async (dispatch, state) => {
  const categories = state().categories.categories;
  if (categories.length === 0) {
    try {
      dispatch(getCategoriesStart());
      const categories = await getCategories();
      dispatch(getCategoriesSuccess(categories));
    } catch (err) {
      dispatch(getCategoriesFailure(err.toString()));
    }
  }
};
