import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface InstitutionsMenuState {
  selectedInstitutions: Record<string, string>[];
  isDisabled: boolean;
}

const initialInstitutionsMenuState: InstitutionsMenuState = {
  selectedInstitutions: [],
  isDisabled: true,
};

export const institutionsMenuFactory = (route: string) =>
  createSlice({
    name: `${route}/institutionsMenu`,
    initialState: { ...initialInstitutionsMenuState } as InstitutionsMenuState,
    reducers: {
      setInstitutionsIsDisabled(state, { payload }: PayloadAction<boolean>) {
        state.isDisabled = payload;
      },
      setSelectedInstitutions(state, { payload }: PayloadAction<Record<string, string>[]>) {
        state.selectedInstitutions = payload;
      },
      updateSelectedInstitutionsByCountry(state, { payload }: PayloadAction<string[]>) {
        const countries = payload;
        state.selectedInstitutions = _.filter(state.selectedInstitutions, ({ country }) =>
          _.includes(countries, country)
        );
      },
      updateSelectedInstitutionsByCategory(state, { payload }: PayloadAction<string[]>) {
        const categories = payload;
        state.selectedInstitutions = _.filter(state.selectedInstitutions, ({ category }) =>
          _.includes(categories, category)
        );
      },
    },
  });
