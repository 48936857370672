import React, { FC, CSSProperties } from "react";

import { PushpinFilled, PushpinOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";

interface PinButtonProps {
  isPinned: boolean;
  type: string;
  onPin(): Promise<void>;
  onUnpin(): Promise<void>;
}

const iconStyle: CSSProperties = {
  fontSize: 24,
};

const isPinnedStyle: CSSProperties = {
  color: "#40a9ff",
  ...iconStyle,
};

const PinButton: FC<PinButtonProps> = ({ isPinned, type, onPin, onUnpin }) => {
  const handleOnPin = async () => {
    await onPin();
    trackCustomEvent(EventCategory.dataStorage, EventAction.pin, type);
  };

  const handleOnUnpin = async () => {
    await onUnpin();
    trackCustomEvent(EventCategory.dataStorage, EventAction.unpin, type);
  };

  return (
    <Tooltip
      title={`${isPinned ? "Unpin" : "Pin"} ${type}`}
      placement="topRight"
      arrowPointAtCenter
      getPopupContainer={(trigger) => trigger}
    >
      <Button
        type="default"
        size="middle"
        icon={
          isPinned ? <PushpinFilled style={isPinnedStyle} /> : <PushpinOutlined style={iconStyle} />
        }
        onClick={isPinned ? handleOnUnpin : handleOnPin}
      />
    </Tooltip>
  );
};

export default PinButton;
