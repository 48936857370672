import axios from "axios";

import { SiglaAnswer } from "./";
import { API_BASE_URL } from "./constants";

const API_URL = `${API_BASE_URL}body_of_law`;

export interface Law {
  _id: string;
  variables: string[];
  index: number;
  sigla_answers: SiglaAnswer[];
}

export const getBodyOfLaw = async (variable: string) => {
  const url = `${API_URL}?id=${variable}`;
  const { data } = await axios.get<Law[]>(url);
  return data;
};
