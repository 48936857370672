import FileSaver from "file-saver";
import JSZip from "jszip";
import _ from "lodash";
import XLSX, { WritingOptions, JSON2SheetOpts } from "xlsx";

import { Sheet, FILE_EXTENSION } from "./DownloadData";

import createExcelSheetName from "../../utils/createExcelSheetName";

const createZipFile = async (zipFileName: string, sheetData: Sheet[][], fileNames: string[]) => {
  const zip = new JSZip();
  _.forEach(sheetData, (sheets, i) => {
    const newWorkbook = XLSX.utils.book_new();
    _.forEach(sheets, (sheet) => {
      const opts: JSON2SheetOpts = { header: sheet.headers, skipHeader: false };
      const worksheet = XLSX.utils.json_to_sheet(sheet.data, opts);
      XLSX.utils.book_append_sheet(newWorkbook, worksheet, createExcelSheetName(sheet.title));
    });
    const wopts: WritingOptions = { bookType: "xlsx", bookSST: false, type: "array" };
    const wbout = XLSX.write(newWorkbook, wopts);
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    zip.file(`${fileNames[i]}${FILE_EXTENSION}`, blob);
  });
  const zipOut = await zip.generateAsync({ type: "blob" });
  FileSaver.saveAs(zipOut, zipFileName);
};

export default createZipFile;
