import React from "react";

import GeneralErrorBoundary from "./GeneralErrorBoundary";

const withErrorBoundary = <P extends object>(Component: React.ComponentType<P>) =>
  class WithErrorBoundary extends React.Component<P> {
    render() {
      return (
        <GeneralErrorBoundary>
          <Component {...(this.props as P)} />
        </GeneralErrorBoundary>
      );
    }
  };

export default withErrorBoundary;
