import React, { FC } from "react";
import { Link } from "react-router-dom";

import { Card, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { SiglaTriple, Variable, VariableType } from "../../api/variable";

import InvisibleText from "../../components/InvisibleText";
import TableCell from "../../components/TableCell";
import TableHeader from "../../components/TableHeader";
import Title from "../../components/Title";
import OpenInNewTab from "../../components/OpenInNewTab";
import DownloadData from "../downloadData/DownloadData";
import createSheetsFromSIT from "../downloadData/createSheetsFromSIT";
import PinSIT from "../myPinnedData/PinSIT";
import PinVariable from "../myPinnedData/PinVariable";
import UnpinButton from "../myPinnedData/UnpinButton";

import { DELIMITER } from "../../constants";

interface SpecificInstitutionTableProps {
  breadcrumbs: string[];
  furled: boolean;
  institution: Institution;
  variables: Variable[][];
  pin: boolean;
}

interface IRow {
  key: string;
  name: string;
  children?: Record<string, string | number>[];
  [propName: string]: any;
}

const headerRender = (header: string) => <TableHeader header={header} />;

const columnRender = (text: string, record: IRow) => {
  if (text) {
    const fontWeight = record.children ? 600 : record.name === text ? 600 : 400;
    const showEllipsis = record.children ? false : record.name === text ? false : true;
    return (
      <TableCell showEllipsis={showEllipsis} fontWeight={fontWeight}>
        {text}
      </TableCell>
    );
  }
};

const siglaAnswerRender = (text: string, record: IRow) => {
  if (text) {
    return record.type === VariableType.composite ? (
      <Link to={`/${record.hyperlink}/${record.key}`} rel="noopener noreferrer" target="_blank">
        {text}
      </Link>
    ) : (
      <TableCell showEllipsis={true} fontWeight={400}>
        {text}
      </TableCell>
    );
  }
};

const createStandardTreeData = (variables: Variable[][]) => {
  return _.map(variables, (variableForAHeading) => {
    return {
      key: variableForAHeading[0].heading,
      name: variableForAHeading[0].heading,
      children: _.map(variableForAHeading, (variable) => {
        return {
          key: variable._id,
          _id: variable._id,
          heading: variable.heading,
          name: variable.name,
          sigla_answer: variable.sigla_answer,
          orig_text: variable.orig_text,
          source: variable.source,
          variable_index: variable.variable_index,
          type: variable.type,
          hyperlink: variable.hyperlink,
        } as Record<string, string | number>;
      }),
    };
  });
};

const SpecificInstitutionTable: FC<SpecificInstitutionTableProps> = ({
  breadcrumbs,
  furled,
  institution,
  variables,
  pin,
}) => {
  const title = (
    <Title>
      {_.join(
        _.reduce(
          breadcrumbs,
          (list, breadcrumb) => {
            const value = _.get(institution, breadcrumb);
            if (value !== undefined) {
              list.push(value);
            }
            return list;
          },
          [] as string[]
        ),
        ` ${DELIMITER} `
      )}
    </Title>
  );

  const pinColumnRender = (text: string, record: IRow) => {
    if (record.sigla_answer) {
      const variable: Variable = {
        _id: record._id,
        institution: institution._id as string,
        heading: record.heading as string,
        name: record.name as string,
        sigla_answer: record.sigla_answer as string,
        orig_text: record.orig_text as string,
        source: record.source as string,
        variable_index: record.variable_index as number,
        type: record.type as string,
        hyperlink: record.hyperlink as string,
      };
      return pin ? (
        <PinVariable institution={institution} variable={variable} type="variable" />
      ) : (
        <UnpinButton
          type="variable"
          compare={false}
          data={[{ institution, variables: [variable] }]}
        />
      );
    }
  };

  const standardColumns: ColumnsType<IRow> = [
    {
      title: headerRender("Variable"),
      dataIndex: "name",
      key: "name",
      render: columnRender,
    },
    {
      title: headerRender(SiglaTriple.answer),
      dataIndex: "sigla_answer",
      key: "sigla_answer",
      render: siglaAnswerRender,
    },
    {
      title: headerRender(SiglaTriple.originalText),
      dataIndex: "orig_text",
      key: "orig_text",
      render: columnRender,
    },
    {
      title: headerRender(SiglaTriple.source),
      dataIndex: "source",
      key: "source",
      render: columnRender,
    },
    {
      key: "pin",
      title: <InvisibleText text="Pin" />,
      width: "5%",
      render: pinColumnRender,
    },
  ];

  const showOpenInNewTab = pin ? furled : false;
  const icons = (
    <Space>
      {showOpenInNewTab && (
        <OpenInNewTab
          route={`/institutions/${institution._id}`}
          tooltipTitle="Open table in a new tab"
        />
      )}
      <DownloadData
        compositeVariables={_.filter(
          _.flattenDeep(variables),
          (variable) => variable.type === VariableType.composite
        )}
        tooltipTitle="Download table"
        saveAsFileName="SIGLA_Institution_Data"
        sheets={createSheetsFromSIT(institution, _.flattenDeep(variables))}
      />
      {pin ? (
        <PinSIT institution={institution} variables={_.flattenDeep(variables)} />
      ) : (
        <UnpinButton
          type="table"
          compare={false}
          data={[{ institution, variables: _.flattenDeep(variables) }]}
        />
      )}
    </Space>
  );
  return (
    <Card
      className="sigla-table"
      hoverable
      title={title}
      extra={icons}
      bodyStyle={{ padding: 0, cursor: "auto" }}
    >
      <Table
        className="specific-institution-table"
        rowClassName="main-row"
        tableLayout="fixed"
        columns={standardColumns}
        dataSource={createStandardTreeData(variables)}
        expandable={{
          childrenColumnName: "children",
          defaultExpandAllRows: !furled,
          indentSize: 15,
        }}
        pagination={false}
        components={{
          header: {
            cell: (props: any) => <th {...props} scope="col" />,
          },
        }}
      />
    </Card>
  );
};

export default SpecificInstitutionTable;
