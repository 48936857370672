export const selectColumn = {
  xs: 24,
  sm: 24,
  lg: 7,
};

export const seeDataColumn = {
  xs: 24,
  sm: 24,
  lg: 3,
};
