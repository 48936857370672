import React, { FC, useState, useEffect } from "react";

import localforage from "localforage";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import { TABLE, DB_NAME } from "./constants";
import PinButton from "./PinButton";
import { InstitutionRecord } from "./recordTypes";

interface PinVariableProps {
  institution: Institution;
  variable: Variable;
  type: string;
}

const fetchPinStatus = async (
  institution: Institution,
  variable: Variable,
  setIsPinned: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const institutions = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.INSTITUTIONS,
  });
  const key = `${institution.country || institution.category}>${institution.name}`;
  const record = await institutions.getItem<InstitutionRecord>(key);
  const pinStatus = record ? _.has(record.variables, `${variable.variable_index}`) : false;
  setIsPinned(pinStatus);
};

const PinVariable: FC<PinVariableProps> = ({ institution, variable, type }) => {
  const [isPinned, setIsPinned] = useState<boolean>(false);

  const key = `${institution.country || institution.category}>${institution.name}`;

  useEffect(() => {
    const handleFetchPinStatus = () => {
      fetchPinStatus(institution, variable, setIsPinned);
    };
    handleFetchPinStatus();

    window.addEventListener(
      `${TABLE.INSTITUTIONS}/${key}/${variable.variable_index}`,
      handleFetchPinStatus
    );

    return () =>
      window.removeEventListener(
        `${TABLE.INSTITUTIONS}/${key}/${variable.variable_index}`,
        handleFetchPinStatus
      );
  }, [key, institution, variable]);

  const onPin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const record = await institutions.getItem<InstitutionRecord>(key);
    const newRecord: InstitutionRecord = record ? { ...record } : { institution, variables: {} };
    newRecord.variables[`${variable.variable_index}`] = variable;

    await institutions.setItem(key, newRecord);
    setIsPinned(true);
    window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}`));
    window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/customBrowse`));
  };

  const onUnpin = async () => {
    const institutions = localforage.createInstance({
      name: DB_NAME,
      storeName: TABLE.INSTITUTIONS,
    });
    const record = await institutions.getItem<InstitutionRecord>(key);
    if (record) {
      const newRecord: InstitutionRecord = { ...record };
      delete newRecord.variables[`${variable.variable_index}`];
      if (_.keys(newRecord.variables).length > 0) {
        await institutions.setItem(key, newRecord);
      } else {
        await institutions.removeItem(key);
      }
    }

    setIsPinned(false);
    window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/${key}`));
    window.dispatchEvent(new CustomEvent(`${TABLE.INSTITUTIONS}/customBrowse`));
  };

  return <PinButton isPinned={isPinned} onPin={onPin} onUnpin={onUnpin} type={type} />;
};

export default PinVariable;
