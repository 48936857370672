import React, { CSSProperties, FC, ReactNode } from "react";

import { black } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

interface BadgeProps {
  count: number;
  children: ReactNode;
}

const RADIUS_STANDARD = 10;
const MAX_COUNT = 100;

const rootStyle: CSSProperties = {
  display: "inline-flex",
  verticalAlign: "center",
};

const badgeStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: RADIUS_STANDARD * 2,
  height: RADIUS_STANDARD * 2,
  borderRadius: RADIUS_STANDARD,
  boxSizing: "border-box",
  color: black.black_1,
  fontSize: fontSizes.font_size_4,
  backgroundColor: "#40a9ff",
  padding: "0 6px",
};

const Badge: FC<BadgeProps> = ({ count, children }) => {
  return (
    <span style={rootStyle}>
      {children}
      {count > 0 && (
        <span style={badgeStyle}>{count >= MAX_COUNT ? `${MAX_COUNT - 1}+` : count}</span>
      )}
    </span>
  );
};

export default Badge;
