import React, { FC } from "react";

import { Row, Col, Checkbox, Button, Grid } from "antd";
import _ from "lodash";

const { useBreakpoint } = Grid;

interface FilterTableColumnProps {
  dataIndex: string;
  values: string[];
  selectedKeys: React.Key[];
  setSelectedKeys(selectedKeys: React.Key[]): void;
  confirm(): void;
  clearFilters?(): void;
  handleFilter(selectedKeys: string[], confirm: () => void, dataIndex: string): void;
  handleReset(dataIndex: string, clearFilters?: () => void): void;
}

const FilterTableColumn: FC<FilterTableColumnProps> = ({
  dataIndex,
  values,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  handleFilter,
  handleReset,
}) => {
  const { xs } = useBreakpoint();
  return (
    <div style={{ padding: 8, width: xs ? "50vw" : "25vw" }}>
      <Row gutter={[8, 8]} style={{ maxHeight: 256, overflowY: "auto" }}>
        <Col span={24}>
          <Checkbox.Group
            value={selectedKeys}
            onChange={(checkedValue) =>
              setSelectedKeys(_.map(checkedValue, (value) => value as string))
            }
          >
            <Row gutter={[8, 8]}>
              {values.map((value, i) => {
                return (
                  <Col key={i} span={24} style={{ margin: "4px 8px" }}>
                    <Checkbox value={value}>{value}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row
        gutter={[8, 8]}
        justify="space-between"
        style={{ borderTop: `1px solid #d9d9d9`, paddingTop: 8, marginBottom: 0 }}
      >
        <Col>
          <Button type="default" size="small" onClick={() => handleReset(dataIndex, clearFilters)}>
            Reset
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            size="small"
            onClick={() => handleFilter(selectedKeys as string[], confirm, dataIndex)}
          >
            Filter
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default FilterTableColumn;
