import React, { FC } from "react";

import { Tooltip, Button } from "antd";
import { BsBoxArrowUpRight } from "react-icons/bs";

interface OpenInNewTabProps {
  route: string;
  tooltipTitle: string;
}

const OpenInNewTab: FC<OpenInNewTabProps> = ({ route, tooltipTitle }) => {
  const onClick = () => {
    window.open(route, "_blank");
  };
  return (
    <Tooltip
      title={tooltipTitle}
      placement="topRight"
      arrowPointAtCenter
      getPopupContainer={(trigger) => trigger}
    >
      <Button
        aria-label={tooltipTitle}
        type="default"
        size="middle"
        onClick={onClick}
        icon={<BsBoxArrowUpRight style={{ fontSize: 22 }} />}
      />
    </Tooltip>
  );
};

export default OpenInNewTab;
