import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface VariablesMenuState {
  isDisabled: boolean;
}

const initialVariablesMenuState: VariablesMenuState = {
  isDisabled: true,
};

export const variablesMenuFactory = (route: string) =>
  createSlice({
    name: `${route}/variablesMenu`,
    initialState: { ...initialVariablesMenuState } as VariablesMenuState,
    reducers: {
      setVariablesIsDisabled(state, { payload }: PayloadAction<boolean>) {
        state.isDisabled = payload;
      },
    },
  });
