import React, { FC, ReactNode } from "react";

import { Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import _ from "lodash";

import { CustomBrowseTreeNode } from "./customBrowseSelectors";

interface ExampleTreeProps {
  data: CustomBrowseTreeNode[];
}

const loop = (data: CustomBrowseTreeNode[]) => {
  const dataNodes: DataNode[] = _.map(data, (node) => {
    const styledTitle: ReactNode = (
      <span style={{ fontSize: node.fontSize, color: node.color }}>{node.title}</span>
    );

    if (node.children) {
      return { ...node, title: styledTitle, children: loop(node.children) };
    }

    return {
      ...node,
      title: styledTitle,
    } as DataNode;
  });
  return dataNodes;
};

const ExampleTree: FC<ExampleTreeProps> = ({ data }) => {
  return <Tree checkable defaultExpandAll selectable={false} treeData={loop(data)} />;
};

export default ExampleTree;
