import React, { FC, CSSProperties } from "react";

import { black } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

interface TableHeaderProps {
  header: string;
  fontWeight?: number;
}

const DEFALT_FONTWEIGHT = 700;

const TableHeader: FC<TableHeaderProps> = ({ header, fontWeight }) => {
  const headerStyle: CSSProperties = {
    color: black.black_2,
    fontSize: fontSizes.font_size_6,
    fontWeight: fontWeight || DEFALT_FONTWEIGHT,
  };
  return <span style={headerStyle}>{header}</span>;
};

export default TableHeader;
