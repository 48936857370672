import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";

import { Collapse, Space } from "antd";
import _ from "lodash";
import { GoGlobe } from "react-icons/go";
import { ImLibrary } from "react-icons/im";

import { useAppDispatch } from "../../app/store";
import {
  fetchCountries,
  setSelectedCountries,
  fetchInstitutions,
  setSelectedInstitutions,
  fetchVariables,
  setSelectedVariables,
  updateSelectedInstitutions,
  updateSelectedVariablesByCountry,
  updateSelectedVariablesByInstitution,
  setShowSeeDataCard,
  setActivePanel,
} from "./customBrowseSlice";
import {
  getActivePanel,
  getCountriesIsLoading,
  getCountryNodes,
  getInstitutionNodes,
  getInstitutionsIsLoading,
  getSelectedCountries,
  getSelectedInstitutions,
  getSelectedVariables,
  getVariableNodes,
  getVariablesIsLoading,
  makeGetFlattenedNodes,
} from "./customBrowseSelectors";

import Badge from "../../components/Badge";
import Title from "../../components/Title";
import VariableIcon from "../../components/VariableIcon";
import CustomBrowseTree from "./CustomBrowseTree";

const { Panel } = Collapse;

const CustomBrowseMenu: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchInstitutions());
  }, [dispatch]);

  const activePanel = useSelector(getActivePanel);
  const onActivePanelChange = (key: string | string[]) => {
    const panelKey = key as string;
    dispatch(setActivePanel(panelKey));
  };
  const expandIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
      <button
        tabIndex={-1}
        role="presentation"
        style={{ outline: 0 }}
        className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
          isActive ? "expanded" : "collapsed"
        }`}
      />
    );
  };

  const selectedCountries = useSelector(getSelectedCountries);
  const countriesIsLoading = useSelector(getCountriesIsLoading);
  const countryList = useSelector(getCountryNodes);
  const flattenedCountryList = useSelector(makeGetFlattenedNodes(countryList));
  const onSelectCountry = (countries: any) => {
    dispatch(setShowSeeDataCard(true));
    dispatch(setSelectedCountries(countries));
    dispatch(updateSelectedInstitutions(countries));
    dispatch(updateSelectedVariablesByCountry(countries));
  };
  const onSelectAllCountries = () => {
    onSelectCountry(_.map(flattenedCountryList, ({ key }) => key));
  };
  const onUnselectAllCountries = () => {
    onSelectCountry([]);
  };

  const selectedInstitutions = useSelector(getSelectedInstitutions);
  const institutionsIsLoading = useSelector(getInstitutionsIsLoading);
  const institutionList = useSelector(getInstitutionNodes);
  const flattenedInstitutionList = useSelector(makeGetFlattenedNodes(institutionList));
  const onSelectInstitutionName = (selectedKeys: any) => {
    dispatch(setShowSeeDataCard(true));
    //Don't include category nodes
    const selections = _.filter(selectedKeys, (selectedKey) => selectedKey.split(">").length === 2);
    const selectedInstitutions = _.map(selections, (selection) => {
      const [category, name] = _.split(selection, ">");
      return {
        category,
        name,
      };
    });
    dispatch(setSelectedInstitutions(selectedInstitutions));
    dispatch(fetchVariables(selectedInstitutions));
    dispatch(updateSelectedVariablesByInstitution(selectedInstitutions));
  };
  const onSelectAllInstitutionName = () => {
    onSelectInstitutionName(_.map(flattenedInstitutionList, ({ key }) => key));
  };
  const onUnselectAllInstitutionName = () => {
    onSelectInstitutionName([]);
  };

  const selectedVariables = useSelector(getSelectedVariables);
  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variableList = useSelector(getVariableNodes);
  const flattenedVariableList = useSelector(makeGetFlattenedNodes(variableList));
  const onSelectVariableName = (selectedKeys: any) => {
    dispatch(setShowSeeDataCard(true));
    //remove
    _.forEach(selectedKeys, (selectedKey) => {
      if (_.split(selectedKey, ">").length > 4) {
        console.error(selectedKey);
      }
    });
    const selections = _.filter(selectedKeys, (selectedKey) => {
      return _.split(selectedKey, ">").length === 4;
    });
    const selectedVariables = _.map(selections, (selection) => {
      const [category, institution_name, heading, name] = _.split(selection, ">");
      return {
        category,
        institution_name,
        heading,
        name,
      };
    });
    dispatch(setSelectedVariables(selectedVariables));
  };
  const onSelectAllVariableName = () => {
    onSelectVariableName(_.map(flattenedVariableList, ({ key }) => key));
  };
  const onUnselectAllVariableName = () => {
    onSelectVariableName([]);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Collapse
        accordion
        activeKey={activePanel}
        onChange={onActivePanelChange}
        expandIcon={expandIcon}
        expandIconPosition="right"
      >
        <Panel
          header={
            <Badge count={selectedCountries.length}>
              <Title fontWeight={600}>
                <Space>
                  <GoGlobe />
                  {"Countries"}
                </Space>
              </Title>
            </Badge>
          }
          showArrow={true}
          key="country"
        >
          <CustomBrowseTree
            isLoading={countriesIsLoading}
            searchable={false}
            checkedKeys={selectedCountries}
            treeData={countryList}
            flattenedTreeData={flattenedCountryList}
            initialExpandedTreeCutoffLevel={0}
            virtual={false}
            onCheck={onSelectCountry}
            onSelectAll={onSelectAllCountries}
            onUnselectAll={onUnselectAllCountries}
          />
        </Panel>
        <Panel
          header={
            <Badge count={selectedInstitutions.length}>
              <Title fontWeight={600}>
                <Space>
                  <ImLibrary />
                  {"Institutions"}
                </Space>
              </Title>
            </Badge>
          }
          showArrow={true}
          key="institution"
        >
          <CustomBrowseTree
            isLoading={institutionsIsLoading}
            searchable={true}
            checkedKeys={_.map(selectedInstitutions, ({ category, name }) => `${category}>${name}`)}
            treeData={institutionList}
            flattenedTreeData={flattenedInstitutionList}
            initialExpandedTreeCutoffLevel={2}
            virtual={true}
            onCheck={onSelectInstitutionName}
            onSelectAll={onSelectAllInstitutionName}
            onUnselectAll={onUnselectAllInstitutionName}
          />
        </Panel>
        <Panel
          header={
            <Badge count={selectedVariables.length}>
              <Title fontWeight={600}>
                <Space>
                  <VariableIcon />
                  {"Variables"}
                </Space>
              </Title>
            </Badge>
          }
          showArrow={true}
          key="variable"
        >
          <CustomBrowseTree
            isLoading={variablesIsLoading}
            searchable={true}
            checkedKeys={_.map(
              selectedVariables,
              ({ category, institution_name, heading, name }) =>
                `${category}>${institution_name}>${heading}>${name}`
            )}
            treeData={variableList}
            flattenedTreeData={flattenedVariableList}
            initialExpandedTreeCutoffLevel={3}
            virtual={true}
            onCheck={onSelectVariableName}
            onSelectAll={onSelectAllVariableName}
            onUnselectAll={onUnselectAllVariableName}
          />
        </Panel>
      </Collapse>
    </Space>
  );
};

export default CustomBrowseMenu;
