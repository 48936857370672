import React, { FC } from "react";

import { Link } from "react-router-dom";

import { Variable, VariableType } from "../api/variable";

import AggregateTableCell from "./AggregateTableCell";
import TableCell from "./TableCell";

interface SiglaAnswerCellProps {
  variable: Variable;
}

const SiglaAnswerCell: FC<SiglaAnswerCellProps> = ({ variable }) => {
  let content;

  if (variable.type === VariableType.aggregate) {
    const siglaAnswers = variable.sigla_answer as Record<string, string>[][];
    content = <AggregateTableCell siglaAnswers={siglaAnswers} />;
  } else if (variable.type === VariableType.composite) {
    content = (
      <Link to={`/${variable.hyperlink}/${variable._id}`} rel="noopener noreferrer" target="_blank">
        {variable.sigla_answer as string}
      </Link>
    );
  } else {
    content = (
      <TableCell showEllipsis={false} fontWeight={400}>
        {variable.sigla_answer as string}
      </TableCell>
    );
  }
  return <>{content}</>;
};

export default SiglaAnswerCell;
