import { configureStore, Action } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
});

/*if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.aceept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}*/

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;

/**local storage keys for app ui state config */
export enum APP_UI_STATE {
  DISABLE_SIGLA_DATA_HINT = "disable-sigla-data-hint",
  DISABLE_COMPARE_ROUTE_HINT = "disable-compare-route-hint",
  DISABLE_CUSTOM_BROWSE_TABLE_HINT = "disable-custom-browse-table-hint",
}
