import React, { FC } from "react";

import { Card, Space } from "antd";
import _ from "lodash";

import { CompositeVariable } from "../api";
import { Institution } from "../api/institution";

import FilterTable from "./FilterTable";
import Title from "./Title";
import DownloadData from "../features/downloadData/DownloadData";
import createSheetsFromCompositeVariable from "../features/downloadData/createSheetsFromCompositeVariable";

import { DELIMITER } from "../constants";

interface CompositeVariableTableProps {
  breadcrumbs: string[];
  institution: Institution;
  furled: boolean;
  variables: CompositeVariable[][];
  columnsType: string[];
}

const CompositeVariableTable: FC<CompositeVariableTableProps> = ({
  breadcrumbs,
  institution,
  furled,
  columnsType,
  variables,
}) => {
  const title = <Title>{_.join(breadcrumbs, ` ${DELIMITER} `)}</Title>;
  const icons = (
    <Space>
      <DownloadData
        tooltipTitle={`Download table`}
        saveAsFileName={`SIGLA_${_.join(breadcrumbs, "_")}_Data`}
        sheets={[createSheetsFromCompositeVariable(_.flattenDeep(variables), "Table")]}
      />
    </Space>
  );

  const headingData = _.map(variables, (variablesForAHeading, i) => {
    const variable = variablesForAHeading[0];
    return {
      key: `${i}`,
      rowType: "main",
      [`${_.includes(breadcrumbs[2], "Rights") ? "heading" : variable.sigla_answers[0].name}`]:
        variable.heading,
      children: _.map(variablesForAHeading, (cvData, j) => {
        const row = _.reduce(
          cvData.sigla_answers,
          (obj, col) => {
            obj[col.name] = col.answer;
            return obj;
          },
          {} as Record<string, string>
        );
        return {
          ...row,
          rowType: "child",
          key: `${i}>${j}`,
        };
      }),
    };
  });

  const flattenedVariables = _.flattenDeep(variables);
  const flattenedData = _.map(flattenedVariables, ({ sigla_answers }) => {
    return _.map(sigla_answers, ({ name, answer }) => {
      return {
        name,
        answer,
      };
    });
  });
  return (
    <Card
      className="sigla-table"
      hoverable
      title={title}
      extra={icons}
      bodyStyle={{ padding: 0, cursor: "auto" }}
    >
      <FilterTable
        headingColumnIsSeparate={_.includes(breadcrumbs[2], "Rights")}
        institution={institution}
        data={headingData}
        flattenedData={flattenedData}
        columnsType={columnsType}
        includePinColumn={false}
        pin={false}
        furled={furled}
      />
    </Card>
  );
};

export default CompositeVariableTable;
