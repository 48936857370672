import React, { CSSProperties, FC, useState, useRef } from "react";

import { DownOutlined, FilterFilled, MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import { Modal, Space, Table, Tooltip, Grid, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import { Variable } from "../../api/variable";
import { Institution } from "../../api/institution";

import FilterOption from "../../components/FilterOption";
import TableHeader from "../../components/TableHeader";
import TableCell from "../../components/TableCell";
import Title from "../../components/Title";

import { black, secondary } from "../../styles/colors";
import { fontSizes } from "../../styles/fonts";

import "../customBrowse/CustomBrowseTable.css";

const { useBreakpoint } = Grid;

interface CompareRightsModalProps {
  title: string;
  rights: {
    institution: Institution;
    variable: Variable;
  }[];
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: black.black_3, fontSize: fontSizes.font_size_8 }} />
));

const SortableRow = SortableElement((props: any) => <tr {...props} />);
const SortableTableContainer = SortableContainer((props: any) => <tbody {...props} />);

const headerRender = (header: string) => <TableHeader header={header} />;

const columnRenderFactory = (showEllipsis: boolean) => (text: string) => {
  return (
    <TableCell
      showEllipsis={showEllipsis}
      fontWeight={400}
      numberOfRows={showEllipsis ? 2 : undefined}
    >
      {text}
    </TableCell>
  );
};

const CompareRightsModal: FC<CompareRightsModalProps> = ({ title, rights }) => {
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const openModal = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const getColumnFilterProps = (dataIndex: string, values: string[]) => ({
    filters: _.map(values, (value) => {
      return {
        text: <FilterOption text={value} maxWidth={"25vw"} />,
        value: value,
      };
    }),
    filterIcon: (filtered: boolean) => (
      <Tooltip
        title={`Filter ${dataIndex}`}
        placement="top"
        arrowPointAtCenter
        getPopupContainer={(trigger) => trigger}
      >
        <Button
          aria-label={`Filter ${dataIndex}`}
          icon={
            <FilterFilled
              style={{
                fontSize: fontSizes.font_size_8,
                color: filtered ? secondary : black.black_3,
              }}
            />
          }
        />
      </Tooltip>
    ),
    onFilter: (value: string | number | boolean, record: Record<string, string>) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false,
  });
  const siglaAnswers = rights[0].variable.sigla_answer as Record<string, string>[][];
  const columns: ColumnsType<Record<string, string>> = [
    {
      dataIndex: "sort",
      className: "drag-visible",
      width: "5%",
      render: () => <DragHandle />,
    },
    {
      key: "country",
      dataIndex: "country",
      className: "drag-visible",
      title: headerRender("Country"),
      //width: "10%",
      render: columnRenderFactory(false),
      ...getColumnFilterProps(
        "country",
        _.map(rights, ({ institution }) => institution.country as string)
      ),
    },
    ..._.map(siglaAnswers[0], (siglaAnswer, i) => {
      return {
        // beneficiaries and right to are drag-visible
        className: i < 2 ? "drag-visible" : "",
        key: siglaAnswer.name,
        title: headerRender(siglaAnswer.name),
        dataIndex: siglaAnswer.name,
        render: columnRenderFactory(i === 2),
      };
    }),
  ];

  const data = _.reduce(
    rights,
    (list, current) => {
      const { institution, variable } = current;
      const siglaAnswers = variable.sigla_answer as Record<string, string>[][];
      _.forEach(siglaAnswers, (siglaAnswer, i) => {
        const right = _.reduce(
          siglaAnswer,
          (obj, col) => {
            obj[col.name] = col.answer;
            return obj;
          },
          {} as Record<string, string>
        );
        right.country = institution.country as string;
        right._id = `${variable._id}>${i}`;
        list.push(right);
      });
      return list;
    },
    [] as Record<string, string>[]
  );
  const allBeneficiaries = _.reduce(
    data,
    (list, record) => {
      const beneficiaries = _.split(record[siglaAnswers[0][0].name], ";");
      list.push(...beneficiaries);
      return list;
    },
    [] as string[]
  );
  const trimmedBeneficiaries = _.map(allBeneficiaries, (beneficiary) => _.trim(beneficiary));
  const uniqueBeneficiaries = _.uniq(
    _.sortBy(_.filter(trimmedBeneficiaries, (beneficary) => beneficary.length > 0))
  );
  columns[2] = {
    ...columns[2],
    ...getColumnFilterProps(siglaAnswers[0][0].name, uniqueBeneficiaries),
  };
  const uniqueRightTo = _.uniq(
    _.sortBy(_.map(data, (record) => _.trim(record[siglaAnswers[0][1].name])))
  );
  columns[3] = {
    ...columns[3],
    ...getColumnFilterProps(siglaAnswers[0][1].name, uniqueRightTo),
  };
  columns[4].width = "30%";

  const [dataSource, setDataSource] = useState<Record<string, string>[]>(data);
  const onSortStart = () => {
    document.body.className = "grabbing";
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        ([] as Record<string, string>[]).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
    }
    document.body.className = "";
  };

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = dataSource.findIndex((x) => x._id === restProps["data-row-key"]);
    return <SortableRow className={className} style={style} index={index} {...restProps} />;
  };

  const DraggableContainer = (props: any) => (
    <SortableTableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const { xl } = useBreakpoint();

  const modalStyle: CSSProperties = {
    top: xl ? "16vh" : "0px",
  };

  const fullModalStyle: CSSProperties = {
    margin: "0px",
    maxWidth: "none",
    padding: "0px",
  };

  return (
    <>
      <Tooltip title={`Open ${title} in a new table`} getPopupContainer={(trigger) => trigger}>
        <div ref={modalContainerRef} onClick={openModal} style={{ cursor: "pointer" }}>
          <Space>
            <button
              className="table-row-down-arrow-icon ant-table-row-expand-icon"
              aria-label={`Open ${title} in a new table`}
            >
              <DownOutlined />
            </button>
            <TableCell showEllipsis={false} fontWeight={600}>
              {title}
            </TableCell>
          </Space>
        </div>
      </Tooltip>
      <Modal
        closable
        destroyOnClose
        maskClosable={false}
        footer={null}
        title={<Title>{title}</Title>}
        visible={visible}
        zIndex={99}
        onCancel={onClose}
        bodyStyle={{ maxHeight: xl ? "74vh" : "100vh", overflow: "auto" }}
        width={xl ? "80vw" : "100vw"}
        style={xl ? modalStyle : { ...modalStyle, ...fullModalStyle }}
        getContainer={modalContainerRef.current}
        focusTriggerAfterClose={true}
      >
        <Table
          className="compare-rights-modal-table"
          tableLayout="fixed"
          columns={columns}
          dataSource={dataSource}
          rowKey="_id"
          pagination={{
            defaultPageSize: 20,
            hideOnSinglePage: true,
            position: ["topRight", "bottomRight"],
          }}
          components={{
            header: {
              cell: (props: any) => <th {...props} scope="col" />,
            },
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        />
      </Modal>
    </>
  );
};

export default CompareRightsModal;
