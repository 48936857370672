import React, { FC } from "react";

import TableHeader from "./TableHeader";

interface TableSortProps {
  header: string;
}

const TableSort: FC<TableSortProps> = ({ header }) => {
  //This relies on event bubbling so that clicking this button bubbles click event to table header to sort
  return (
    <button
      className="custom-browse-table-sorter"
      style={{
        width: "100%",
        cursor: "pointer",
        border: 0,
        padding: 0,
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "transparent",
      }}
    >
      <TableHeader header={header} fontWeight={700} />
    </button>
  );
};

export default TableSort;
