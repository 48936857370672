import _ from "lodash";

export const SHEET_NAME_LENGTH_LIMIT = 30;

/**Remove \/*?[] from sheet name and limit to lenght of 30 */
const createExcelSheetName = (sheetName: string) => {
  return _.replace(sheetName, /[\\/*?[\]]/g, ``).slice(0, SHEET_NAME_LENGTH_LIMIT);
};

export default createExcelSheetName;
