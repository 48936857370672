import _ from "lodash";

export enum SORT_ORDER {
  asc = "asc",
  desc = "desc",
}

const EXECUTIVE_SORT_BY = ["Executive Overview", "Presidency", "Ministries Overview"];

const replaceTheStr = (name: string) =>
  name
    .replace(/\s+the\s+/g, " ")
    .replace(/\s\s+/g, " ")
    .trim();

const createSortBy = (sortBy: string) => (institution: Record<string, any>) =>
  institution.name !== sortBy;

const createExecutiveSortBy = () => {
  return EXECUTIVE_SORT_BY.map((sortBy) => createSortBy(sortBy));
};

const createExecutiveSortByName = () => {
  return (institution: Record<string, any>) => replaceTheStr(institution.name);
};

export const executiveSortByConfig = [...createExecutiveSortBy(), createExecutiveSortByName()];
export const executiveSortOrderConfig = _.fill(Array(4), SORT_ORDER.asc);

//Sort executive sits in by category page
export const byCategorySortByConfig = [
  (institution: Record<string, any>) => institution.country,
  ...executiveSortByConfig,
];
export const byCategorySortOrderConfig = _.fill(Array(5), SORT_ORDER.asc);

//Sort executive sits in by country page
export const byCountrySortByConfig = [
  (institution: Record<string, any>) => institution.category,
  ...executiveSortByConfig,
];
export const byCountrySortOrderConfig = _.fill(Array(5), SORT_ORDER.asc);

//Sort executive names in compare page
export const compareSortByConfig = [
  ...EXECUTIVE_SORT_BY.map((sortBy) => (name: string) => name !== sortBy),
  (name: string) => replaceTheStr(name),
];
//Inludes the length sort order
export const compareSortOrderConfig = _.fill(Array(5), SORT_ORDER.asc);
