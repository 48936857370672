import React, { FC, useEffect, useState, Key } from "react";
import Highlighter from "react-highlight-words";

import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import { Table, Tooltip, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import _ from "lodash";

import { Institution } from "../api/institution";
import { Variable } from "../api/variable";

import TableCell from "./TableCell";
import TableHeader from "./TableHeader";

import FilterTableColumn from "./FilterTableColumn";
import InvisibleText from "./InvisibleText";
import SearchTableColumn from "./SearchTableColumn";
import PinVariable from "../features/myPinnedData/PinVariable";
import UnpinButton from "../features/myPinnedData/UnpinButton";

import { fontSizes } from "../styles/fonts";
import { black, secondary } from "../styles/colors";

interface IMainRow {
  key: string;
  rowType: string;
  variable?: Variable;
  children?: Record<string, string>[];
  [propName: string]: any;
}

interface FilterTableProps {
  headingColumnIsSeparate: boolean;
  institution: Institution;
  data: IMainRow[];
  flattenedData: Record<string, string>[][];
  columnsType: string[];
  includePinColumn: boolean;
  pin: boolean;
  furled: boolean;
}

const headerRender = (header: string) => <TableHeader header={header} />;

const FilterTable: FC<FilterTableProps> = ({
  headingColumnIsSeparate,
  institution,
  data,
  flattenedData,
  columnsType,
  includePinColumn,
  pin,
  furled,
}) => {
  const columnNames = _.map(flattenedData[0], (col) => col.name);
  const [dataSource, setDataSource] = useState<IMainRow[]>(data);
  useEffect(() => {
    setDataSource(data);
  }, [data]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>(
    furled ? [] : _.map(data, ({ key }) => key)
  );
  const onExpandedRowsChange = (expandedRowKeys: readonly Key[]) =>
    setExpandedRowKeys(expandedRowKeys);

  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const filterIsStrict = _.reduce(
    columnNames,
    (obj, columnName, i) => {
      const columnType = columnsType[i];
      obj[columnName] = columnType === "filter";
      return obj;
    },
    {} as Record<string, boolean>
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [dataIndex]: selectedKeys,
      };
    });
    const newFilters = {
      ...filters,
      [dataIndex]: selectedKeys,
    };
    const relevantFilters = _.filter(columnNames, (columnName) => {
      const filterValues = _.get(newFilters, columnName);
      return !(_.isUndefined(filterValues) || filterValues.length === 0);
    });
    if (relevantFilters.length > 0) {
      const newData = filterTableData(data, relevantFilters, newFilters, filterIsStrict);
      setDataSource(newData);
      const newExpandedRowKeys = _.reduce(
        newData,
        (list, datum) => {
          if (datum.children && datum.children.length > 0) {
            list.push(datum.key);
          }
          return list;
        },
        [] as string[]
      );
      setExpandedRowKeys(newExpandedRowKeys);
    }
  };
  const handleReset = (dataIndex: string, clearFilters?: () => void) => {
    if (clearFilters) {
      clearFilters();
    }
    setFilters((oldFilters) => {
      return {
        ...oldFilters,
        [dataIndex]: [],
      };
    });
    const relevantFilters = _.filter(columnNames, (columnName) => {
      const filterValues = _.get(filters, columnName);
      if (columnName === dataIndex) {
        return false;
      }
      return !(_.isUndefined(filterValues) || filterValues.length === 0);
    });
    const newData = filterTableData(data, relevantFilters, filters, filterIsStrict);
    setDataSource(newData);
    const newExpandedRowKeys = _.reduce(
      newData,
      (list, datum) => {
        if (datum.children && datum.children.length > 0) {
          list.push(datum.key);
        }
        return list;
      },
      [] as string[]
    );
    setExpandedRowKeys(
      relevantFilters.length === 0
        ? furled
          ? []
          : _.map(data, ({ key }) => key)
        : newExpandedRowKeys
    );
  };
  const getColumnFilterProps = (dataIndex: string, columnIndex: number) => {
    const allValues = _.reduce(
      flattenedData,
      (list, datum) => {
        const values = _.map(_.split(datum[columnIndex].answer, ";"), (value) => _.trim(value));
        const validValues = _.filter(values, (value) => value.length > 0);
        list.push(...validValues);
        return list;
      },
      [] as string[]
    );
    const uniqValues = _.uniq(_.sortBy(allValues));
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <FilterTableColumn
            dataIndex={dataIndex}
            values={uniqValues}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            handleFilter={handleSearch}
            handleReset={handleReset}
          />
        );
      },
      filterIcon: (filtered: boolean) => (
        <Tooltip
          title={`Filter ${dataIndex}`}
          placement="top"
          arrowPointAtCenter
          getPopupContainer={(trigger) => trigger}
        >
          <Button
            aria-label={`Filter ${dataIndex}`}
            icon={
              <FilterFilled
                style={{
                  fontSize: fontSizes.font_size_8,
                  color: filtered ? secondary : black.black_3,
                }}
              />
            }
          />
        </Tooltip>
      ),
      render: (text: string, record: IMainRow | Record<string, string>) => {
        if (text) {
          const fontWeight = record.rowType === "main" ? 600 : 400;
          return (
            <TableCell showEllipsis={false} fontWeight={fontWeight}>
              {text}
            </TableCell>
          );
        }
      },
    };
  };
  const getColumnSearchProps = (dataIndex: string, searchPlaceholder: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => {
      return (
        <SearchTableColumn
          dataIndex={dataIndex}
          placeholderText={searchPlaceholder}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
      );
    },
    filterIcon: (filtered: boolean) => (
      <Button
        aria-label={`Search ${dataIndex}`}
        icon={
          <SearchOutlined
            style={{ fontSize: fontSizes.font_size_8, color: filtered ? secondary : black.black_2 }}
          />
        }
      />
    ),
    render: (text: string, record: IMainRow | Record<string, string>) => {
      if (text) {
        const fontWeight = record.rowType === "main" ? 600 : 400;
        const filterValues = _.get(filters, dataIndex);
        return (
          <TableCell showEllipsis={false} fontWeight={fontWeight}>
            {record.rowType === "child" && filterValues && filterValues.length === 1 ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={filterValues}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
              />
            ) : (
              text
            )}
          </TableCell>
        );
      }
    },
  });

  const getColumnBasicProps = () => ({
    render: (text: string, record: IMainRow | Record<string, string>) => {
      if (text) {
        const fontWeight = record.rowType === "main" ? 600 : 400;
        return (
          <TableCell showEllipsis={false} fontWeight={fontWeight}>
            {text}
          </TableCell>
        );
      }
    },
  });

  const pinColumnRender = (value: any, record: IMainRow) => {
    if (record.variable) {
      return pin ? (
        <PinVariable
          institution={institution}
          variable={record.variable}
          type="tag / category of right"
        />
      ) : (
        <UnpinButton
          compare={false}
          type="tag / category of right"
          data={[{ institution, variables: [record.variable] }]}
        />
      );
    }
  };

  const columns: ColumnsType<IMainRow> = _.map(columnNames, (columnName, i) => {
    const columnType = columnsType[i];
    const column = {
      title: headerRender(columnName),
      dataIndex: columnName,
      key: columnName,
    };
    if (columnType === "search") {
      let placeholderText: string;
      if (_.includes(columnName, "Right to")) {
        placeholderText = "Right to...";
      } else if (_.includes(columnName, "Orig")) {
        placeholderText = "Derecho / Direito a...";
      } else {
        placeholderText = columnName;
      }
      return {
        ...column,
        ...getColumnSearchProps(columnName, placeholderText),
      };
    } else if (columnType === "filter") {
      return {
        ...column,
        ...getColumnFilterProps(columnName, i),
      };
    } else {
      return {
        ...column,
        ...getColumnBasicProps(),
      };
    }
  });

  if (includePinColumn) {
    columns.push({
      key: "pin",
      title: <InvisibleText text="Pin" />,
      width: "5%",
      render: pinColumnRender,
    });
  }

  if (headingColumnIsSeparate) {
    columns.unshift({
      key: "heading",
      // only applicable to rights table
      title: headerRender("Category of Right"),
      dataIndex: "heading",
      width: "15%",
      ...getColumnBasicProps(),
    });
    columns[1].width = "15%";
    columns[3].width = "30%";
    columns[4].width = "13%";
  }

  return (
    <Table
      rowClassName="main-row"
      tableLayout="fixed"
      columns={columns}
      dataSource={dataSource}
      expandable={{
        childrenColumnName: "children",
        indentSize: 0,
        expandedRowKeys: expandedRowKeys,
        onExpandedRowsChange: onExpandedRowsChange,
      }}
      pagination={false}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      components={{
        header: {
          cell: (props: any) => <th {...props} scope="col" />,
        },
      }}
    />
  );
};

export default FilterTable;

/**Filter table helper */
const filterTableData = (
  data: IMainRow[],
  filters: string[],
  filterDict: Record<string, string[]>,
  filterIsStrict: Record<string, boolean>
) => {
  const newData = _.map(data, (mainRow) => {
    const newChildren = _.filter(mainRow.children, (child) => {
      return _.every(
        _.map(filters, (filter) => {
          const filterValues = _.get(filterDict, filter);
          const isStrict = _.get(filterIsStrict, filter);
          return _.some(
            _.map(filterValues, (filterValue) => {
              if (isStrict) {
                const values = _.map(_.split(child[filter], ";"), (value) => _.trim(value));
                return _.includes(values, filterValue);
              } else {
                return _.includes(child[filter].toLowerCase(), _.trim(filterValue.toLowerCase()));
              }
            })
          );
        })
      );
    });
    return {
      ...mainRow,
      children: newChildren.length > 0 ? newChildren : undefined,
    };
  });
  return newData;
};
