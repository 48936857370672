import React, { FC, useState } from "react";

import { MenuOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Table, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { RenderExpandIconProps, RenderExpandIcon } from "rc-table/lib/interface";
import arrayMove from "array-move";
import _ from "lodash";
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Institution } from "../../api/institution";
import { Variable, VariableType, NAP, SiglaTriple } from "../../api/variable";

import AggregateTableCell from "../../components/AggregateTableCell";
import InvisibleText from "../../components/InvisibleText";
import TableCell from "../../components/TableCell";
import TableHeader from "../../components/TableHeader";
import Title from "../../components/Title";
import CompareRightsModal from "./CompareRightsModal";
import DownloadData from "../downloadData/DownloadData";
import createSheetsFromCompareTable from "../downloadData/createSheetsFromCompareTable";
import PinCompareRow from "../myPinnedData/PinCompareRow";
import UnpinButton from "../myPinnedData/UnpinButton";
import PinCompareTable from "../myPinnedData/PinCompareTable";

import { DELIMITER } from "../../constants";
import { black } from "../../styles/colors";

import "./CompareTable.css";

interface CompareTableProps {
  institutions: {
    institution: Institution;
    variables: Variable[][];
  }[];
  pin: boolean;
  title: string;
}

interface ICompareRow {
  key: string;
  name: string;
  type?: string;
  children?: Record<string, string>[];
  [propName: string]: any;
}

const headerRender = (header: string, draggable: boolean) => {
  return draggable ? (
    <Space direction="horizontal">
      <MenuOutlined rotate={90} style={{ color: black.black_3, fontSize: 20 }} />
      <TableHeader header={header} />
    </Space>
  ) : (
    <TableHeader header={header} />
  );
};

const SortableHeaderCell = SortableElement((props: any) => <th {...props} />);
const SortableHeaderRow = SortableContainer((props: any) => <tr {...props} />);

const siglaAnswerRender = (value: string | Variable, record: ICompareRow) => {
  let cell;
  if (record.type) {
    // variable cell
    cell = (
      <TableCell showEllipsis={false} fontWeight={400}>
        {NAP}
      </TableCell>
    );
    if (value) {
      const variable = value as Variable;
      if (variable.type === VariableType.aggregate) {
        cell = (
          <AggregateTableCell siglaAnswers={variable.sigla_answer as Record<string, string>[][]} />
        );
      } else if (record.type === VariableType.composite) {
        cell = (
          <Link
            to={`/${variable.hyperlink}/${variable._id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {variable.sigla_answer as string}
          </Link>
        );
      } else {
        cell = (
          <TableCell showEllipsis={true} fontWeight={400}>
            {variable.sigla_answer as string}
          </TableCell>
        );
      }
    }
  } else if (!record.children) {
    // orig text or source cell
    cell = (
      <TableCell showEllipsis={true} fontWeight={400}>
        {value ? (value as string) : NAP}
      </TableCell>
    );
  }
  return cell;
};

const CompareTable: FC<CompareTableProps> = ({ institutions, pin, title }) => {
  const cardTitle = <Title>{title}</Title>;
  const variableColumnRender = (value: string, record: ICompareRow) => {
    //bold variable heading and name
    const fontWeight = record.type ? 600 : record.children ? 600 : 400;
    let cell;
    if (record.type === VariableType.aggregate) {
      const rights = _.reduce(
        institutions,
        (list, data, i) => {
          const variable = record[`${i}`];
          if (variable) {
            list.push({
              institution: data.institution,
              variable: variable as Variable,
            });
          }
          return list;
        },
        [] as { institution: Institution; variable: Variable }[]
      );
      cell = <CompareRightsModal title={value} rights={rights} />;
    } else {
      cell = (
        <TableCell showEllipsis={false} fontWeight={fontWeight}>
          {value}
        </TableCell>
      );
    }
    return cell;
  };

  const pinColumnRender = (value: any, record: ICompareRow) => {
    if (record.type) {
      const data = _.map(institutions, (institutionData, i) => {
        return {
          institution: institutionData.institution,
          variables: [record[`${i}`]] as Variable[],
        };
      });
      return pin ? (
        <PinCompareRow data={data} name={title} />
      ) : (
        <UnpinButton compare={true} type="comparison" data={data} />
      );
    }
  };
  const columns: ColumnsType<ICompareRow> = _.map(institutions, (institutionData, i) => {
    const institution = institutionData.institution;
    return {
      key: `${i}`,
      title: headerRender(
        institution.country
          ? institution.sub_category && institution.sub_category.length > 0
            ? `${institution.country} ${DELIMITER} ${institution.name}`
            : institution.country
          : institution.name,
        true
      ),
      dataIndex: `${i}`,
      width: "24vw",
      render: siglaAnswerRender,
      onHeaderCell: () => {
        return { id: `${i}` };
      },
      className: "drag-visible",
    };
  });

  columns.unshift({
    key: "name",
    title: headerRender("Variable", false),
    dataIndex: "name",
    width: "17vw",
    render: variableColumnRender,
  });

  columns.push({
    key: "pin",
    title: <InvisibleText text="Pin" />,
    dataIndex: "pin",
    width: "4vw",
    render: pinColumnRender,
  });

  /**selected institution with the largest number of headings */
  const largestInstitution = _.maxBy(institutions, (institution) => institution.variables.length);

  /**Assumption: There can be an uneven number of headings across institutions,
   * but the variables are assummed to line up correctly across institutions first,
   * then the variables that are not present in all institutions occurs at the end.
   */
  const data: ICompareRow[] = _.map(
    largestInstitution?.variables,
    (variablesForAHeading, headingIndex) => {
      // all variables for a heading across institutions
      const allVariablesForHeading = _.map(
        institutions,
        (institution) => institution.variables[headingIndex] || []
      );
      const largestHeading = _.maxBy(allVariablesForHeading, (variables) => {
        return variables.length;
      });
      const compareVariableRows = _.map(largestHeading, (variable, variableIndex) => {
        const siglaAnswer: ICompareRow = {
          key: `${variable._id} sigla_answer`,
          name: variable.name,
          /**only variable row has a type */
          type: variable.type,
        };
        const originalText: ICompareRow = {
          key: `${variable._id} orig_text`,
          name: "Original Text",
        };
        const source: ICompareRow = { key: `${variable._id} source`, name: "Source" };
        _.forEach(institutions, (_, institutionIndex) => {
          const variableForInstitution = (institutions[institutionIndex].variables[headingIndex] ||
            [])[variableIndex];
          siglaAnswer[`${institutionIndex}`] = variableForInstitution;
          if (
            variableForInstitution &&
            variableForInstitution.orig_text &&
            variableForInstitution.source
          ) {
            originalText[`${institutionIndex}`] = variableForInstitution.orig_text as string;
            source[`${institutionIndex}`] = variableForInstitution.source as string;
          }
        });
        if (variable.type !== VariableType.aggregate) {
          siglaAnswer.children = [originalText, source];
        }
        return siglaAnswer;
      });
      return {
        key: variablesForAHeading[0].heading,
        name: variablesForAHeading[0].heading,
        children: compareVariableRows,
      };
    }
  );

  const [columnsState, setColumnsState] = useState<ColumnsType<ICompareRow>>(columns);

  const onSortStart = () => {
    document.body.className = "grabbing";
  };

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        ([] as ColumnsType<ICompareRow>).concat(columnsState),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setColumnsState(newData);
    }
    document.body.className = "";
  };

  const DraggableHeader = ({ className, style, ...restProps }: any) => {
    if (restProps.id) {
      const index = columnsState.findIndex((x) => x.key === restProps.id);
      return (
        <SortableHeaderCell
          index={index}
          className={className}
          style={style}
          {...restProps}
          scope="col"
        />
      );
    } else {
      return <th className={className} style={style} {...restProps} scope="col" />;
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableHeaderRow
      helperClass="column-dragging"
      axis="x"
      //lockAxis="x"
      onSortStart={onSortStart}
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const expandIconRender: RenderExpandIcon<ICompareRow> = ({
    expanded,
    expandable,
    onExpand,
    record,
  }: RenderExpandIconProps<ICompareRow>) => {
    let content;
    if (expandable) {
      if (record.children && record.type === undefined) {
        //heading row
        content = (
          <button
            className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
              expanded ? "expanded" : "collapsed"
            }`}
            aria-label={`${expanded ? "Collapse" : "Expand"} heading row`}
            onClick={(e) => onExpand(record, e)}
          />
        );
      } else {
        //regular variable row
        content = (
          <Tooltip
            placement="topLeft"
            title={`Click to ${expanded ? "hide" : "see"} ${SiglaTriple.originalText} and ${
              SiglaTriple.source
            }`}
            getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          >
            <button
              className="table-row-down-arrow-icon ant-table-row-expand-icon"
              aria-label={`${expanded ? "Collapse" : "Expand"} variable row`}
              onClick={(e) => onExpand(record, e)}
            >
              <UpOutlined rotate={expanded ? 0 : 180} />
            </button>
          </Tooltip>
        );
      }
    } else if (record.type === VariableType.aggregate) {
      //show nothing for aggregate row
      content = "";
    } else {
      //empty icon
      content = (
        <div
          style={{ display: "inline-flex", width: 17, height: 17, float: "left", marginRight: 8 }}
        />
      );
    }
    return content;
  };

  const pinDataFormat = _.map(institutions, (institutionData) => {
    return {
      institution: institutionData.institution,
      variables: _.flattenDeep(institutionData.variables),
    };
  });

  const downloadCompositeVariables: Variable[] = [];
  _.forEach(institutions, ({ variables }) => {
    const compositeVariables = _.filter(
      _.flattenDeep(variables),
      ({ type }) => type === VariableType.composite
    );
    downloadCompositeVariables.push(...compositeVariables);
  });

  return (
    <Card
      className="sigla-table"
      title={cardTitle}
      bodyStyle={{ padding: 0 }}
      extra={
        <Space>
          <DownloadData
            tooltipTitle="Download table"
            saveAsFileName="SIGLA_Compare_Data"
            sheets={createSheetsFromCompareTable(title, institutions)}
            compositeVariables={downloadCompositeVariables}
          />
          {pin ? (
            <PinCompareTable data={pinDataFormat} name={title} />
          ) : (
            <UnpinButton compare={true} type="table" data={pinDataFormat} />
          )}
        </Space>
      }
    >
      <Table
        className="compare-table"
        rowClassName="main-row"
        tableLayout="fixed"
        columns={columnsState}
        components={{
          header: {
            row: DraggableContainer,
            cell: DraggableHeader,
          },
        }}
        dataSource={data}
        pagination={false}
        expandable={{
          expandIcon: expandIconRender,
          indentSize: 15,
          childrenColumnName: "children",
        }}
        scroll={{ y: "80vh" }}
      />
    </Card>
  );
};

export default CompareTable;
