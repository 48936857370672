import React from "react";

import { BackTop } from "antd";
import { BrowserRouter as Router } from "react-router-dom";

import { APP_UI_STATE } from "./store";

import AppContent from "./Content";
import Footer from "../components/Footer";
import AppHeader from "../components/AppHeader";
import NotificationHint from "../components/NotificationHint";
import ReportIssue from "../components/ReportIssue";
import SiglaDataDescription from "../components/SiglaDataDescription";

import "./App.less";

const App = () => {
  return (
    <Router basename="/">
      <AppHeader />
      <main id="main" style={{ minHeight: "100vh" }}>
        <NotificationHint
          showHint={true}
          appUiStateKey={APP_UI_STATE.DISABLE_SIGLA_DATA_HINT}
          message="SIGLA Functions"
          description={<SiglaDataDescription />}
          duration={0}
        />
        <ReportIssue />
        <BackTop />
        <AppContent />
      </main>
      <Footer />
    </Router>
  );
};

export default App;
