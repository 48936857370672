import axios from "axios";

import { API_BASE_URL } from "./constants";

const API_URL = `${API_BASE_URL}categories`;

export const getCategories = async (country?: string) => {
  let url = `${API_URL}`;
  if (country) {
    url = url.concat("?", `country=${country}`);
  }
  const { data } = await axios.get<string[]>(url);
  return data;
};
