import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAmendments } from "../../api";
import { Amendment } from "../../api/amendment";
import { AppThunk } from "../../app/store";

interface AmendmentsState {
  amendments: Amendment[];
  isLoading: boolean;
  error: string | null;
}

const initialAmendmentsState: AmendmentsState = {
  amendments: [],
  isLoading: false,
  error: null,
};

const amendments = createSlice({
  name: "amendments",
  initialState: initialAmendmentsState,
  reducers: {
    getAmendmentsSuccess(state, { payload }: PayloadAction<Amendment[]>) {
      state.isLoading = false;
      state.error = null;
      state.amendments = payload;
    },
    getAmendmentsStart(state) {
      state.isLoading = true;
    },
    getAmendmentsFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { getAmendmentsSuccess, getAmendmentsStart, getAmendmentsFailure } =
  amendments.actions;

export default amendments.reducer;

export const fetchAmendments =
  (variable: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getAmendmentsStart());
      const amendments = await getAmendments(variable);
      dispatch(getAmendmentsSuccess(amendments));
    } catch (err) {
      dispatch(getAmendmentsFailure(err.toString()));
    }
  };
