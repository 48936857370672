import React, { FC, useEffect } from "react";

import { Col } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { fetchCountries } from "../countries/countriesSlice";
import { fetchCategories } from "../categories/categoriesSlice";
import {
  setSelectedCategories,
  setSelectedCountries,
  setSelectedInstitutions,
  setVariablesIsDisabled,
  updateSelectedInstitutionsByCategory,
} from "./byCountrySlice";
import { fetchInstitutionsByCountry } from "../institutions/institutionsSlice";
import { fetchVariables } from "../variables/variablesSlice";

import { getCategoriesIsLoading } from "../categories/categoriesSelectors";
import { getCountriesIsLoading } from "../countries/countriesSelectors";
import {
  getCountryNodes,
  getSelectedCategories,
  getSelectedCountries,
  makeGetCategoryNodes,
  getInstitutionNodes,
  getSelectedInstitutions,
  getSelectedInstitutionStrings,
  getVariablesIsDisabled,
  getSelectedInstitutionData,
} from "./byCountrySelectors";
import { getInstitutionsIsLoading } from "../institutions/institutionsSelectors";
import { getVariablesIsLoading } from "../variables/variablesSelectors";

import AssertiveContainer from "../../components/AssertiveContainer";
import Container from "../../components/Container";
import SeeDataButton from "../../components/SeeDataButton";
import SelectDropdown from "../../components/SelectDropdown";
import SelectRow from "../../components/SelectRow";
import PageHeader from "../../components/PageHeader";
import SITGroup from "../institutions/SITGroup";
import { EventCategory, EventAction } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";
import withErrorBoundary from "../../components/withErrorBoundary";

import { selectColumn, seeDataColumn } from "../../styles/breakpoints";
import { SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../../constants";

const route = SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.byCountry];

const InstitutionByCountryPage: FC = () => {
  useDocumentTitle(route.title);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCategories());
  }, [dispatch]);

  const countriesIsLoading = useSelector(getCountriesIsLoading);
  const countryNodes = useSelector(getCountryNodes);
  const selectedCountries = useSelector(getSelectedCountries);

  const categoriesIsLoading = useSelector(getCategoriesIsLoading);
  const categoryNodes = useSelector(makeGetCategoryNodes(selectedCountries[0]));
  const selectedCategories = useSelector(getSelectedCategories);

  const institutionsIsLoading = useSelector(getInstitutionsIsLoading);
  const institutionNodes = useSelector(getInstitutionNodes);
  const selectedInstitutions = useSelector(getSelectedInstitutions);
  const selectedInstitutionStrings = useSelector(getSelectedInstitutionStrings);

  const variablesIsLoading = useSelector(getVariablesIsLoading);
  const variablesIsDisabled = useSelector(getVariablesIsDisabled);
  const selectedInstitutionData = useSelector(getSelectedInstitutionData);

  const onSelectCountry = (countries: string[]) => {
    dispatch(setSelectedCountries(countries));
    dispatch(fetchInstitutionsByCountry(countries[0]));
    dispatch(setSelectedCategories([]));
    dispatch(setSelectedInstitutions([]));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectCategory = (categories: string[]) => {
    dispatch(setSelectedCategories(categories));
    dispatch(updateSelectedInstitutionsByCategory(categories));
    dispatch(setVariablesIsDisabled(true));
  };

  const onSelectInstitution = (institutions: string[]) => {
    const selectedInstitutions = _.map(institutions, (institution) => {
      const [category, country, name, _id] = _.split(institution, ">");
      return {
        category,
        country,
        name,
        _id,
      };
    });
    dispatch(setSelectedInstitutions(selectedInstitutions));
    dispatch(setVariablesIsDisabled(true));
  };

  const onClickSeeData = () => {
    dispatch(fetchVariables(_.map(selectedInstitutions, ({ _id }) => _id)));
    dispatch(setVariablesIsDisabled(selectedInstitutions.length === 0));
    trackCustomEvent(EventCategory.dataRequest, EventAction.requestData, SIGLA_ROUTES.byCountry);
  };

  let specificInstitutionsTable;
  if (!variablesIsDisabled && !variablesIsLoading) {
    specificInstitutionsTable = (
      <SITGroup
        furled={true}
        breadcrumbs={["country", "category", "name"]}
        sits={selectedInstitutionData}
        pin={true}
      />
    );
  }

  return (
    <>
      <PageHeader title={route.title} description={route.description} imageUrl={route.img} />
      <Container>
        <SelectRow>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={false}
              loading={countriesIsLoading}
              multiple={false}
              onChangeCb={onSelectCountry}
              options={countryNodes}
              selectedOptions={selectedCountries}
              placeholder="1. Choose a country"
            />
          </Col>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={selectedCountries.length === 0}
              loading={categoriesIsLoading || institutionsIsLoading}
              multiple={true}
              onChangeCb={onSelectCategory}
              options={categoryNodes}
              selectedOptions={selectedCategories}
              placeholder="2. Choose institutional categories"
            />
          </Col>
          <Col {...selectColumn}>
            <SelectDropdown
              disabled={selectedCategories.length === 0}
              loading={institutionsIsLoading}
              multiple={true}
              onChangeCb={onSelectInstitution}
              options={institutionNodes}
              selectedOptions={selectedInstitutionStrings}
              placeholder="3. Choose institutions"
            />
          </Col>
          <Col
            {...seeDataColumn}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SeeDataButton
              loading={variablesIsLoading}
              disabled={selectedInstitutions.length === 0}
              onClickCb={onClickSeeData}
            />
          </Col>
        </SelectRow>
        <AssertiveContainer>{specificInstitutionsTable}</AssertiveContainer>
      </Container>
    </>
  );
};

export default withErrorBoundary(InstitutionByCountryPage);
