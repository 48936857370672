import React, { FC, CSSProperties } from "react";

import { CloseOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { BsArrowBarRight } from "react-icons/bs";
import { Row, Col, Tooltip, Button, Drawer, Grid } from "antd";
import { FaSort } from "react-icons/fa";
import { useSelector } from "react-redux";

import { SiglaTriple } from "../../api/variable";
import { APP_UI_STATE } from "../../app/store";
import { useAppDispatch } from "../../app/store";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { toggleMenuIsCollapsed } from "./customBrowseSlice";
import {
  getMenuIsCollapsed,
  getSelectedVariablesData,
  getShowSeeDataCard,
} from "./customBrowseSelectors";

import Container from "../../components/Container";
import NotificationHint from "../../components/NotificationHint";
import PageHeader from "../../components/PageHeader";
import withErrorBoundary from "../../components/withErrorBoundary";
import CustomBrowseMenu from "./CustomBrowseMenu";
import CustomBrowseTable from "./CustomBrowseTable";
import SeeDataCard from "./SeeDataCard";
import VisuallyHidden from "../../components/VisuallyHidden";

import "./CustomBrowsePage.css";
import { SIGLA_ROUTES, SIGLA_ROUTES_DISPLAY } from "../../constants";

const { useBreakpoint } = Grid;

const route = SIGLA_ROUTES_DISPLAY[SIGLA_ROUTES.customBrowse];

const CustomBrowsePage: FC = () => {
  useDocumentTitle(route.title);
  const dispatch = useAppDispatch();

  const showSeeDataCard = useSelector(getShowSeeDataCard);
  const menuIsCollapsed = useSelector(getMenuIsCollapsed);
  const variables = useSelector(getSelectedVariablesData);

  const customBrowseDataIconStyle: CSSProperties = {
    border: `1px solid #d9d9d9`,
    borderRadius: "2px",
  };

  const customBrowseDataDescription = (
    <ul>
      <li>
        <span>{"Click "}</span>
        <VisuallyHidden icon={<BsArrowBarRight size={20} />} text="show side menu" />
        <span>{" button to open the side menu"}</span>
      </li>
      <li>
        <span>{"Click "}</span>
        <VisuallyHidden
          icon={<FaSort />}
          text="the table header with caret up and caret down icons"
        />
        <span>{" to sort the data in ascending or descending order for each column"}</span>
      </li>
      <li>
        <span>{"Drag the "}</span>
        <MenuOutlined />
        <span>{" icon to reorder variable rows"}</span>
      </li>
      <li>
        <span>{"Click the "}</span>
        <VisuallyHidden
          icon={<PlusOutlined style={customBrowseDataIconStyle} />}
          text="expand row"
        />
        <span>{` button to view ${SiglaTriple.originalText} and ${SiglaTriple.source} for ${SiglaTriple.answer}`}</span>
      </li>
    </ul>
  );

  const onMenuCollapse = () => {
    dispatch(toggleMenuIsCollapsed());
  };

  const { xs } = useBreakpoint();

  return (
    <div>
      <PageHeader title={route.title} description={route.description} imageUrl={route.img} />
      {menuIsCollapsed && (
        <Tooltip
          zIndex={2}
          trigger={["hover", "click"]}
          placement="right"
          title={"Show side menu"}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        >
          <Button
            style={{
              position: "fixed",
              top: "45vh",
              left: 0,
              zIndex: 99,
            }}
            size="large"
            type="default"
            icon={<BsArrowBarRight size={26} />}
            onClick={onMenuCollapse}
            aria-label={"Show side menu"}
          />
        </Tooltip>
      )}
      <Container>
        <NotificationHint
          showHint={!showSeeDataCard}
          appUiStateKey={APP_UI_STATE.DISABLE_CUSTOM_BROWSE_TABLE_HINT}
          message={`Navigating ${route.title}`}
          description={customBrowseDataDescription}
          duration={0}
        />
        <Row gutter={[0, 8]}>
          <Col>
            <Drawer
              aria-label={`${route.title} Menu`}
              title={<span style={{ fontSize: 24 }}>{`${route.title} Menu`}</span>}
              placement="left"
              closeIcon={<CloseOutlined style={{ fontSize: 24 }} />}
              closable={true}
              onClose={onMenuCollapse}
              visible={!menuIsCollapsed}
              getContainer={false}
              width={xs ? "100vw" : "400px"}
              mask={xs ? true : false}
              bodyStyle={{ paddingLeft: 0, paddingRight: 0 }}
              zIndex={99}
              destroyOnClose
            >
              <CustomBrowseMenu />
            </Drawer>
          </Col>
          <Col span={24}>
            {showSeeDataCard ? (
              <SeeDataCard menuIsCollapsed={menuIsCollapsed} onMenuCollapse={onMenuCollapse} />
            ) : (
              <CustomBrowseTable variables={variables} pin={true} />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withErrorBoundary(CustomBrowsePage);
