import React, { FC } from "react";

import { Tooltip, TreeSelect, Space } from "antd";
import _ from "lodash";

import { Institution } from "../../api/institution";

import { compareSortByConfig, compareSortOrderConfig } from "../../utils/createSortConfig";

import { black } from "../../styles/colors";
import { fontSizes } from "../../styles/fonts";

const { TreeNode } = TreeSelect;

interface CompareInstitutionsSelectProps {
  disabled: boolean;
  institutionByName: Record<string, Record<string, Institution>>;
  loading: boolean;
  multiple: boolean;
  numberOfSelectedCountries: number;
  placeholder: string;
  selectedOptions: string[];
  onChangeCb(institutions: string[]): void;
}

const CompareInstitutionsSelect: FC<CompareInstitutionsSelectProps> = ({
  disabled,
  institutionByName,
  loading,
  multiple,
  numberOfSelectedCountries,
  placeholder,
  selectedOptions,
  onChangeCb,
}) => {
  const handleChange = (value: string | string[]) => {
    const institutions = multiple ? (value as string[]) : [value as string];
    onChangeCb(institutions);
  };

  const selectedValue = multiple
    ? selectedOptions
    : selectedOptions.length > 0
    ? selectedOptions[0]
    : undefined;

  /*hacky solution to sort executive*/
  const sortedNameList = _.orderBy(
    _.keys(institutionByName),
    [
      (name) => {
        const institutionsPerName = _.values(institutionByName[name]);
        return -1 * _.uniq(_.map(institutionsPerName, (institution) => institution.country)).length;
      },
      ...compareSortByConfig,
    ],
    compareSortOrderConfig
  );

  const inputId = _.replace(placeholder.toLowerCase(), /\s+/g, "-");

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="select-label"
        style={{ fontWeight: 500, fontSize: fontSizes.font_size_7, color: black.black_2 }}
      >
        <label htmlFor={`tree-select-${inputId}`}>{placeholder}</label>
      </div>
      <TreeSelect
        id={`tree-select-${inputId}`}
        allowClear={multiple}
        disabled={disabled}
        loading={loading}
        maxTagCount={3}
        onChange={handleChange}
        showArrow={true}
        showSearch={false}
        style={{ width: "100%" }}
        treeCheckable={multiple}
        value={selectedValue}
        virtual={false}
      >
        {sortedNameList.map((name) => {
          const institutionsPerName = _.values(institutionByName[name]);
          const value = `${institutionsPerName[0].category}>${name}`;

          let optionContent;
          const uniqueCountries = _.sortBy(
            _.uniq(_.map(institutionsPerName, (institution) => institution.country))
          );
          if (multiple || uniqueCountries.length === numberOfSelectedCountries) {
            optionContent = name;
          } else {
            const countries = _.join(uniqueCountries, ", ");
            const countryText =
              uniqueCountries.length > 1
                ? `${uniqueCountries.length} countries`
                : `${uniqueCountries.length} country`;
            optionContent = (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {[
                  `${name} [`,
                  <Tooltip
                    key={value}
                    title={`This institution exists in ${countries}`}
                    getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
                  >
                    <strong key={value}>{countryText}</strong>
                  </Tooltip>,
                  "]",
                ]}
              </div>
            );
          }
          return <TreeNode key={value} title={optionContent} value={value} />;
        })}
      </TreeSelect>
    </Space>
  );
};

export default CompareInstitutionsSelect;
