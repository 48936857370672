import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { getInstitutionById } from "../../api/";
import { getInstitutions, Institution } from "../../api/institution";
import { AppThunk } from "../../app/store";

interface InstitutionsState {
  byId: Record<string, Institution>;
  byCountryByCategory: Record<string, Record<string, Record<string, string>>>;
  byCategoryByCountry: Record<string, Record<string, Record<string, string>>>;
  byCategoryByName: Record<string, Record<string, Record<string, string>>>;
  isLoading: boolean;
  error: string | null;
}

const initialInstitutionsState: InstitutionsState = {
  byId: {},
  byCountryByCategory: {},
  byCategoryByCountry: {},
  byCategoryByName: {},
  isLoading: false,
  error: null,
};

const institutions = createSlice({
  name: "institutions",
  initialState: initialInstitutionsState,
  reducers: {
    getInstitutionsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getInstitutionsSuccess(state, { payload }: PayloadAction<Institution[]>) {
      state.isLoading = false;
      state.error = null;
      const institutions = payload;
      _.forEach(institutions, (institution) => {
        if (!_.has(state.byId, institution._id)) {
          state.byId[institution._id] = institution;
        }
      });
    },
    getInstitutionsFailure(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
    },
    updateByCountryByCategory(state, { payload }: PayloadAction<Institution[]>) {
      const institutions = payload;
      _.forEach(institutions, (institution) => {
        if (!_.has(state.byCountryByCategory, institution.country as string)) {
          state.byCountryByCategory[institution.country as string] = { [institution.category]: {} };
        }
        if (
          !_.has(state.byCountryByCategory[institution.country as string], institution.category)
        ) {
          state.byCountryByCategory[institution.country as string][institution.category] = {};
        }
        state.byCountryByCategory[institution.country as string][institution.category][
          institution._id
        ] = institution._id;
      });
    },
    updateByCategoryByCountry(state, { payload }: PayloadAction<Institution[]>) {
      const institutions = payload;
      _.forEach(institutions, (institution) => {
        if (!_.has(state.byCategoryByCountry, institution.category)) {
          state.byCategoryByCountry[institution.category] = {
            [institution.country || institution.category]: {},
          };
        }
        if (
          !_.has(
            state.byCategoryByCountry[institution.category],
            institution.country || institution.category
          )
        ) {
          state.byCategoryByCountry[institution.category][
            institution.country || institution.category
          ] = {};
        }
        state.byCategoryByCountry[institution.category][
          institution.country || institution.category
        ][institution._id] = institution._id;
      });
    },
    updateByCategoryByName(state, { payload }: PayloadAction<Institution[]>) {
      const institutions = payload;
      _.forEach(institutions, (institution) => {
        if (!_.has(state.byCategoryByName, institution.category)) {
          state.byCategoryByName[institution.category] = {};
        }
        const names =
          institution.sub_category && institution.sub_category.length > 0
            ? institution.sub_category
            : [institution.name];
        _.forEach(names, (name) => {
          if (!_.has(state.byCategoryByName[institution.category], name)) {
            state.byCategoryByName[institution.category][name] = {};
          }
          state.byCategoryByName[institution.category][name][institution._id] = institution._id;
        });
      });
    },
  },
});

export const {
  getInstitutionsStart,
  getInstitutionsSuccess,
  getInstitutionsFailure,
  updateByCategoryByCountry,
  updateByCountryByCategory,
  updateByCategoryByName,
} = institutions.actions;

export default institutions.reducer;

export const fetchInstitutionById =
  (id: string): AppThunk =>
  async (dispatch, state) => {
    const byId = state().institutions.byId;
    if (!_.has(byId, id)) {
      try {
        dispatch(getInstitutionsStart());
        const institution = await getInstitutionById(id);
        dispatch(getInstitutionsSuccess([institution]));
      } catch (err) {
        dispatch(getInstitutionsFailure(err.toString()));
      }
    }
  };

export const fetchInstitutionsByCountry =
  (country: string): AppThunk =>
  async (dispatch, state) => {
    const byCountryByCategory = state().institutions.byCountryByCategory;
    if (!_.has(byCountryByCategory, country)) {
      try {
        dispatch(getInstitutionsStart());
        const institutions = await getInstitutions([country], []);
        dispatch(getInstitutionsSuccess(institutions));
        dispatch(updateByCountryByCategory(institutions));
      } catch (err) {
        dispatch(getInstitutionsFailure(err.toString()));
      }
    }
  };

export const fetchInstitutionsByCategory =
  (category: string): AppThunk =>
  async (dispatch, state) => {
    const byCategoryByCountry = state().institutions.byCategoryByCountry;
    if (!_.has(byCategoryByCountry, category)) {
      try {
        dispatch(getInstitutionsStart());
        const institutions = await getInstitutions([], [category]);
        dispatch(getInstitutionsSuccess(institutions));
        dispatch(updateByCategoryByCountry(institutions));
        dispatch(updateByCategoryByName(institutions));
      } catch (err) {
        dispatch(getInstitutionsFailure(err.toString()));
      }
    }
  };
