export enum EventCategory {
  dataStorage = "sigla_data_storage",
  dataRequest = "sigla_data_request",
  externalLink = "external_link",
}

export enum EventAction {
  download = "download",
  pin = "pin",
  unpin = "unpin",
  requestData = "request_data",
  openLink = "open_link",
}
