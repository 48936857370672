import React, { FC } from "react";

import { Card, Space } from "antd";
import _ from "lodash";

import { Institution } from "../../api/institution";
import { Variable } from "../../api/variable";

import FilterTable from "../../components/FilterTable";
import OpenInNewTab from "../../components/OpenInNewTab";
import Title from "../../components/Title";
import DownloadData from "../downloadData/DownloadData";
import createSheetsFromSIT from "../downloadData/createSheetsFromSIT";
import PinSIT from "../myPinnedData/PinSIT";
import UnpinButton from "../myPinnedData/UnpinButton";

import { DELIMITER } from "../../constants";

interface RightsTableProps {
  breadcrumbs: string[];
  furled: boolean;
  institution: Institution;
  variables: Variable[][];
  pin: boolean;
  columnsType: string[];
}

const RightsTable: FC<RightsTableProps> = ({
  breadcrumbs,
  furled,
  institution,
  variables,
  pin,
  columnsType,
}) => {
  const title = (
    <Title>
      {_.join(
        _.map(breadcrumbs, (breadcrumb) => _.get(institution, breadcrumb)),
        ` ${DELIMITER} `
      )}
    </Title>
  );

  const showOpenInNewTab = pin ? furled : false;
  const icons = (
    <Space>
      {showOpenInNewTab && (
        <OpenInNewTab
          route={`institutions/${institution._id}`}
          tooltipTitle="Open table in a new tab"
        />
      )}
      <DownloadData
        tooltipTitle="Download table"
        saveAsFileName="SIGLA_Institution_Data"
        sheets={createSheetsFromSIT(institution, _.flattenDeep(variables))}
      />
      {pin ? (
        <PinSIT institution={institution} variables={_.flattenDeep(variables)} />
      ) : (
        <UnpinButton
          compare={false}
          type="table"
          data={[{ institution, variables: _.flattenDeep(variables) }]}
        />
      )}
    </Space>
  );

  const rightData = _.map(variables, (variablesForAHeading, i) => {
    const variable = variablesForAHeading[0];
    const rights = variable.sigla_answer as Record<string, string>[][];
    return {
      key: variable._id,
      rowType: "main",
      variable: variable,
      heading: variable.heading,
      children: _.map(rights, (right, j) => {
        const row = _.reduce(
          right,
          (obj, col) => {
            obj[col.name] = col.answer;
            return obj;
          },
          {} as Record<string, string>
        );
        return {
          ...row,
          rowType: "child",
          key: `${i}>${j}`,
        };
      }),
    };
  });

  const flattenedData = _.reduce(
    _.flattenDeep(variables),
    (list, variable) => {
      const rights = variable.sigla_answer as Record<string, string>[][];
      list.push(...rights);
      return list;
    },
    [] as Record<string, string>[][]
  );
  return (
    <Card
      className="sigla-table"
      hoverable
      title={title}
      extra={icons}
      bodyStyle={{ padding: 0, cursor: "auto" }}
    >
      <FilterTable
        headingColumnIsSeparate={true}
        institution={institution}
        data={rightData}
        flattenedData={flattenedData}
        columnsType={columnsType}
        includePinColumn={true}
        pin={pin}
        furled={furled}
      />
    </Card>
  );
};

export default RightsTable;
