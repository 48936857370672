import React, { CSSProperties, FC } from "react";

import { BsFlagFill } from "react-icons/bs";

import VisuallyHidden from "./VisuallyHidden";

import { SIGLA_WEBSITE } from "../constants";

import { primary } from "../styles/colors";

const iconContainerStyle: CSSProperties = {
  height: "40px",
  width: "40px",
  boxSizing: "border-box",
  backgroundImage: `radial-gradient(circle, white 16px, ${primary} 17px, ${primary} 18px, transparent 19px)`,
  color: primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "24px",
};

const ReportIssue: FC = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "45vh",
        right: "10px",
        zIndex: 99,
        width: "44px",
        height: "44px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a
        href={`${SIGLA_WEBSITE}report-an-issue/`}
        rel="noopener noreferrer"
        title="Report an issue"
      >
        <>
          <div style={iconContainerStyle}>
            <VisuallyHidden
              icon={<BsFlagFill style={{ transform: "rotate(-15deg)" }} />}
              text="Report an issue"
            />
          </div>
        </>
      </a>
    </div>
  );
};

export default ReportIssue;
