import React, { CSSProperties, FC } from "react";

import { CaretRightOutlined, CheckSquareTwoTone, InfoCircleOutlined } from "@ant-design/icons";
import { Card, Button, Typography, Collapse, Space, Row, Col, Tooltip, Grid } from "antd";
import { BsArrowBarRight, BsArrowBarLeft } from "react-icons/bs";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../app/store";
import {
  CustomBrowseTreeNode,
  getSelectedCountries,
  getSelectedInstitutions,
  getSelectedVariables,
} from "./customBrowseSelectors";
import { setShowSeeDataCard } from "./customBrowseSlice";

import ExampleTree from "./ExampleTree";
import { EventAction, EventCategory } from "../googleAnalytics/constants";
import { trackCustomEvent } from "../googleAnalytics/utils";

import { DELIMITER, SIGLA_ROUTES } from "../../constants";

import { fontSizes } from "../../styles/fonts";
import { INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { black } from "../../styles/colors";

const { Text } = Typography;
const { Panel } = Collapse;
const { useBreakpoint } = Grid;

const INSTITUTIONAL_CATEGORY = "Institutional Category";
const SPECIFIC_INSTITUTION = "Institution";
const VARIABLE_HEADING = "Heading";
const VARIABLE_NAME = "Variable";

const institutionsTreeData: CustomBrowseTreeNode[] = [
  {
    key: `${INSTITUTIONAL_CATEGORY} 1`,
    title: `${INSTITUTIONAL_CATEGORY} 1`,
    fontSize: fontSizes.font_size_7,
    color: black.black_2,
    children: [
      {
        key: `${SPECIFIC_INSTITUTION} 1`,
        title: `${SPECIFIC_INSTITUTION} 1`,
        fontSize: fontSizes.font_size_5,
        color: black.black_3,
      },
      {
        key: `${SPECIFIC_INSTITUTION} 2`,
        title: `${SPECIFIC_INSTITUTION} 2`,
        fontSize: fontSizes.font_size_5,
        color: black.black_3,
      },
    ],
  },
  {
    key: `${INSTITUTIONAL_CATEGORY} 2`,
    title: `${INSTITUTIONAL_CATEGORY} 2`,
    fontSize: fontSizes.font_size_7,
    color: black.black_2,
    children: [
      {
        key: `${SPECIFIC_INSTITUTION} 3`,
        title: `${SPECIFIC_INSTITUTION} 3`,
        fontSize: fontSizes.font_size_5,
        color: black.black_3,
      },
      {
        key: `${SPECIFIC_INSTITUTION} 4`,
        title: `${SPECIFIC_INSTITUTION} 4`,
        fontSize: fontSizes.font_size_5,
        color: black.black_3,
      },
    ],
  },
];
const institutionsExample = <ExampleTree data={institutionsTreeData} />;

const variablesTreeData: CustomBrowseTreeNode[] = [
  {
    key: `${INSTITUTIONAL_CATEGORY} 1 ${DELIMITER} ${SPECIFIC_INSTITUTION} 1`,
    title: `${INSTITUTIONAL_CATEGORY} 1 ${DELIMITER} ${SPECIFIC_INSTITUTION} 1`,
    fontSize: fontSizes.font_size_7,
    color: black.black_2,
    children: [
      {
        key: `${VARIABLE_HEADING} 1`,
        title: `${VARIABLE_HEADING} 1`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 1`,
            title: `${VARIABLE_NAME} 1`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 2`,
            title: `${VARIABLE_NAME} 2`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
      {
        key: `${VARIABLE_HEADING} 2`,
        title: `${VARIABLE_HEADING} 2`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 3`,
            title: `${VARIABLE_NAME} 3`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 4`,
            title: `${VARIABLE_NAME} 4`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
    ],
  },
  {
    key: `${INSTITUTIONAL_CATEGORY} 2 ${DELIMITER} ${SPECIFIC_INSTITUTION} 2`,
    title: `${INSTITUTIONAL_CATEGORY} 2 ${DELIMITER} ${SPECIFIC_INSTITUTION} 2`,
    fontSize: fontSizes.font_size_7,
    color: black.black_2,
    children: [
      {
        key: `${VARIABLE_HEADING} 3`,
        title: `${VARIABLE_HEADING} 3`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 5`,
            title: `${VARIABLE_NAME} 5`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 6`,
            title: `${VARIABLE_NAME} 6`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
      {
        key: `${VARIABLE_HEADING} 4`,
        title: `${VARIABLE_HEADING} 4`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 7`,
            title: `${VARIABLE_NAME} 7`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 8`,
            title: `${VARIABLE_NAME} 8`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
    ],
  },
  {
    key: INTERNATIONAL_INSTITUTIONS,
    title: INTERNATIONAL_INSTITUTIONS,
    fontSize: fontSizes.font_size_7,
    color: black.black_2,
    children: [
      {
        key: `${VARIABLE_HEADING} 5`,
        title: `${VARIABLE_HEADING} 5`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 9`,
            title: `${VARIABLE_NAME} 9`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 10`,
            title: `${VARIABLE_NAME} 10`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
      {
        key: `${VARIABLE_HEADING} 6`,
        title: `${VARIABLE_HEADING} 6`,
        fontSize: fontSizes.font_size_7,
        color: black.black_3,
        children: [
          {
            key: `${VARIABLE_NAME} 11`,
            title: `${VARIABLE_NAME} 11`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
          {
            key: `${VARIABLE_NAME} 12`,
            title: `${VARIABLE_NAME} 12`,
            fontSize: fontSizes.font_size_5,
            color: black.black_3,
          },
        ],
      },
    ],
  },
];
const variablesExample = <ExampleTree data={variablesTreeData} />;

interface SeeDataCardProps {
  menuIsCollapsed: boolean;
  onMenuCollapse(): void;
}

const SeeDataCard: FC<SeeDataCardProps> = ({ menuIsCollapsed, onMenuCollapse }) => {
  const dispatch = useAppDispatch();
  const selectedCountries = useSelector(getSelectedCountries);
  const selectedInstitutions = useSelector(getSelectedInstitutions);
  const selectedVariables = useSelector(getSelectedVariables);

  const data = [
    {
      title: "Countries",
      success: selectedCountries.length > 0,
    },
    {
      title: "Institutions",
      success: selectedInstitutions.length > 0,
    },
    {
      title: "Variables",
      success: selectedInstitutions.length > 0 && selectedVariables.length > 0,
    },
  ];

  const handleClick = () => {
    dispatch(setShowSeeDataCard(false));
    trackCustomEvent(EventCategory.dataRequest, EventAction.requestData, SIGLA_ROUTES.customBrowse);
  };

  const title = (
    <Text strong style={{ whiteSpace: "pre-wrap", fontSize: fontSizes.font_size_8 }}>
      Please select options from each of the following dimensions from the side menu to view a
      report
    </Text>
  );

  const generateExtra = () => (
    <Tooltip
      title="Click to view instructions"
      placement="right"
      arrowPointAtCenter
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <InfoCircleOutlined style={{ fontSize: fontSizes.font_size_7 }} />
    </Tooltip>
  );
  const headerRender = (header: string) => (
    <span style={{ fontSize: fontSizes.font_size_7 }}>{header}</span>
  );

  const { md } = useBreakpoint();
  const seeDataStyle: CSSProperties = {
    cursor: "auto",
    width: md ? "70%" : "100%",
  };

  let showSeeDataButton = data[1].success && data[2].success;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: fontSizes.font_size_5,
      }}
    >
      <Card hoverable bordered={false} title={title} style={seeDataStyle}>
        <Row gutter={[0, 32]} justify="space-around">
          <Col span={24}>
            <Collapse bordered={false} style={{ fontSize: fontSizes.font_size_6 }}>
              <Panel
                key={data[0].title}
                showArrow={false}
                header={
                  <Space>
                    {data[0].success ? (
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <div>&#8226;</div>
                    )}
                    {headerRender(data[0].title)}
                  </Space>
                }
                extra={generateExtra()}
              >
                In the side menu expand <b>Countries</b> and then select specific countries to
                explore. International Institutions will appear in <b>Institutions</b> by default.
              </Panel>
              <Panel
                key={data[1].title}
                showArrow={false}
                header={
                  <Space>
                    {data[1].success ? (
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <div>&#8226;</div>
                    )}
                    {headerRender(data[1].title)}
                  </Space>
                }
                extra={generateExtra()}
              >
                <div style={{ marginBottom: 16 }}>
                  In the side menu expand <b>Institutions</b> and then select specific institutions
                  to explore. The list of institutions is structured as follows.
                </div>
                {institutionsExample}
              </Panel>
              <Panel
                key={data[2].title}
                showArrow={false}
                header={
                  <Space>
                    {data[2].success ? (
                      <CheckSquareTwoTone twoToneColor="#52c41a" />
                    ) : (
                      <div>&#8226;</div>
                    )}
                    {headerRender(data[2].title)}
                  </Space>
                }
                extra={generateExtra()}
              >
                <div style={{ marginBottom: 16 }}>
                  In the side menu expand <b>Variables</b> and then select specific variables to
                  view SIGLA's Summaries. The list of variables is structured as follows.
                </div>
                {variablesExample}
                <div style={{ marginTop: "1em" }}>
                  <span>{"Use the "}</span>
                  <CaretRightOutlined />
                  <span>{" icon to expand/collapse any item in the list"}</span>
                </div>
              </Panel>
            </Collapse>
          </Col>
          <Col>
            <Button
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              size="large"
              type="default"
              onClick={onMenuCollapse}
            >
              {`${menuIsCollapsed ? "Show" : "Hide"} side menu   `}
              {menuIsCollapsed ? <BsArrowBarRight size={26} /> : <BsArrowBarLeft size={26} />}
            </Button>
          </Col>
          <Col>
            <Button size="large" type="primary" disabled={!showSeeDataButton} onClick={handleClick}>
              See Data
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SeeDataCard;
