import React, { FC, useEffect, useState } from "react";

import { Row, Col } from "antd";
import localforage from "localforage";
import _ from "lodash";

import { IVariable } from "../customBrowse/CustomBrowseTable";
import CustomBrowseTable from "../customBrowse/CustomBrowseTable";
import { DB_NAME, TABLE, LOCAL_STORAGE_EVENT } from "./constants";
import { InstitutionRecord } from "./recordTypes";

const fetchPins = async (setPins: React.Dispatch<React.SetStateAction<IVariable[]>>) => {
  const institutions = localforage.createInstance({
    name: DB_NAME,
    storeName: TABLE.INSTITUTIONS,
  });
  const keys = await institutions.keys();
  const institutionPromises = _.map(keys, (key) => institutions.getItem<InstitutionRecord>(key));
  let pinnedInstitutions = await Promise.all(institutionPromises);
  const variableList = _.map(
    _.map(pinnedInstitutions, (record) => record as InstitutionRecord),
    (record) => {
      const institution = record.institution;
      const variables: IVariable[] = _.map(record.variables, (variable) => {
        return {
          key: variable._id,
          ...variable,
          institution: institution,
        };
      });
      return variables;
    }
  );

  const variablePins = _.sortBy(_.flattenDeep(variableList), [
    "institution.country",
    "institution.name",
    "variable_index",
  ]);
  setPins(variablePins);
};

const VariablePins: FC = () => {
  const [pins, setPins] = useState<IVariable[]>([]);

  useEffect(() => {
    const handleFetchPins = () => {
      fetchPins(setPins);
    };
    window.addEventListener(LOCAL_STORAGE_EVENT.INSTITUTIONS, handleFetchPins as EventListener);

    return () =>
      window.removeEventListener(
        LOCAL_STORAGE_EVENT.INSTITUTIONS,
        handleFetchPins as EventListener
      );
  }, []);

  useEffect(() => {
    fetchPins(setPins);
  }, []);

  let content = <></>;
  if (pins.length > 0) {
    content = (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CustomBrowseTable variables={pins} pin={false} />
        </Col>
      </Row>
    );
  }
  return content;
};

export default VariablePins;
