import { createSelector } from "@reduxjs/toolkit";
import { DataNode } from "antd/lib/tree";
import _ from "lodash";

import { RootState } from "../../app/rootReducer";
import { INTERNATIONAL_INSTITUTIONS } from "../../api/institution";
import { VariableData } from "./customBrowseSlice";

import { black } from "../../styles/colors";
import { fontSizes } from "../../styles/fonts";

const TREE_NODE_SELECTABLE = false;

export interface CustomBrowseTreeNode extends DataNode {
  color: string;
  fontSize: string;
  children?: CustomBrowseTreeNode[];
}

/**Factory function to get flattened tree nodes */
export const makeGetFlattenedNodes = (nodes: CustomBrowseTreeNode[]) => {
  return createSelector([], () => {
    const flattenedNodes: CustomBrowseTreeNode[] = [];
    const getNodes = (data: CustomBrowseTreeNode[]) => {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        flattenedNodes.push(node);
        if (node.children) {
          getNodes(node.children);
        }
      }
    };
    getNodes(nodes);
    return flattenedNodes;
  });
};

/**country menu item */
export const getCountryNodes = (state: RootState) => {
  return _.map(state.customBrowse.countriesMenu.countries, (country) => {
    return {
      key: country,
      title: country,
      selectable: TREE_NODE_SELECTABLE,
      fontSize: fontSizes.font_size_5,
      color: black.black_3,
    };
  });
};
export const getSelectedCountries = (state: RootState) =>
  state.customBrowse.countriesMenu.selectedCountries;
export const getCountriesIsLoading = (state: RootState) =>
  state.customBrowse.countriesMenu.isLoading;

/**institution menu item */
const getInstitutionsByCategoryByName = (state: RootState) =>
  state.customBrowse.institutionsMenu.byCategoryByName;
export const getInstitutionsIsLoading = (state: RootState) =>
  state.customBrowse.institutionsMenu.isLoading;
export const getSelectedInstitutions = (state: RootState) =>
  state.customBrowse.institutionsMenu.selectedInstitutions;
export const getInstitutionNodes = createSelector(
  [getSelectedCountries, getInstitutionsByCategoryByName],
  (countries, byCategoryByName) => {
    const sortedCategories = _.orderBy(
      _.keys(byCategoryByName),
      [(category) => category === INTERNATIONAL_INSTITUTIONS, (category) => category],
      ["asc", "asc"]
    );
    const nodes = _.reduce(
      sortedCategories,
      (list, category) => {
        const names = _.filter(_.keys(byCategoryByName[category]), (name) => {
          const institutions = _.values(byCategoryByName[category][name]);
          return _.some(institutions, (institution) => {
            return institution.country ? _.includes(countries, institution.country) : true;
          });
        });
        const sortedNames = _.sortBy(names);
        if (sortedNames.length > 0) {
          list.push({
            key: category,
            title: category,
            selectable: TREE_NODE_SELECTABLE,
            fontSize: fontSizes.font_size_7,
            color: black.black_2,
            children: _.map(sortedNames, (name) => {
              return {
                key: `${category}>${name}`,
                title: name,
                selectable: TREE_NODE_SELECTABLE,
                fontSize: fontSizes.font_size_5,
                color: black.black_3,
              };
            }),
          });
        }
        return list;
      },
      [] as CustomBrowseTreeNode[]
    );
    return nodes;
  }
);

const variableNameCollator = new Intl.Collator("en-US", {
  numeric: true,
  usage: "sort",
  sensitivity: "variant",
});

/**variable menu item */
const getVariablesByInstitutionByHeadingByName = (state: RootState) =>
  state.customBrowse.variablesMenu.byInstitutionByHeadingByName;
export const getVariablesIsLoading = (state: RootState) =>
  state.customBrowse.variablesMenu.isLoading;
export const getSelectedVariables = (state: RootState) =>
  state.customBrowse.variablesMenu.selectedVariables;
export const getVariableNodes = createSelector(
  [getSelectedCountries, getSelectedInstitutions, getVariablesByInstitutionByHeadingByName],
  (countries, institutions, byInstitutionByHeadingByName) => {
    const institutionsByCategory = _.groupBy(institutions, "category");
    const sortedDomesticCategories = _.sortBy(
      _.filter(
        _.keys(institutionsByCategory),
        (category) => category !== INTERNATIONAL_INSTITUTIONS
      )
    );
    const nodes = _.reduce(
      sortedDomesticCategories,
      (nodesList, category) => {
        const institutionsPerCategory = _.sortBy(institutionsByCategory[category], "name");
        const institutionNodes = _.reduce(
          institutionsPerCategory,
          (institutionList, institution) => {
            const byHeadingByName = byInstitutionByHeadingByName[institution.name];
            const headingNodes = _.reduce(
              _.sortBy(_.keys(byHeadingByName)),
              (headingList, heading) => {
                const byName = byHeadingByName[heading];
                const variableNames = _.filter(_.keys(byName), (name) => {
                  const variables = _.values(byName[name]);
                  return _.some(variables, ({ institution }) => {
                    return institution.country ? _.includes(countries, institution.country) : true;
                  });
                });
                if (variableNames.length > 0) {
                  /*const sortedVariableNames = _.sortBy(variableNames, name => {
                    return _.values(byName[name])[0].variable_index;
                  })*/
                  const sortedVariableNames = variableNames.sort(variableNameCollator.compare);
                  headingList.push({
                    key: `${category}>${institution.name}>${heading}`,
                    title: heading,
                    selectable: TREE_NODE_SELECTABLE,
                    fontSize: fontSizes.font_size_7,
                    color: black.black_3,
                    children: _.map(sortedVariableNames, (name) => {
                      return {
                        key: `${category}>${institution.name}>${heading}>${name}`,
                        title: name,
                        selectable: TREE_NODE_SELECTABLE,
                        fontSize: fontSizes.font_size_5,
                        color: black.black_3,
                      };
                    }),
                  });
                }
                return headingList;
              },
              [] as CustomBrowseTreeNode[]
            );
            if (headingNodes.length > 0) {
              const title =
                category === institution.name ? category : `${category} / ${institution.name}`;
              institutionList.push({
                key: `${category}>${institution.name}`,
                title: title,
                selectable: TREE_NODE_SELECTABLE,
                fontSize: fontSizes.font_size_7,
                color: black.black_2,
                children: headingNodes,
              });
            }
            return institutionList;
          },
          [] as CustomBrowseTreeNode[]
        );
        if (institutionNodes.length > 0) {
          nodesList.push(...institutionNodes);
        }
        return nodesList;
      },
      [] as CustomBrowseTreeNode[]
    );
    if (
      institutionsByCategory[INTERNATIONAL_INSTITUTIONS] &&
      institutionsByCategory[INTERNATIONAL_INSTITUTIONS].length > 0
    ) {
      const byHeadingByName = byInstitutionByHeadingByName[INTERNATIONAL_INSTITUTIONS];
      const headingNodes = _.reduce(
        _.sortBy(_.keys(byHeadingByName)),
        (list, heading) => {
          const byName = byHeadingByName[heading];
          const variableNames = _.sortBy(_.keys(byName));
          list.push({
            key: `${INTERNATIONAL_INSTITUTIONS}>${INTERNATIONAL_INSTITUTIONS}>${heading}`,
            title: heading,
            selectable: TREE_NODE_SELECTABLE,
            fontSize: fontSizes.font_size_7,
            color: black.black_3,
            children: _.map(variableNames, (name) => {
              return {
                key: `${INTERNATIONAL_INSTITUTIONS}>${INTERNATIONAL_INSTITUTIONS}>${heading}>${name}`,
                title: name,
                selectable: TREE_NODE_SELECTABLE,
                fontSize: fontSizes.font_size_5,
                color: black.black_3,
              };
            }),
          });
          return list;
        },
        [] as CustomBrowseTreeNode[]
      );
      nodes.push({
        key: INTERNATIONAL_INSTITUTIONS,
        title: INTERNATIONAL_INSTITUTIONS,
        selectable: TREE_NODE_SELECTABLE,
        fontSize: fontSizes.font_size_7,
        color: black.black_2,
        children: headingNodes,
      });
    }
    return nodes;
  }
);

const alphabeticCollator = new Intl.Collator("en-US", {
  numeric: false,
  usage: "sort",
  sensitivity: "variant",
});

/**custom browse table */
export const getSelectedVariablesData = createSelector(
  [
    getSelectedCountries,
    getSelectedInstitutions,
    getSelectedVariables,
    getVariablesByInstitutionByHeadingByName,
  ],
  (countries, institutions, variables, byInstitutionByHeadingByName) => {
    const selectedInternationalInstitutions = _.reduce(
      institutions,
      (list, institution) => {
        if (institution.category === INTERNATIONAL_INSTITUTIONS) {
          list.push(institution.name);
        }
        return list;
      },
      [] as string[]
    );
    const variableData = _.reduce(
      variables,
      (list, variable) => {
        const { institution_name, heading, name } = variable;
        const variableDataPerVariableName = _.values(
          byInstitutionByHeadingByName[institution_name][heading][name]
        );
        const filteredVariableData = _.filter(variableDataPerVariableName, ({ institution }) => {
          return institution.country
            ? _.includes(countries, institution.country)
            : _.includes(selectedInternationalInstitutions, institution.name);
        });
        list.push(...filteredVariableData);
        return list;
      },
      [] as VariableData[]
    );

    const uniqueVariableData = _.uniqBy(variableData, "_id");
    uniqueVariableData.sort((a, b) => {
      const comparisons = [
        alphabeticCollator.compare(a.institution.country || "", b.institution.country || ""),
        alphabeticCollator.compare(a.institution.name, b.institution.name),
        alphabeticCollator.compare(a.heading, b.heading),
        variableNameCollator.compare(a.name, b.name),
      ];
      for (let i = 0; i < comparisons.length; i++) {
        if (comparisons[i] !== 0) {
          return comparisons[i];
        }
      }
      return 0;
    });
    return uniqueVariableData;
  }
);

/**ui state*/
export const getActivePanel = (state: RootState) => state.customBrowse.ui.activePanel;
export const getShowSeeDataCard = (state: RootState) => state.customBrowse.ui.showSeeDataCard;
export const getMenuIsCollapsed = (state: RootState) => state.customBrowse.ui.menuIsCollapsed;
