import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";

import { CompositeVariable } from "../../api";
import { RootState } from "../../app/rootReducer";

import { makeGetVariableById } from "../variables/variablesSelectors";

export const getBodyOfLawIsLoading = (state: RootState) => state.bodyOfLaw.isLoading;
const getBodyOfLaw = (state: RootState) => state.bodyOfLaw.laws;

export const makeGetCompositeVariables = (variableId: string) =>
  createSelector([getBodyOfLaw, makeGetVariableById(variableId)], (bodyOfLaw, variable) => {
    return [
      _.map(bodyOfLaw, (law) => {
        return {
          _id: law._id,
          index: law.index,
          heading: `${variable ? variable.name : ""}`,
          variable: variableId,
          sigla_answers: law.sigla_answers,
        } as CompositeVariable;
      }),
    ];
  });
