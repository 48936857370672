import React, { FC, useState, ReactText, useEffect } from "react";

import { TreeSelect, Space } from "antd";
import _ from "lodash";
import { DataNode } from "rc-tree-select/lib/interface";

import { black } from "../styles/colors";
import { fontSizes } from "../styles/fonts";

const { SHOW_CHILD } = TreeSelect;

interface SelectDropdownProps {
  disabled: boolean;
  loading: boolean;
  multiple: boolean;
  options: DataNode[];
  selectedOptions: string[];
  placeholder: string;
  onChangeCb(selectedOptions: string[]): void;
}

const SelectDropdown: FC<SelectDropdownProps> = ({
  disabled,
  loading,
  multiple,
  options,
  placeholder,
  selectedOptions,
  onChangeCb,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<ReactText[]>([]);

  useEffect(() => {
    setExpandedKeys(_.map(options, ({ key }) => key as string));
  }, [options]);

  const handleOnChange = (value: string | string[]) => {
    onChangeCb(multiple ? (value as string[]) : [value as string]);
  };

  const handleOnTreeExpand = (expandedKeys: ReactText[]) => {
    setExpandedKeys(expandedKeys);
  };

  const selectedValue = multiple
    ? selectedOptions
    : selectedOptions.length > 0
    ? selectedOptions[0]
    : undefined;

  const inputId = _.replace(placeholder.toLowerCase(), /\s+/g, "-");

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div
        className="select-label"
        style={{ fontWeight: 500, fontSize: fontSizes.font_size_7, color: black.black_2 }}
      >
        <label htmlFor={`tree-select-${inputId}`}>{placeholder}</label>
      </div>
      <TreeSelect
        id={`tree-select-${inputId}`}
        aria-required
        allowClear={multiple}
        disabled={disabled}
        loading={loading}
        maxTagCount={3}
        showArrow={true}
        showCheckedStrategy={SHOW_CHILD}
        showSearch={false}
        treeCheckable={multiple}
        treeData={options}
        treeExpandedKeys={expandedKeys}
        value={selectedValue}
        virtual={false}
        onChange={handleOnChange}
        onTreeExpand={handleOnTreeExpand}
        style={{ width: "100%" }}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      />
    </Space>
  );
};

export default SelectDropdown;
